export const Cities ={
    karachi : 'Karachi',
    lahore : 'Lahore',
    islamabad : 'Islamabad',
    peshawar : 'Peshawar',
    quetta : 'Quetta',
    multan : 'Multan',
    faisalabad : 'Faisalabad',
    rawalpindi : 'Rawalpindi',
    gujranwala : 'Gujranwala',
    sialkot : 'Sialkot',
    abbottabad : 'Abbottabad',
    bahawalpur : 'Bahawalpur',
    sargodha : 'Sargodha',
    sahiwal : 'Sahiwal',
    jhang : 'Jhang',
    okara : 'Okara',
    gujrat : 'Gujrat',
    kasur : 'Kasur',
    rahim_yar_khan : 'Rahim Yar Khan',
    mardan : 'Mardan',
    sheikhupura : 'Sheikhupura',
    chiniot : 'Chiniot',
    kamoke : 'Kamoke',
    mandi_bahauddin : 'Mandi Bahauddin',
    hafizabad : 'Hafizabad',
    sadiqabad : 'Sadiqabad',
    jhelum : 'Jhelum',
    gujar_khan : 'Gujar Khan',
    narowal : 'Narowal',
    muridke : 'Muridke',
    nowshera : 'Nowshera'
}