import { Generals } from "../../enums/general.names.enum";
export const getSessionStorageData = (moduleName) => {
    // Retrieve data from session storage based on module name
    switch (moduleName) {
      case 'FTTS':
        return sessionStorage.getItem(Generals.totalFttsAlarms) || '';
      case 'MPBN':
        return sessionStorage.getItem(Generals.totalMpbnAlarms) || '';
      case 'OTN':
        return sessionStorage.getItem(Generals.totalOtnAlarms) || '';
      case 'IPRAN':
        return sessionStorage.getItem(Generals.totalIpranAlarms) || '';
      default:
        return '';
    }
  };