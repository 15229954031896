import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Modal, Switch, Grid, Divider } from '@mui/material';
import { getOnePermissionDetail } from './userManagementFunctions/get_one_permission_detail.function';
import { assignFeatureToPermissions } from './userManagementFunctions/assign_features_to_permissions';
import { notify } from '../../common/functions/react-toastify.function';
import { useBackdrop } from '../../common/components/backDrop_context.component';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    p: 2,
    width: '1200px',
    maxHeight: '87vh',
    overflowY: 'auto',
};

export default function EditPermissionModal(props) {
    const { open, handleClose, rowData, fetchGroups } = props;
    const [onePermissions, setOnePermissions] = useState([]);
    const { showBackdrop, hideBackdrop } = useBackdrop();
    const [permissions, setPermissions] = useState({});

    useEffect(() => {
        if (rowData?.group_id) {
            fetchOnePermissions(rowData.group_id);
        }
    }, [rowData]);

    useEffect(() => {
        if (onePermissions?.length > 0) {
            const initialPermissions = {};
            onePermissions.forEach(permission => {
                initialPermissions[permission.name] = permission.active;
            });
            setPermissions(initialPermissions);
        }
    }, [onePermissions]);

    const fetchOnePermissions = async (permissionId) => {
        showBackdrop()
        const allPermissionsData = await getOnePermissionDetail(permissionId);
        setOnePermissions(allPermissionsData);
        hideBackdrop()
    };

    const handleToggle = (name) => (event) => {
        setPermissions({ ...permissions, [name]: event.target.checked });
    };
    const handleUpdate = async () => {
        showBackdrop()
        const activePermissionIds = onePermissions
            .filter(permission => permissions[permission.name])
            .map(permission => permission.id);

        try {
            await assignFeatureToPermissions(rowData?.group_id, activePermissionIds);
            notify('success', 'Permissions Updated Successfully')
            await fetchGroups();
            hideBackdrop()
            handleClose();
        } catch (err) {
            notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong')
            hideBackdrop()
         }
    };

   
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-title" sx={{ paddingLeft: '20px', fontWeight: '600', color: '#000', fontSize: '20px' }} gutterBottom>
                        Edit Permission / {rowData?.group_name}
                    </Typography>
                    <Divider />
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <Typography sx={{ paddingLeft: '20px', paddingTop: '12px' }} variant="body1">System Modules</Typography>
                        </Grid>
                        <Grid item xs={4} textAlign={'end'}>
                            <Typography sx={{ paddingRight: '20px', paddingTop: '12px' }} variant="body1">Access</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        {onePermissions?.map(permission => (
                            <React.Fragment key={permission.id}>
                                <Grid item xs={8}>
                                    <Typography sx={{ paddingLeft: '20px', color: '#1D242E' }} variant="body1">{permission.name}</Typography>
                                </Grid>
                                <Grid item xs={4} textAlign={'end'}>
                                    <Switch
                                        color="error"
                                        checked={permissions[permission.name] || false}
                                        onChange={handleToggle(permission.name)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </React.Fragment>
                        ))}

                    </Grid>
                    <Divider />
                    <Grid container spacing={1} paddingTop={'24px'}>
                        <Grid item xs={8} />
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="error"
                                fullWidth
                                onClick={handleUpdate}
                            >
                                UPDATE
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                                fullWidth
                                sx={{
                                    color: '#000',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                        borderColor: '#BD1D23',
                                        backgroundColor: 'rgba(189, 29, 35, 0.1)',
                                    },
                                }}
                            >
                                CANCEL
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}
