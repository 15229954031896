import apiClient from "../../../common/functions/api_axios_instance.function";
import { logout } from "../../../common/functions/log_out.function";
export const deleteUser = async (id) => {
  try {
    const response = await apiClient.post(`/users/deleteUser/${id}`);
    
    return response.data;
  } catch (error) {
    console.error('Error assigning feature to permissions:', error);
    // throw error;
    if(error.response.status === 401) {logout()}
  }
};
