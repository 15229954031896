export const ChildHeader = () => {
  return (
    <div className="lower">
    <div className="search-bar-1">
      <div className="outline-search-minimalistic-magnifer-1">
        <img className="vector-9" src="assets/vectors/Vector235_x2.svg" />
      </div>
      <div className="search-1">
        Search 
      </div>
    </div>
    <div className="frame-1410087955">
      <div className="frame-14117">
        <span className="region">
          Region
        </span>
        <div className="arrow-down-sign-navigate-321951">
          <img className="vector-10" src="assets/vectors/Vector749_x2.svg" />
        </div>
      </div>
      <div className="frame-14119">
        <span className="city">
          City
        </span>
        <div className="arrow-down-sign-navigate-3219511">
          <img className="vector-11" src="assets/vectors/Vector245_x2.svg" />
        </div>
      </div>
      <div className="frame-14120">
        <span className="ring-5">
          Ring
        </span>
        <div className="arrow-down-sign-navigate-3219512">
          <img className="vector-12" src="assets/vectors/Vector741_x2.svg" />
        </div>
      </div>
      <div className="frame-14121">
        <span className="site">
          Site
        </span>
        <div className="arrow-down-sign-navigate-3219513">
          <img className="vector-13" src="assets/vectors/Vector261_x2.svg" />
        </div>
      </div>
    </div>
    <div className="button">
      <span className="text-3">
        Apply
      </span>
    </div>
  </div>
  );
}