import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField, Grid, FormControl, MenuItem, InputLabel, Select, FormHelperText } from '@mui/material';
import axios from 'axios';
import { notify } from '../../common/functions/react-toastify.function';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { useBackdrop } from '../../common/components/backDrop_context.component';
import apiClient from '../../common/functions/api_axios_instance.function';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    p: 4,
};

export default function NewUserModal(props) {
    const { open, handleClose, fetchUsers, row } = props;
    const { showBackdrop, hideBackdrop } = useBackdrop();
    const [showPassword, setShowPassword] = useState(false);
    const [formTouched, setFormTouched] = useState(false);
    const userObjectTemplate = {
        userType: '',
        userName: '',
        email: '',
        password: '',
        confirmPassword: '',
        department: '',
        company: '',
        phoneNumber: ''
    };
    const [userObj, setUserObj] = useState(userObjectTemplate);
    const [errors, setErrors] = useState({});
    const [passwordMatch, setPasswordMatch] = useState(false);

    useEffect(() => {
        setUserObj({
            userType: row ? row.type : '',
            userName: row ? row.user_name : '',
            email: row ? row.user_email : '',
            password: '',
            confirmPassword: '',
            department: row ? row.department : '',
            company: row ? row.company : '',
            phoneNumber: row ? row.phone_number : ''
        })
    }, [row])
    const handleClickShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    useEffect(() => {
        if (formTouched) {
            const validate = () => {
                let tempErrors = {};
                if (!userObj.userType && !row) tempErrors.userType = 'User Type is required';
                if (!userObj.userName) tempErrors.userName = 'Username is required';
                if (!userObj.email) tempErrors.email = 'Email is required';
                else if (!/\S+@\S+\.\S+/.test(userObj.email)) tempErrors.email = 'Email is invalid';
                if (!userObj.password && !row && userObj.userType != 'AD') tempErrors.password = 'Password is required';
                if ((userObj.password !== userObj.confirmPassword) && !row && userObj.userType != 'AD') {
                    tempErrors.confirmPassword = 'Passwords do not match';
                } else {
                    setPasswordMatch(true);
                }
                setErrors(tempErrors);
                setPasswordMatch(userObj.password === userObj.confirmPassword);
            };

            validate();
        }
    }, [userObj, formTouched]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormTouched(true)
        setUserObj((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCloseModal = () => {
        setUserObj(userObjectTemplate);
        setErrors({});
        handleClose();
        setFormTouched(false)
    };

    const handleSubmit = async () => {
        showBackdrop();
        try {
            const apiObjForUser = {
                name: userObj.userName,
                email: userObj.email,
                password: userObj.password,
                department: userObj.department,
                phone_number: userObj.phoneNumber,
                designation: userObj.department,
                company: userObj.company,
                type: userObj.userType
            }
            const response = await apiClient.post(`/auth/register`, apiObjForUser);
            if (response) {
                hideBackdrop()
                handleCloseModal();
                fetchUsers();
                notify('success', 'User Created Successfully');
            }
        } catch (err) {
            hideBackdrop()
            notify('error', err?.response?.data?.message);
        }
        hideBackdrop()
    };
    const handleUpdate = async () => {
        showBackdrop()
        try {
            const apiObjForUser = {
                id: row?.user_id,
                name: userObj.userName,
                email: userObj.email,
                department: userObj.department,
                phone_number: userObj.phoneNumber,
                designation: userObj.department,
                company: userObj.company,
                type: userObj.userType
            }
            const response = await apiClient.post(`/users/update`, apiObjForUser);
            if (response) {
                handleCloseModal();
                fetchUsers();
                notify('success', response.data.message);
            }
        } catch (err) {
            hideBackdrop();
            notify('error', err?.response?.data?.message);
        }
        hideBackdrop()
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography id="modal-modal-title" style={{
                                fontSize: '24px',
                                fontWeight: 500,
                            }}>
                                {row ? 'Update User' : 'Create New User'}
                            </Typography>
                        </Grid>

                    </Grid>
                    <Grid container spacing={2} paddingTop={'20px'}>
                        <Grid item xs={6}>
                            <TextField
                                label="User Name"
                                variant="outlined"
                                sx={{ width: '100%' }}
                                name="userName"
                                value={userObj.userName}
                                onChange={handleChange}
                                required
                                error={!!errors.userName}
                                helperText={errors.userName}
                                autoComplete='off'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Email Address"
                                variant="outlined"
                                sx={{ width: '100%' }}
                                name="email"
                                type='email'
                                value={userObj.email}
                                disabled={row}
                                onChange={handleChange}
                                error={!!errors.email}
                                helperText={errors.email}
                                autoComplete='off'
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} paddingTop={'20px'}>
                        <Grid item xs={6} >
                            <FormControl variant="outlined" sx={{ width: '100%' }} error={!!errors.userType}>
                                <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                                <Select
                                    name="userType"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={userObj.userType}
                                    onChange={handleChange}
                                    sx={{ width: '100%' }}
                                    disabled={row}
                                    label='User Type'
                                    required
                                    data-testId='userModal-userType-1'
                                >
                                    <MenuItem value="">
                                        <em>Please Select</em>
                                    </MenuItem>
                                    <MenuItem value="AD">Active Directory</MenuItem>
                                    <MenuItem value="local">Local</MenuItem>
                                </Select>
                                {errors.userType && <FormHelperText>{errors.userType}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Phone Number"
                                variant="outlined"
                                sx={{ width: '100%' }}
                                name="phoneNumber"
                                value={userObj.phoneNumber}
                                onChange={handleChange}
                                type='number'
                                size='large'
                                data-testId='userModal-phoneNumber-1'
                            />
                        </Grid>

                    </Grid>
                    {(!row && userObj.userType != 'AD') ? <>
                        <Grid container spacing={2} paddingTop={'20px'}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Password"
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    name="password"
                                    // type="password"
                                    value={userObj.password}
                                    autoComplete='off'
                                    onChange={handleChange}
                                    type={showPassword ? 'text' : 'password'}
                                    required
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    // InputProps={{
                                    //     endAdornment: passwordMatch && userObj.password ? (
                                    //         <CheckCircleIcon sx={{ color: 'green' }} />
                                    //     ) : null,
                                    // }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                >
                                                    {showPassword ? <VisibilityOff sx={{ color: '#BD1D23' }} /> : <Visibility sx={{ color: '#BD1D23' }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Confirm Password"
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    name="confirmPassword"
                                    type="password"
                                    autoComplete='off'
                                    value={userObj.confirmPassword}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: passwordMatch && userObj.password ? (
                                            <CheckCircleIcon sx={{ color: 'green' }} />
                                        ) : null,
                                    }}
                                    error={!!errors.confirmPassword}
                                    helperText={errors.confirmPassword}
                                />
                            </Grid>
                        </Grid></> : null}


                    <Grid container spacing={2} paddingTop={'20px'}>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" sx={{ width: '100%' }}>
                                <InputLabel>Department</InputLabel>
                                <Select
                                    name="department"
                                    value={userObj.department}
                                    onChange={handleChange}
                                    sx={{ width: '100%' }}
                                    label='Department'
                                    data-testId='userModal-department-1'
                                >
                                    <MenuItem value="">
                                        <em>Please Select</em>
                                    </MenuItem>
                                    <MenuItem value="NOC">NOC</MenuItem>
                                    <MenuItem value="Ops">Ops</MenuItem>
                                    <MenuItem value="Administration ">Administration </MenuItem>
                                    <MenuItem value="IT">Quality department</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" sx={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-company">Company</InputLabel>
                                <Select
                                    labelId="demo-simple-select-company"
                                    id="demo-simple-select"
                                    name="company"
                                    value={userObj.company}
                                    onChange={handleChange}
                                    sx={{ width: '100%' }}
                                    size='large'
                                    label="Company"
                                    data-testId='userModal-company-1'
                                >
                                    <MenuItem value="">
                                        <em>Please Select</em>
                                    </MenuItem>
                                    <MenuItem value="Company A">Company A</MenuItem>
                                    <MenuItem value="Company B">Company B</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} paddingTop={'32px'}>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#BD1D23', width: '100%',
                                    '&:hover': {
                                        backgroundColor: '#BD1D23', // Ensure the hover color remains the same
                                    },
                                    '&:disabled': {
                                        backgroundColor: '#E57373',
                                        color: '#fff',
                                    },
                                }}
                                disabled={Object.keys(errors).length > 0 || !passwordMatch}
                                onClick={row ? handleUpdate : handleSubmit}
                            >
                                {row ? 'Update' : 'Create'}
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                sx={{
                                    color: '#000', backgroundColor: 'white', width: '100%',
                                    '&:hover': {
                                        borderColor: '#BD1D23',
                                        backgroundColor: 'rgba(189, 29, 35, 0.1)', // Optional: Adds a hover effect with a light background
                                    },
                                }}
                                onClick={handleCloseModal}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
