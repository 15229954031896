import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import logo from '../../Assets/Logos/logo-desk.svg'
import Avatar from '@mui/material/Avatar';
import ProfileImage from "../../Assets/ProfileImage/Profile image.png"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ChildHeader } from './ChildHeader';
import { borderTop, fontSize, height, width } from '@mui/system';
import AdminPopUp from './small-right-popUp-admin.component';
import AppsIcon from '@mui/icons-material/Apps';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from '../../common/functions/log_out.function';
import { notify } from '../../common/functions/react-toastify.function';

const theme = createTheme({
  palette: {
    primary: {
      main: '#BD1D23', // Red color
    },
    secondary: {
      main: '#BD1D23', // Red color
    },
  },
});

export default function TopHeader({ totalAlarms, handleReset, value, setValue, handleDrawerClose, toggleDrawer, applyFilters, list, state }) {
  const [anchor, setAnchor] = useState(null);
  const navigate = useNavigate();
  const permissionsJSON = localStorage.getItem('permissions');
  let permissions = [];
  try {
    permissions = JSON.parse(permissionsJSON) || [];
  } catch (error) {
    console.error("Failed to parse permissions:", error);
  }
  const permissionNames = Array.isArray(permissions) ? permissions.map(permission => permission.name) : [];
  const tabStyle = (tabValue, value) => ({
    color: '#fff',
    fontSize: '16px',
    height: '60px',
    position: 'relative',
    width: '9rem',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderTop: value === tabValue ? '4px solid  #d6595e' : 'none',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    backgroundColor: value === tabValue ? '#2F3C44' : '#192126',
    overflow: 'visible',
    ":hover": {
      backgroundColor: '#2F3C44',
    },
    // "::before": value === tabValue ? {
    //   content: '""',
    //   position: 'absolute',
    //   bottom: '-11px', // Adjust to move the curve outward
    //   left: '-2px', // Adjust to position the curve on the left
    //   width: '20px', // Width of the curve
    //   height: '20px', // Height of the curve
    //   backgroundColor: '#2F3C44', // Same as tab background color
    //   borderBottomLeftRadius: '20px', // Make the bottom left corner curved
    //   transform: 'rotate(-45deg)', // Rotate to create the opposite outward curve
    //   zIndex: 1,
    // } : {},
    // "::after": value === tabValue ? {
    //   content: '""',
    //   position: 'absolute',
    //   bottom: '-11px', // Adjust to move the curve outward
    //   right: '-2px', // Adjust to position the curve on the right
    //   width: '20px', // Width of the curve
    //   height: '20px', // Height of the curve
    //   backgroundColor: '#2F3C44', // Same as tab background color
    //   borderBottomRightRadius: '20px', // Make the bottom right corner curved
    //   transform: 'rotate(45deg)', // Rotate to create the opposite outward curve
    //   zIndex: 1,
    // } : {}
  });

  const handleClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
  };
  ////   old
  // const tabStyle = (tabValue, value) => ({
  //   color: '#fff', // Ensures the text color is white for both selected and unselected states
  //   fontSize: '16px',
  //   height: '60px',
  //   // fontFamily: 'Poppins',
  //   borderTop: value === tabValue ? '4px solid  #d6595e' : 'none',
  //   width: '9rem',
  //   boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  //   borderTopLeftRadius: '10px',
  //   borderTopRightRadius: '10px',
  //   backgroundColor: value === tabValue ? '#2F3C44' : '#192126',
  //   ":hover": {
  //     backgroundColor: '#2F3C44',
  //   }
  // });
  const handleTabSelection = (tabValue) => {
    setValue(tabValue);
    sessionStorage.setItem('selectedTab', tabValue);
    handleDrawerClose();
    handleReset()
  }
  useEffect(() => {
    const tab = sessionStorage.getItem('selectedTab');
    setValue(tab)
  }, [])
  const handleLogout = async () => {
    try {
      const result = await logout();
      console.log(result)
      navigate('/')
      notify('succes', result.message)
    } catch (error) {
      notify('error', error.message)
    }
  };
  return (<>
    <Box
      sx={{
        backgroundColor: '#192126',
        border: '1px solid #333333',
        color: '#fff',
        height: '70px'
      }}
    >
      <AdminPopUp anchor={anchor} handleTabSelection={handleTabSelection} setAnchor={setAnchor}/>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: '12px',
          mx: '32px'
        }}
      >
        <img alt='' src={logo} onClick={() => navigate('/rov')} style={{cursor:'pointer',height:'50px'}}/>
        <ThemeProvider theme={theme}>
          {/* <Box>
            <Tabs
              value={value}
              onChange={(event, newValue) => handleTabSelection(newValue)}
              aria-label="secondary tabs example"
              textColor="inherit"
              indicatorColor="secondary"
              sx={{
                '& .MuiTabs-indicator': {
                  top: 'unset',
                  bottom: 'unset'
                }
              }}
            >
              {permissionNames.includes('OTN') && <Tab sx={tabStyle('otn', value)} value="otn"  label={`otn ${sessionStorage.getItem('totalOtnAlarms') ? `(${sessionStorage.getItem('totalOtnAlarms')})` : ''}`} />}
              {permissionNames.includes('IPRAN') && <Tab sx={tabStyle('ipran', value)} value="ipran" disabled  label={`ipran ${sessionStorage.getItem('totalIpranAlarms') ? `(${sessionStorage.getItem('totalIpranAlarms')})` : ''}`} />}
              {permissionNames.includes('MPBN') && <Tab sx={tabStyle('mpbn', value)} value="mpbn" label={`mpbn ${sessionStorage.getItem('totalMpbnAlarms') ? `(${sessionStorage.getItem('totalMpbnAlarms')})` : ''}`} />}
              {permissionNames.includes('FTTS') && <Tab sx={tabStyle('ftts', value)} value="ftts" label={`ftts ${sessionStorage.getItem('totalFttsAlarms') ? `(${sessionStorage.getItem('totalFttsAlarms')})` : ''}`} />}
            </Tabs>
          </Box> */}
         {state.right ?  <Box>{list('right')}</Box> : null}

        </ThemeProvider>
        <Box sx={{
          display: 'flex',
          gap: '12px',
        }}>
          {/* <HomeIcon sx={{ marginBottom: '10px', color: '#BD1D23', marginTop: '50px', cursor: 'pointer' }} onClick={() => navigate('/rov')} /> */}

          <AppsIcon onClick={handleClick} sx={{ cursor: 'pointer' }} />
          {/* <HomeIcon onClick={() => navigate('/rov')} sx={{ cursor: 'pointer' }} /> */}
          {/* <LogoutIcon sx={{ cursor: 'pointer' }} onClick={handleLogout} /> */}
          {/* <span onClick={handleClick} style={{cursor:'pointer'}}>Admin</span> */}
          {/* <Avatar alt="Profile Image" src={ProfileImage} style={{ width: 40, height: 40 }} /> */}
        </Box>
      </Box>
    </Box>
    {/* {state.right ?
      <Box
        sx={{
          backgroundColor: '#2F3C44',
          border: `1px solid #333333`,
          color: '#fff',
          height: '55px'
        }}
      >
        <Box>
          {list('right')}
        </Box>
      </Box> : null} */}
  </>
  );
}
