import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { borderRadius } from '@mui/system';
import { TextField, Grid, Switch, FormControlLabel, FormGroup } from '@mui/material';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { notify } from '../../common/functions/react-toastify.function';
import { useBackdrop } from '../../common/components/backDrop_context.component';
import apiClient from '../../common/functions/api_axios_instance.function';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 413,
    bgcolor: 'background.paper',
    borderRadius: '5px',
    //   boxShadow: 24,
    p: 4,
};

export default function NewGroupModal(props) {
    const groupObjectTemplate = {
        name: '',
        description: '',
    }
    const { open, handleClose, fetchGroups, row } = props;
    console.log('===>row data', row)
    const { showBackdrop, hideBackdrop } = useBackdrop();
    const [groupObj, setGroupObj] = useState(groupObjectTemplate);
    const [backDrop, setBackDrop] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setGroupObj((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCloseModal = () => {
        setGroupObj(groupObjectTemplate)
        handleClose();
    };
    const handleUpdate = async () => {
        showBackdrop()
        try {
            const apiObjForGroup = {
                name: groupObj.name,
                description: groupObj.description
            }
            const response = await apiClient.put(`/groups/updateGroup/${row.group_id}`, apiObjForGroup);
            if (response) {
                handleCloseModal();
                fetchGroups();
                notify('success', response.data.message);
            }
        } catch (err) {
            hideBackdrop();
            notify('error', err?.response?.data?.message);
        }
        hideBackdrop()
    }
    useEffect(() => {
        setGroupObj({
            name: row ? row.group_name : '',
            description: row ? row.group_description : '',
        })
    }, [row])
    const handleSubmitGroup = () => {
        showBackdrop()
        apiClient.post(`/groups/createNewGroup`, groupObj)
            .then(response => {
                handleCloseModal()
                fetchGroups()
                hideBackdrop()
                handleClose()
                notify('success', 'Group Created Successfully')
            }
            )
            .catch(err => {
                notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong')
                hideBackdrop()
            });
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" style={{
                        // fontFamily: 'Roboto',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal'
                    }}>
                       {row ? 'Update Group' : 'Create New Group'}
                    </Typography>
                    <Grid container spacing={2} paddingTop={'20px'}>
                        <Grid item xs={12}>
                            <TextField id="standard-basic" required value={groupObj.name} onChange={handleChange} name='name' label="Group Name" variant="outlined" sx={{ width: '350px' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Description"
                                multiline
                                rows={2}
                                name='description'
                                value={groupObj.description}
                                onChange={handleChange}
                                sx={{ width: '350px' }}
                            />
                        </Grid>
                        {/* <FormGroup style={{ marginLeft: '16px', marginTop: '24px' }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        defaultChecked
                                        sx={{
                                            '& .MuiSwitch-switchBase': {
                                                '&.Mui-checked': {
                                                    color: '#F9B11A', // Color when the switch is on
                                                },
                                                '&.Mui-checked + .MuiSwitch-track': {
                                                    backgroundColor: '#F9B11A', // Track color when the switch is on
                                                },
                                            },
                                            '& .MuiSwitch-track': {
                                                backgroundColor: 'default', // Default track color when the switch is off
                                            },
                                        }}
                                    />
                                }
                                label="Active"
                            />
                        </FormGroup> */}
                        <Grid container spacing={2} style={{ marginLeft: '11px', marginTop: '20px' }}>
                            <Grid item xs={6}>
                                <Button variant="contained" sx={{
                                    backgroundColor: '#BD1D23', width: '160px',
                                    '&:hover': {
                                        backgroundColor: '#BD1D23', // Ensure the hover color remains the same
                                    },
                                    '&:disabled': {
                                        backgroundColor: '#E57373', // Adjust this to a lighter shade of the original color
                                        color: '#fff', // Ensure text color is still visible when disabled
                                    },
                                }}
                                    onClick={row ? handleUpdate : handleSubmitGroup}
                                    disabled={groupObj.name.length < 2}
                                >{row ? 'Update' : 'Create'}</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" sx={{
                                    color: '#000', backgroundColor: 'white', width: '160px', '&:hover': {
                                        borderColor: '#BD1D23',
                                        backgroundColor: 'rgba(189, 29, 35, 0.1)', // Optional: Adds a hover effect with a light background
                                    },
                                }} onClick={() => handleCloseModal()}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}