export const Generals={
    ftts:'ftts',
    mpbn:'mpbn',
    otn:'otn',
    ipran:'ipran',
    map:'graph',
    tabular:'table',
    userManagement:'userManagement',
    selectedTab:'selectedTab',
    totalMpbnAlarms:'totalMpbnAlarms',
    totalFttsAlarms:'totalFttsAlarms',
    totalOtnAlarms:'totalOtnAlarms',
    totalIpranAlarms:'totalIpranAlarms',
    live:'live',
    history:'history',
    user:'user',
    group:'group',
    permission:'permission'
}