import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Modal, Switch, Grid, Divider, Checkbox, Chip } from '@mui/material';
import { getOneGroupDetail } from './userManagementFunctions/get_one_group_detail.function';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { assignUserToGroups } from './userManagementFunctions/assign_user_to_groups.function';
import { notify } from '../../common/functions/react-toastify.function';
import { useBackdrop } from '../../common/components/backDrop_context.component';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '4px',
    p: 2,
    width: '400px',
    maxHeight: '67vh',
    overflowY: 'auto',
};

export default function AssignGroupModal(props) {
    const { open, handleClose, rowData, fetchUsers } = props;
    const [oneGroupsDetails, setOneGroupsDetails] = useState([]);
    const [permissions, setPermissions] = useState({});
    const { showBackdrop, hideBackdrop } = useBackdrop();
    const selectedGroups = oneGroupsDetails.filter(group => permissions[group.name]);
    useEffect(() => {
        if (rowData?.user_id) {
            fetchOneGroups(rowData.user_id);
        }
    }, [rowData]);

    useEffect(() => {
        if (oneGroupsDetails?.length > 0) {
            const initialAssignedGroups = {};
            oneGroupsDetails.forEach(group => {
                initialAssignedGroups[group.name] = group.active;
            });
            setPermissions(initialAssignedGroups);
        }
    }, [oneGroupsDetails]);

    const fetchOneGroups = async (permissionId) => {
        showBackdrop()
        const allGrouopsData = await getOneGroupDetail(permissionId);
        hideBackdrop()
        setOneGroupsDetails(allGrouopsData);
    };

    const handleToggle = (name) => (event) => {
        setPermissions({ ...permissions, [name]: event.target.checked });
    };
    const handleUpdate = async () => {
        showBackdrop()
        const activeGroupsIds = oneGroupsDetails
            .filter(permission => permissions[permission.name])
            .map(permission => permission.id);

        try {
            await assignUserToGroups(rowData?.user_id, activeGroupsIds);
            notify('success', 'Groups Assigned Successfully')
            await fetchUsers();
            hideBackdrop()
            handleClose();
        } catch (err) {
            notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong')
            console.error('Error updating permissions:', err);
            hideBackdrop()
            // Handle error appropriately (e.g., show an error message to the user)
        }
    };
    const colorStyles = [
        {
            backgroundColor: '#FFF7E0', // Light yellow
            color: '#D89200', // Dark yellow
            borderColor: '#D89200', // Dark yellow border
        },
        {
            backgroundColor: '#F5F3FF', // Light green
            color: '#7C3AED', // Dark green
            borderColor: '#C4B5FD', // Dark green border
        },
        {
            backgroundColor: '#FEF2F2', // Light blue
            color: '#DC2626', // Dark blue
            borderColor: '#FCA5A5', // Dark blue border
        },
        // Add more styles if needed
    ];
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                {/* <Box sx={{ padding: '24px', backgroundColor: '#fff', borderRadius: '8px', width: '400px', margin: 'auto', mt: '10%' }}> */}
                <Box sx={style}>
                    <Typography id="modal-title" sx={{ fontWeight: '600', color: '#000', fontSize: '20px', mb: 2 }} gutterBottom>
                        Assign Group
                    </Typography>
                    <Divider />

                    <Grid container spacing={1} sx={{ mt: 2 }}>
                        {oneGroupsDetails?.map(group => (
                            <React.Fragment key={group.id}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Checkbox
                                            color="error"
                                            checked={permissions[group.name] || false}
                                            onChange={handleToggle(group.name)}
                                        />
                                    </Grid>
                                    <Grid item xs={10} >
                                        <Typography sx={{ color: '#1D242E', marginTop: '3%' }} variant="body1">{group.name}</Typography>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                    <Grid container spacing={1} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            {/* {selectedGroups.map(group => (
                            <Chip
                                key={group.id}
                                label={group.name}
                                sx={{
                                    marginRight: 1,
                                    marginBottom: 1,
                                    backgroundColor: '#FFF7E0', // Light yellow background
                                    color: '#D89200', // Dark yellow text
                                    borderColor: '#D89200', // Dark yellow border
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    '& .MuiChip-deleteIcon': {
                                        color: '#D89200', // Dark yellow delete icon
                                    },
                                }}
                            // onDelete={handleDelete} // If you want the delete icon
                            />
                        ))} */}

                            {selectedGroups.map((group, index) => {
                                // Cycle through the color styles based on the index
                                const styles = colorStyles[index % colorStyles.length];

                                return (
                                    <Chip
                                        key={group.id}
                                        label={group.name}
                                        sx={{
                                            marginRight: 1,
                                            marginBottom: 1,
                                            backgroundColor: styles.backgroundColor,
                                            color: styles.color,
                                            borderColor: styles.borderColor,
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            '& .MuiChip-deleteIcon': {
                                                color: styles.color, // Delete icon color
                                            },
                                            borderRadius: '4px',
                                            fontWeight: 'bold',
                                        }}
                                        onDelete={handleToggle(group.name)} // If you want the delete icon
                                    />

                                );
                            })}
                        </Grid>
                    </Grid>

                    <Divider sx={{ mt: 2 }} />

                    <Grid container spacing={1} paddingTop={'24px'}>
                        <Grid item xs={6} />
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                color="error"
                                fullWidth
                                onClick={handleUpdate}
                            >
                                UPDATE
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                                fullWidth
                                sx={{
                                    color: '#000',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                        borderColor: '#BD1D23',
                                        backgroundColor: 'rgba(189, 29, 35, 0.1)',
                                    },
                                }}
                            >
                                CANCEL
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}
