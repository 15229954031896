import axios from 'axios';
import Cookies from 'js-cookie';
import apiClient from './api_axios_instance.function';

export const logout = async () => {
    try {
        Cookies.remove('logInState');
        sessionStorage.removeItem('logInState')
        Cookies.remove('permissions');
        localStorage.removeItem('permissions');
        sessionStorage.removeItem('totalFttsAlarms');
        sessionStorage.removeItem('totalMpbnAlarms');
        sessionStorage.removeItem('totalOtnAlarms');
        sessionStorage.removeItem('totalIpranAlarms');
        sessionStorage.removeItem('selectedTab');
        const response = await apiClient.post('/auth/logout');
     return response.data;
    } catch (error) {
        console.error('Logout failed:', error);
      if (error.response && error.response.status !== 401) {
              console.error('Error deleting user:', error);
              // Show a user-friendly message if necessary
            }
        // throw error;
    }
};
