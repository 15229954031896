import React, { useState } from 'react';
import { Grid, Box, TextField, Button, Typography } from '@mui/material';
import BAckground from '../../Assets/ProfileImage/Background.png';
import jazzLogo from '../../Assets/ProfileImage/jazzLogo.png';
import axios from 'axios';
import { notify } from '../../common/functions/react-toastify.function';
import { getTokenFromCookies } from '../../common/functions/get_token_from_cookies';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Overlay from '../../Assets/ProfileImage/Overlay.png';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useBackdrop } from '../../common/components/backDrop_context.component';
export const MainLoginScreen = () => {
    const loginObj = { email: '', password: '' }
    const [login, setLogin] = useState(loginObj)
    const apiUrl = process.env.REACT_APP_API_URL;
    const [showPassword, setShowPassword] = useState(false);
    const { showBackdrop, hideBackdrop } = useBackdrop();
    const navigate = useNavigate()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLogin({ ...login, [name]: value });
    };
    const handleClickShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const handleSubmit = async (e) => {
        // e.preventDefault();
        showBackdrop()
        if (login.email && login.password) {
            try {
                const response = await axios.post(`${apiUrl}/auth/login`, login, {
                    withCredentials: true,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Credentials': true
                    }
                });

                console.log('Login successful:', response.data);
                sessionStorage.setItem('logInState', JSON.stringify(response.data.response.name));


                // Set the permissions in cookies and local storage
                // Cookies.set('permissions', JSON.stringify(response.data.response.permissions));
                localStorage.setItem('permissions', JSON.stringify(response.data.response.permissions));

                // Debugging: Check if the token is properly set in cookies
                const token = getTokenFromCookies(); // Assuming getTokenFromCookies() retrieves the token
                const accessToken = sessionStorage.getItem('logInState');
                console.log('Retrieved token after setting:', token);

                // If token is set, navigate to /rov
                if (accessToken) {
                    notify('success', `Login successful as ${response.data.response.name}`);
                    navigate('/rov');
                    hideBackdrop()
                } else {
                    console.error('Token is not set in cookies.');
                    hideBackdrop()
                    notify('error', 'Login failed: Token not found.');
                }
            } catch (err) {
                notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong');
                hideBackdrop()
            }
        }
        else {
            notify('error', 'Please add email and password')
            hideBackdrop()
        }
    };
    return (
        <div style={{ height: '100vh', overflow: 'hidden' }}>
            <Grid container sx={{ position: 'relative', width: '100%', height: '100%', padding: 0, margin: 0 }}>
                <img src={BAckground} alt="login" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                <img
                    src={Overlay}
                    alt="overlay"
                    style={{
                        position: 'absolute',
                        width: '100%', height: '100%', objectFit: 'cover',
                        // top: '50%',
                        // left: '50%',
                        // transform: 'translate(-50%, -50%)',
                        // width: '300px',  // Adjust the size as needed
                        // height: 'auto', // Adjust the size as needed
                        zIndex: 0
                    }}
                />
                <Grid container justifyContent="center" alignItems="center" sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                    <Box sx={{ textAlign: 'center', padding: 4, borderRadius: 2 }}>
                        {/* bgcolor: 'rgba(0, 0, 0, 0.6)', */}
                        <img src={jazzLogo} alt="Jazz Logo" style={{ marginBottom: 16 }} />
                        {/* <Typography variant="h4" component="h1" sx={{ display: 'ruby-text', color: '#fff', mb: 4 }}>
                            <Box component="span" sx={{ fontWeight: '400', fontSize: '28px', fontFamily: 'math' }}>
                                JAZZ
                            </Box>{' '}
                            <Box component="span" sx={{ fontWeight: '700', fontSize: '28px', fontFamily: 'math' }}>
                                ROV
                            </Box>
                        </Typography> */}

                        <Box component="form" noValidate autoComplete="off" onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}>
                            <TextField
                                label="Email address"
                                name="email"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={login.email}
                                onChange={handleChange}
                                InputLabelProps={{ style: { color: 'rgba(255, 255, 255, 0.45)' } }} // Label color
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#BD1D23', // Border color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#BD1D23',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#BD1D23',
                                        },
                                    },
                                    input: { color: '#fff' }, // Text color
                                }}
                            />
                            {/* <TextField
                                label="Password"
                                variant="outlined"
                                fullWidth
                                name='password'
                                value={login.password}
                                onChange={handleChange}
                                margin="normal"
                                type="password"
                                InputLabelProps={{ style: { color: 'rgba(255, 255, 255, 0.45)' } }} // Label color
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#BD1D23', // Border color
                                            color:'rgba(255, 255, 255, 0.87)'
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#BD1D23',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#BD1D23',
                                        },
                                    },
                                    input: { color: '#fff' }, // Text color
                                }}
                            /> */}
                            <TextField
                                label="Password"
                                variant="outlined"
                                fullWidth
                                name="password"
                                value={login.password}
                                onChange={handleChange}
                                margin="normal"
                                type={showPassword ? 'text' : 'password'}
                                InputLabelProps={{ style: { color: 'rgba(255, 255, 255, 0.45)' } }} // Label color
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                                aria-label="toggle password visibility"
                                            >
                                                {showPassword ? <VisibilityOff sx={{ color: 'white' }} /> : <Visibility sx={{ color: 'white' }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#BD1D23',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#BD1D23',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#BD1D23',
                                        },
                                    },
                                    input: { color: '#fff' },
                                }}
                            />
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                    mt: 2,
                                    bgcolor: '#BD1D23',
                                    color: '#fff',
                                    '&:hover': {
                                        bgcolor: '#BD1D23',
                                    },

                                    display: 'flex',
                                    // width: 408px;
                                    padding: '8px 22px',

                                }}
                                onClick={handleSubmit}
                            >
                                LOGIN
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div >
    );
};
