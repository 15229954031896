import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, Typography, CircularProgress, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import { notify } from '../../common/functions/react-toastify.function';
import { logout } from '../../common/functions/log_out.function';
import apiClient from '../../common/functions/api_axios_instance.function';
export default function ExcelDataModal({ open, handleClose, handleFileUpload ,fetchUsers}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.name.endsWith('.csv')) {
            setSelectedFile(file);
        } else {
            alert('Please upload a valid CSV file.');
            setSelectedFile(null);
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) return;
    
        setUploading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);
    
        try {
            const response = await apiClient.post(`/users/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.status === 200) {
                console.log('File uploaded successfully');
                handleClose();
                fetchUsers();
                notify('success', 'File uploaded successfully');
            } else {
                notify('error', 'Failed to upload the file');
            }
        } catch (error) {
            if(error.response.status === 401) {logout()}
            console.error('Error uploading file:', error);
            notify('error', error.response.data.message);
        } finally {
            setUploading(false);
        }
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Select File</DialogTitle>
            <DialogContent>
                <Typography variant="body2" color="textSecondary">Upload .csv file here</Typography>

                <Box
                    sx={{
                        border: '2px dashed #ccc',
                        borderRadius: '4px',
                        padding: '20px',
                        textAlign: 'center',
                        marginTop: '20px',
                        cursor: 'pointer',
                        position: 'relative',
                        backgroundColor: '#f9f9f9'
                    }}
                    onClick={() => document.getElementById('fileInput').click()}
                >
                    <CloudUploadIcon color="action" sx={{ fontSize: 48 }} />
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Click or drag file to this area to upload
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Upload SKU excel sheet. supported formats .csv
                    </Typography>
                    <input
                        id="fileInput"
                        type="file"
                        accept=".csv"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </Box>

                {selectedFile && (
                    <Box
                        sx={{
                            mt: 2,
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            border: '1px solid #ccc',
                            borderRadius: '4px'
                        }}
                    >
                        <Typography>{selectedFile.name}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {uploading ? (
                                <CircularProgress size={24} />
                            ) : (
                                <IconButton onClick={handleRemoveFile}>
                                    <CancelIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleUpload} variant="contained" sx={{
                    backgroundColor: '#BD1D23', width: '92%', marginRight: '13px'
                }} disabled={!selectedFile || uploading}>
                    {uploading ? 'Uploading...' : 'Upload'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
