import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Modal, Grid, Divider, Checkbox, Chip } from '@mui/material';
import { getAllGroups } from './userManagementFunctions/get_all_groups.function';
import { assignBulkUserToGroups } from './userManagementFunctions/bulk_group_assign_to_user.function';
import { notify } from '../../common/functions/react-toastify.function';
import { useBackdrop } from '../../common/components/backDrop_context.component';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '4px',
    p: 2,
    width: '400px',
    maxHeight: '67vh',
    overflowY: 'auto',
};

export default function AssignBulkGroupModal(props) {
    const { open, handleClose, rowData, fetchUsers, handleClearCheck } = props;
    const [oneGroupsDetails, setOneGroupsDetails] = useState([]);
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);
    const { showBackdrop, hideBackdrop } = useBackdrop();

    useEffect(() => {
           fetchAllGroups();
    }, [rowData]);

    const fetchAllGroups = async () => {
        showBackdrop();
        try {
            const allGroupsData = await getAllGroups();
            setOneGroupsDetails(allGroupsData);
        } finally {
            hideBackdrop();
        }
    };

    const handleToggle = (id) => (event) => {
        const newSelectedGroupIds = event.target.checked
            ? [...selectedGroupIds, id]
            : selectedGroupIds.filter(groupId => groupId !== id);
        setSelectedGroupIds(newSelectedGroupIds);
    };

    const handleUpdate = async () => {
        showBackdrop();
        const updateBulkObj = {
            user_ids: rowData,
            group_ids: selectedGroupIds,
        };
        try {
            const resp = await assignBulkUserToGroups(updateBulkObj);
            console.log('updating response', resp)
            if (resp) notify('success', 'Groups Assigned Successfully');
            await fetchUsers();
            handleClose();
        } catch (err) {
            notify('error', err?.response?.data?.message || err.message || 'Something went wrong');
            console.error('Error updating permissions:', err);
        } finally {
            hideBackdrop();
        }
    };
    const handleCloseBulk = () => {
        handleClose()
        setSelectedGroupIds([])
        setOneGroupsDetails([])
        handleClearCheck()
    }
    const colorStyles = [
        { backgroundColor: '#FFF7E0', color: '#D89200', borderColor: '#D89200' },
        { backgroundColor: '#F5F3FF', color: '#7C3AED', borderColor: '#C4B5FD' },
        { backgroundColor: '#FEF2F2', color: '#DC2626', borderColor: '#FCA5A5' },
    ];

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box sx={style}>
                <Typography id="modal-title" sx={{ fontWeight: '600', color: '#000', fontSize: '20px', mb: 2 }} gutterBottom>
                    Bulk Assign Group
                </Typography>
                <Divider />
                <Grid container spacing={1} sx={{ mt: 2 }}>
                    {oneGroupsDetails.map(group => (
                        <Grid container key={group.id}>
                            <Grid item xs={2}>
                                <Checkbox
                                    color="error"
                                    checked={selectedGroupIds.includes(group.group_id)}
                                    onChange={handleToggle(group.group_id)}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography sx={{ color: '#1D242E', marginTop: '3%' }} variant="body1">
                                    {group.group_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={1} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        {selectedGroupIds.map((groupId, index) => {
                            const group = oneGroupsDetails.find(group => group.group_id === groupId);
                            const styles = colorStyles[index % colorStyles.length];
                            return group ? (
                                <Chip
                                    key={group.group_id}
                                    label={group.group_name}
                                    sx={{
                                        marginRight: 1,
                                        marginBottom: 1,
                                        backgroundColor: styles.backgroundColor,
                                        color: styles.color,
                                        borderColor: styles.borderColor,
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        '& .MuiChip-deleteIcon': {
                                            color: styles.color,
                                        },
                                        borderRadius: '4px',
                                        fontWeight: 'bold',
                                    }}
                                    onDelete={() => handleToggle(group?.group_id)({ target: { checked: false } })}
                                />
                            ) : null;
                        })}
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 2 }} />
                <Grid container spacing={1} paddingTop={'24px'}>
                    <Grid item xs={6} />
                    <Grid item xs={3}>
                        <Button
                            variant="contained"
                            color="error"
                            fullWidth
                            onClick={handleUpdate}
                        >
                            UPDATE
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant="outlined"
                            onClick={handleCloseBulk}
                            fullWidth
                            sx={{
                                color: '#000',
                                backgroundColor: 'white',
                                '&:hover': {
                                    borderColor: '#BD1D23',
                                    backgroundColor: 'rgba(189, 29, 35, 0.1)',
                                },
                            }}
                        >
                            CANCEL
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}
