import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Box, Button, Typography, InputAdornment, Chip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditPermissionModal from './edit_permission_modal.component';
import EditIcon from '@mui/icons-material/Edit';
import { events } from 'react-mapbox-gl/lib/map-events';
import { notify } from '../../common/functions/react-toastify.function';
import { deleteGroup } from './userManagementFunctions/delete_group_function';
import AddIcon from '@mui/icons-material/Add';
import NewGroupModal from './new_group_modal.component';
import Swal from 'sweetalert2';
import GroupAndPermissionManagementTable from './group_and_permission_management.component';
const theme = createTheme();

export default function PermissionDataTable(props) {
    const { control, permissions, fetchGroups } = props;
    const [searchText, setSearchText] = useState('');
    const [filteredRows, setFilteredRows] = useState();
    const [openEditPermissionModal, setOpenEditPermissionModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [openGroupModal, setOpenGroupModal] = useState(false);
    const [editGroup, setEditGroup] = useState(null);


    useEffect(() => {
        setFilteredRows(permissions);
    }, [permissions]);


    const handleEditClick = (rowData, event) => {
        setSelectedRowData(rowData);
        setOpenEditPermissionModal(true);
    };

    const handleCloseEditPermissionModal = () => {
        setOpenEditPermissionModal(false);
        setSelectedRowData(null);
    };
    const handleEditGroupClick =(row)=>{
        setSelectedRowData(row)
        setOpenGroupModal(true)
    }
    const handleDeleteGroup = async (rowData, event) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                setSelectedRowData(rowData);
                try {
                    await deleteGroup(rowData?.group_id);
                    notify('success', 'Group Deleted Successfully')
                    await fetchGroups();
                    // handleClose();
                } catch (err) {
                    notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong')
                }
            }
        });
        // try {
        //     await deleteGroup(rowData?.group_id);
        //     notify('success', 'Group Deleted Successfully')
        //     await fetchGroups();
        //     // handleClose();
        // } catch (err) {
        //     notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong')
        // }
    };

    const columns = [
        { field: 'group_name', headerName: 'Group Name', width: 230 },
        {
            field: 'permissions',
            headerName: 'Remarks',
            width: 500,
            renderCell: (params) => (
                <div>
                    {params.value?.map((group, index) => {
                        return (<Chip
                            key={index}
                            label={group.name}
                            sx={{
                                marginRight: 1,
                                marginTop: '1.2%',
                                marginBottom: 1,
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                color: 'rgba(0, 0, 0, 0.8)',
                                borderColor: '0.5px solid rgba(0, 0, 0, 0.1)',
                                borderWidth: 1,
                                borderRadius: '4px',
                                fontWeight: 'bold',
                            }}
                        />)
                    })}
                </div>
            ),
        },
        { field: 'group_description', headerName: 'Description', width: 250 },
        // { field: 'Created', headerName: 'Created', width: 150 }, 
        { field: 'user_count', headerName: 'User', width: 100 }, // Placeholder for user data

        {
            field: 'Action',
            headerName: 'Action',
            width: 170,
            renderCell: (params) => (
                <div style={{ marginTop: '8px' }}>
                    <svg onClick={() => handleEditGroupClick(params.row)} style={{ cursor: 'pointer', marginRight: '16px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM5.92 19H5V18.08L14.06 9.02L14.98 9.94L5.92 19ZM20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3C17.4 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63Z" fill="black" fill-opacity="0.54" />
                    </svg>
                    <svg onClick={() => handleEditClick(params.row)} style={{ cursor: 'pointer', marginRight: '16px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9 15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5 8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96 11.87 14.46 12 15 12Z" fill="black" fill-opacity="0.54" />
                    </svg>
                    <svg onClick={() => handleDeleteGroup(params.row)} style={{ cursor: 'pointer', marginRight: '16px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M14.12 10.47L12 12.59L9.87 10.47L8.46 11.88L10.59 14L8.47 16.12L9.88 17.53L12 15.41L14.12 17.53L15.53 16.12L13.41 14L15.53 11.88L14.12 10.47ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9Z" fill="black" fill-opacity="0.54" />
                    </svg>
                </div>
            ),
        },
    ];
    const handleCloseGroupModal = () => setOpenGroupModal(false);

    return (
        <ThemeProvider theme={theme}>
            <Box>
                <EditPermissionModal
                    open={openEditPermissionModal}
                    handleClose={handleCloseEditPermissionModal}
                    rowData={selectedRowData} // Pass the selected row data to the modal
                    fetchGroups={fetchGroups}
                />
                {/* <NewUserModal open={editUser} row={selectedRowData} handleClose={handleClose} fetchUsers={fetchUsers} /> */}
                <NewGroupModal open={openGroupModal} row={selectedRowData} handleClose={handleCloseGroupModal} fetchGroups={fetchGroups} />
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
                    <Typography variant="h6">
                        <span style={{ color: 'green' }}></span>
                        <span style={{ color: 'red' }}></span>
                    </Typography>
                    <Box marginRight={2}>
                        {/* <Button variant="contained" onClick={() => setOpenEditPermissionModal(true)} style={{ backgroundColor: '#BD1D23' }} startIcon={<RefreshIcon />}>
                            Assign Permission
                        </Button> */}
                        <Button variant="contained" sx={{
                            '&:hover': {
                                borderColor: '#BD1D23',
                                backgroundColor: 'rgba(189, 29, 35, 0.1)', // Optional: Adds a hover effect with a light background
                            },
                        }} onClick={() => setOpenGroupModal(true)} style={{ backgroundColor: '#BD1D23' }} startIcon={<AddIcon />}>
                            New Group
                        </Button>
                    </Box>
                </Box>
                <div style={{ height: 400, width: '99%' }}>
                    {/* <DataGrid
                        rows={permissions}
                        columns={columns}
                        getRowId={(row) => row.group_id}
                        disableSelectionOnClick
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                fontSize: '16px',
                                fontWeight: 600,
                                color: '#1D242E',
                                fontFamily: 'Arial, sans-serif',
                            },
                            '& .-MuiDataGrid-columnHeaderTitle': {
                                fontWeight: '600',
                            },
                            '.css-t89xny-MuiDataGrid-columnHeaderTitle': {
                                fontWeight: '600'
                            },
                            '& .MuiDataGrid-checkboxInput.Mui-checked': {
                                color: '#BD1D23',
                            },
                            '& .MuiDataGrid-row.Mui-selected': {
                                backgroundColor: 'inherit',
                                '&:hover': {
                                    backgroundColor: 'inherit',
                                },
                            },
                            '& .MuiDataGrid-selectedRowCount': {
                                visibility: 'hidden',
                            },
                        }}
                    /> */}
                <GroupAndPermissionManagementTable groups={permissions} fetchGroups={fetchGroups}/>
                </div>
            </Box>
        </ThemeProvider>
    );
}
