import apiClient from '../../../common/functions/api_axios_instance.function';
import { logout } from '../../../common/functions/log_out.function';
export const getAllGroups = async () => {
//   const authState = getAuthenticationState();
 try{ 
  const resp = await apiClient.get(`/groups/showAllGroups`, {
    // headers: {
    //   authorization: `Bearer ${authState.token}`
    // }
  });

  return (resp.data);

} catch (error) {
  if(error?.response?.status === 401) {logout()}
    console.error('Error fetching users:', error);
  }
}