import { Box, Grid, TextField, Typography, createTheme } from '@mui/material';
export const ExpandableTable = ({row}) => {
  const {lat,long,alarm_source,xmin,ymin,first_occurred,last_occurred,xmax,ymax}= row?.original;
return  <Box style={{background:'#FCFCFC'}}>
<Grid container spacing={4} mb={3}>
  <Grid item xs={1}></Grid>
<Grid item xs={2} >
  <Typography style={{color:'#516176',fontSize:'12px',fontWeight:'600',lineHeight:'18px'}}>X-min</Typography>
   <span style={{color:'#667085',fontSize:'12px',fontfontWeight:'400',lineHeight:'18px',fontFamily:'Poppins'}}>{xmin}</span>
   </Grid>
   <Grid item xs={2} >
   <Typography style={{color:'#516176',fontSize:'12px',fontWeight:'600',lineHeight:'18px'}}>Y-min</Typography>
   <span style={{color:'#667085',fontSize:'12px',fontWeight:'400',lineHeight:'18px',fontFamily:'Poppins'}}>{ymin}</span>
   </Grid>
   <Grid item xs={2} >
   <Typography style={{color:'#516176',fontSize:'12px',fontWeight:'600',lineHeight:'18px'}}>First Occured</Typography>
   <span style={{color:'#667085',fontSize:'12px',fontWeight:'400',lineHeight:'18px',fontFamily:'Poppins'}}>{first_occurred}</span>
    </Grid>
   <Grid item xs={2} >
   <Typography style={{color:'#516176',fontSize:'12px',fontWeight:'600',lineHeight:'18px'}}>Latitude</Typography>
   <span style={{color:'#667085',fontSize:'12px',fontWeight:'400',lineHeight:'18px',fontFamily:'Poppins'}}>{lat}</span>
   </Grid>
      <Grid item xs={2} >
   <Typography style={{color:'#516176',fontSize:'12px',fontWeight:'600',lineHeight:'18px'}}>Alarm Source</Typography>
   <span style={{color:'#667085',fontSize:'12px',fontWeight:'400',lineHeight:'18px',fontFamily:'Poppins'}}>{alarm_source}</span>
      </Grid>
   
   </Grid>
   <Grid container spacing={4}>
   <Grid item xs={1}></Grid>
<Grid item xs={2}>
  <Typography style={{color:'#516176',fontSize:'12px',fontWeight:'600',lineHeight:'18px'}}>X-max</Typography>
   <span style={{color:'#667085',fontSize:'12px',fontWeight:'400',lineHeight:'18px',fontFamily:'Poppins'}}>{xmax}</span>
   </Grid>
   <Grid item xs={2} >
   <Typography style={{color:'#516176',fontSize:'12px',fontWeight:'600',lineHeight:'18px'}}>Y-max</Typography>
   <span style={{color:'#667085',fontSize:'12px',fontWeight:'400',lineHeight:'18px',fontFamily:'Poppins'}}>{ymax}</span>
      </Grid>
   <Grid item xs={2} >
   <Typography style={{color:'#516176',fontSize:'12px',fontWeight:'600',lineHeight:'18px'}}>Last Occured</Typography>
   <span style={{color:'#667085',fontSize:'12px',fontWeight:'400',lineHeight:'18px',fontFamily:'Poppins'}}>{last_occurred}</span>
      </Grid>
   <Grid item xs={2} >
   <Typography style={{color:'#516176',fontSize:'12px',fontWeight:'600',lineHeight:'18px'}}>Longitude</Typography>
   <span style={{color:'#667085',fontSize:'12px',fontWeight:'400',lineHeight:'18px',fontFamily:'Poppins'}}>{long}</span>
      </Grid>
   </Grid>
</Box>
}