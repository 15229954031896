// import React, { useRef, useEffect, useState, useMemo } from 'react';
// import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// import northPolygonData from './Geojsons/northPolygonData.geojson';
// import centralAPolygonData from './Geojsons/centralAPolygonData.geojson';
// import pakistanPolygonData from './Geojsons/pakistanPolygonData.geojson';
// import centralBPolygonData from './Geojsons/centralBPolygonData.geojson';
// import southPolygonData from './Geojsons/southPolygonData.geojson';
// import test_cities from './Geojsons/test_cities.json';
// import all_cities from './Geojsons/city_data.json';
// import { getImageUrl } from '../GetImage/GetImage';
// import { styled } from '@mui/material/styles';
// import { Box, Skeleton, Typography, TextField } from '@mui/material';
// import Slider from '@mui/material/Slider';
// import PauseRounded from '@mui/icons-material/PauseRounded';
// import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
// import FastForwardRounded from '@mui/icons-material/FastForwardRounded';
// import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
// import IconButton from '@mui/material/IconButton';
// import TableChartIcon from '@mui/icons-material/TableChart';
// import { findRegionByAlias } from '../../common/functions/find_Region_By_Alias.function';
// import { findCityByAlias } from '../../common/functions/find_City_By_Alias.function';
// import MapIcon from '@mui/icons-material/Map';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dayjs from 'dayjs';
// import axios from 'axios';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import TuneIcon from '@mui/icons-material/Tune';
// import Tooltip from '@mui/material/Tooltip';
// import * as THREE from 'three';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
// import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
// import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
// import { Cities } from '../../enums/cities.enum';
// import './mapStyles.css'
// import apiClient from '../../common/functions/api_axios_instance.function';
// // import 'mapbox-gl/dist/mapbox-gl.css';

// // Extend GLTFLoader class
// class ExtendedGLTFLoader extends GLTFLoader {
//   constructor(manager) {
//     super(manager);
//     this.crossOrigin = 'anonymous'; // Set crossOrigin
//   }
// }

// mapboxgl.accessToken = 'pk.eyJ1IjoidXNtYW4tZ2hhbmkiLCJhIjoiY2xoaGNqODllMGFkZTNtcHIxMXlqcGUwdCJ9.430W3OsMNhVeWxBNktOutA';

// const TinyText = styled(Typography)({
//   fontSize: '0.75rem',
//   // opacity: 0.38,
//   fontWeight: 500,
//   letterSpacing: 0.2,
//   color: '#FFF'
// });

// const Widget = styled('div')(({ theme }) => ({
//   padding: 10,
//   borderRadius: 16,

//   maxWidth: '100%',
//   // margin: 'auto',
//   position: 'relative',
//   zIndex: 1,
//   backgroundColor:
//     theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : '#192126',
//   // backdropFilter: 'blur(40px)',
// }));


// export default function MapMpbn({ setViewType, data, regionData, loading, site, link, markerRef, selectedAlarm, viewType, city, region, ring, setVideoPlayTime, alramType, setAlramType, open, toggleDrawer, applyFilters, state }) {
//   const mapContainer = useRef(null);
//   const map = useRef(null);
//   const stepRef = useRef(0);
//   const [lng, setLng] = useState(69.3451);
//   const [lat, setLat] = useState(30.3753);
//   const [zoom, setZoom] = useState(5);
//   const [sitePopupFlag, setSitePopupFlag] = useState(false);
//   const cityMarkers = useRef([]);
//   const regionMarkers = useRef([]);
//   const alarmMarker = useRef([]);
//   const duration = 86400; // seconds
//   const [position, setPosition] = useState(0);
//   const [paused, setPaused] = useState(true);
//   const [intervalID, setIntervalID] = useState();
//   const [playerHover, setPlayerHover] = useState(false);
//   const mainIconColor = '#000';
//   const [isLoading, setIsLoading] = useState(true);
//   const apiUrl = process.env.REACT_APP_API_URL;

//   const [regions, setRegions] = useState(
//     [
//       { id: 'pakistan', regionName: 'Pakistan', alarmsCount: 0, data: pakistanPolygonData, color: '#009900', center: [72.94996378341335, 34.974994348955335] },
//     ]
//   )
//   const [cities, setCities] = useState(test_cities);

//   const defaultDate = dayjs();
//   const [selectedDate, setSelectedDate] = useState(defaultDate);


//   const handleDateChange = (newDate) => {
//     setSelectedDate(newDate);
//   };

//   useEffect(() => {
//     if (data) {
//       setIsLoading(true);

//       //   const updatedDataArr = regions.map(region => {
//       //     const alarmsCount = data.filter(alarm => alarm.region === region.regionName).length;
//       //     return { ...region, alarmsCount };
//       //   });
//       //   setRegions(updatedDataArr);

//       //   const updatedCitiesDataArr = cities.map(city => {
//       //     const alarmsCount = data.filter(alarm => alarm?.city?.toUpperCase() === city?.name?.toUpperCase()).length;
//       //     return { ...city, alarmsCount };
//       //   });
//       //   setCities(updatedCitiesDataArr);
//       if (map.current) {
//         if (zoom < 8) {
//           //   showRegionMarkers();
//         }

//       }

//       setIsLoading(false);
//     }
//   }, [data, map.current, zoom, open]);

//   useEffect(() => {
//     if (selectedAlarm?.lat && selectedAlarm?.long && selectedAlarm?.xmin && selectedAlarm?.ymin && selectedAlarm?.xmax && selectedAlarm?.ymax) {
//       setLng(selectedAlarm?.lat);
//       setLat(selectedAlarm?.long);
//       if (map.current) {

//         addAlarmMarker([selectedAlarm?.lat, selectedAlarm?.long])
//         map.current.flyTo({
//           center: [selectedAlarm?.lat, selectedAlarm?.long],
//           // zoom: 14,
//           essential: true,
//           duration: 6000,
//         });
//         map.current.fitBounds([
//           [selectedAlarm?.xmin - 0.0003, selectedAlarm?.ymin - 0.0003],
//           [selectedAlarm?.xmax + 0.0003, selectedAlarm?.ymax + 0.0003]
//         ]);
//       }

//     }
//   }, [selectedAlarm])


//   //   useEffect(() => {
//   //     let foundObj = null;
//   //     let zoomTo = 6;
//   //     let lng = 69.3451;
//   //     let lat = 30.3753;

//   //     const fetchData = async () => {
//   //       try {
//   //         let response;
//   //         if (ring) {
//   //           response = await axios.get(`http://api.rov.softoo.co/ring-center`, {
//   //             params: {
//   //               region: region,
//   //               city: city,
//   //               ring: ring
//   //             }
//   //           });
//   //           if (!response?.data?.[0]) {
//   //             foundObj = findCityByAlias(all_cities, city);
//   //             zoomTo = 12;
//   //             lng = foundObj?.lng;
//   //             lat = foundObj?.lat;
//   //           }
//   //         } else if (city) {
//   //           foundObj = findCityByAlias(all_cities, city);
//   //           zoomTo = 12;
//   //           lng = foundObj?.lng;
//   //           lat = foundObj?.lat;
//   //         } else if (region) {
//   //           foundObj = findRegionByAlias(regions, region);
//   //           lng = foundObj?.center[0];
//   //           lat = foundObj?.center[1];
//   //         } else {
//   //           setLng(69.3451);
//   //           setLat(30.3753);
//   //           map.current.flyTo({
//   //             center: [69.3451, 30.3753],
//   //             zoom: 5,
//   //             essential: true,
//   //             duration: 6000,
//   //           });
//   //           return; // Exit early if no city, region, or ring is provided
//   //         }

//   //         if (response?.data?.[0]) {
//   //           foundObj = response.data[0];
//   //           zoomTo = 12.5;
//   //           lng = foundObj?.[0];
//   //           lat = foundObj?.[1];
//   //         } else if (!foundObj) {
//   //           // Handle case when no data is found
//   //           console.error('No data found');
//   //           return;
//   //         }

//   //         setLng(lng);
//   //         setLat(lat);

//   //         map.current.flyTo({
//   //           center: [lng, lat],
//   //           zoom: zoomTo,
//   //           essential: true,
//   //           duration: 6000,
//   //         });
//   //       } catch (error) {
//   //         foundObj = findCityByAlias(all_cities, city);
//   //         zoomTo = 12;
//   //         lng = foundObj?.lng;
//   //         lat = foundObj?.lat;

//   //         setLng(lng);
//   //         setLat(lat);

//   //         map.current.flyTo({
//   //           center: [lng, lat],
//   //           zoom: zoomTo,
//   //           essential: true,
//   //           duration: 6000,
//   //         });
//   //         console.error('Error fetching data:', error);
//   //       }
//   //     };

//   //     if (map.current) {
//   //       fetchData();
//   //     }
//   // }, [city, region, ring, map.current]);


//   //   function findCityByAlias(data, alias) {
//   //     for (const city of data) {
//   //       if (city?.name?.toUpperCase() === alias?.toUpperCase()) {
//   //         return city;
//   //       }
//   //     }
//   //     return null;
//   //   }
//   //   function findRegionByAlias(data, alias) {
//   //     for (const region of data) {
//   //       if (region?.regionName?.toUpperCase() === alias?.toUpperCase()) {
//   //         return region;
//   //       }
//   //     }
//   //     return null;
//   //   }


//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         let response, foundObj, lng, lat, zoomTo;
//         const beforeUnderscoreLink = link.split('_')[0];
//         if (ring || link || site) {
//           response = await apiClient.get(`/mpbn/ringCenter`, {
//             params: { city, linkName: link, site: site },
//           });

//           if (response?.data?.[0]) {
//             foundObj = response.data[0];
//             lng = foundObj?.[0];
//             lat = foundObj?.[1];
//             zoomTo = 7; // Higher zoom level for site

//             // Ensure zoom level is appropriate
//             zoomTo = Math.min(Math.max(zoomTo, 7), 9); // Adjust as needed

//             setLng(lng);
//             setLat(lat);
//             { console.log('MarkerRefMpbn', markerRef) }
//             // Remove the previous marker if it exists
//             if (markerRef.current) {
//               markerRef.current.remove();
//             }

//             // Add a new marker to the map
//             markerRef.current = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map.current);

//             // Use easeTo for faster zoom
//             map.current.flyTo({
//               center: [lng, lat],
//               zoom: zoomTo,
//               duration: 2000, // Adjust duration for smoother transition
//             });

//           } else {
//             foundObj = findCityByAlias(all_cities, city);
//             lng = foundObj?.lng;
//             lat = foundObj?.lat;
//             zoomTo = 7; // Default zoom level for cities
//           }
//         } else if (city) {
//           foundObj = findCityByAlias(all_cities, city);
//           lng = foundObj?.lng;
//           lat = foundObj?.lat;
//           zoomTo = 7; // Default zoom level for cities
//         } else if (region) {
//           foundObj = findRegionByAlias(regions, region);
//           lng = foundObj?.center[0];
//           lat = foundObj?.center[1];
//           zoomTo = 7; // Default zoom level for regions
//         } else {
//           lng = 69.3451;
//           lat = 30.3753;
//           zoomTo = 5; // Default zoom level for the whole map
//         }

//         if (lng !== undefined && lat !== undefined) {
//           setLng(lng);
//           setLat(lat);

//           // Use easeTo for faster zoom
//           map.current.flyTo({
//             center: [lng, lat],
//             zoom: zoomTo,
//             duration: 2000, // Adjust duration for smoother transition
//           });

//         } else {
//           console.error('No data found');
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);

//         const fallbackCity = findCityByAlias(all_cities, city);
//         if (fallbackCity) {
//           const lng = fallbackCity?.lng;
//           const lat = fallbackCity?.lat;
//           const zoomTo = 7; // Default zoom level for fallback

//           setLng(lng);
//           setLat(lat);

//           // Use easeTo for faster zoom
//           map.current.flyTo({
//             center: [lng, lat],
//             zoom: zoomTo,
//             duration: 2000, // Adjust duration for smoother transition
//           });
//         }
//       }
//     };
//     if (map.current) {
//       fetchData();
//     }
//   }, [city, site, link, map, all_cities]);



//   useEffect(() => {
//     if (isLoading === false) {
//       if (!map.current) {
//         map.current = new mapboxgl.Map({
//           container: mapContainer.current,
//           style: 'mapbox://styles/mapbox/standard',//'mapbox://styles/mapbox/streets-v12?optimize=true',
//           center: [lng, lat],
//           zoom: zoom,
//           attributionControl: false,
//           pitch: 0,
//           antialias: true,
//         });
//         // map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
//         map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
//         map.current.on('load', () => {


//           regions.forEach(region => {
//             map.current.addSource(region.id, {
//               type: 'geojson',
//               data: region.data,
//             });
//             map.current.addLayer({
//               id: region.id,
//               // type: 'fill',
//               type: 'line',
//               source: region.id,
//               paint: {
//                 'line-color': region.color,
//                 'line-width': 3,
//                 'line-opacity': 0.4,
//               },
//             });
//             map.current.addLayer({
//               type: 'line',
//               source: region.id,
//               id: `${region.id}-dashed`,
//               paint: {
//                 'line-color': region.color,
//                 'line-width': 3,
//                 'line-opacity': 1,
//                 'line-dasharray': [0, 4, 3],
//               },
//             });
//             // const dashArraySequence = [
//             //   [0, 4, 3],
//             //   [0.5, 4, 2.5],
//             //   [1, 4, 2],
//             //   [1.5, 4, 1.5],
//             //   [2, 4, 1],
//             //   [2.5, 4, 0.5],
//             //   [3, 4, 0],
//             //   [0, 0.5, 3, 3.5],
//             //   [0, 1, 3, 3],
//             //   [0, 1.5, 3, 2.5],
//             //   [0, 2, 3, 2],
//             //   [0, 2.5, 3, 1.5],
//             //   [0, 3, 3, 1],
//             //   [0, 3.5, 3, 0.5],
//             // ];

//             // const animateDashArray = (timestamp) => {
//             //   const newStep = parseInt((timestamp / 120) % dashArraySequence.length);

//             //   // if (newStep !== stepRef.current) {
//             //   map.current.setPaintProperty(`${region.id}-dashed`, 'line-dasharray', dashArraySequence[stepRef.current]);
//             //   stepRef.current = newStep;
//             //   // }

//             //   requestAnimationFrame(animateDashArray);
//             // };

//             // animateDashArray(0);

//             if (!map.current?._markers) {
//               //   showRegionMarkers();
//             }


//             // const regionMarker = new mapboxgl.Marker({
//             //   element: createRegionMarkerElement('N1', region?.alarmsCount)
//             // })
//             //   .setLngLat(region.center)
//             //   .addTo(map.current);
//             // regionMarkers.current.push(regionMarker);

//             // regionMarker.getElement().addEventListener('click', () => {
//             //   setLng(region.center[0]);
//             //   setLat(region.center[1]);
//             //   // setZoom(8);

//             //   map.current.flyTo({
//             //     center: region.center,
//             //     zoom: 8,
//             //     essential: true,
//             //     duration: 6000,
//             //   });
//             //   addCityMarkers(region.center);
//             // });
//           });
//         });
//         addWmsLayer()
//         addWmsSitesLayer()

//         map.current.on('load', () => {
//           // setLng(map.current.getCenter().lng.toFixed(4));
//           // setLat(map.current.getCenter().lat.toFixed(4));
//           // setZoom(map.current.getZoom().toFixed(2));

//           const currentZoom = map.current.getZoom();
//           if (currentZoom >= 7 && currentZoom <= 9.5) {
//             addCityMarkers(map.current.getCenter());
//           } else {
//             removeCityMarkers();
//             // addCityMarkers(map.current.getCenter());
//             if (!map.current?._markers) {
//               //   showRegionMarkers();
//             }
//           }
//           if (currentZoom > 9) {
//             cities?.forEach(item => {
//               removeThreeDLayer(item.lng, item.lat);
//             })
//           }
//           cities?.forEach(item => {
//             if (item?.name === Cities.lahore || item?.name === Cities.faisalabad || item?.name === Cities.karachi || item?.name === Cities.islamabad) {
//               createCityThreeDMarker(item.lng, item.lat, item?.alarmsCount, item?.name);
//             }
//           })
//           // addWmsLayer()
//           //   addWmsSitesLayer()
//           // Check if the WMS layer needs to be removed
//           //   if (currentZoom ) {
//           //     addWmsLayer()
//           //     addWmsSitesLayer()
//           //   } else {
//           //     removeWmsLayer();
//           //     removeWmsSitesLayer();
//           //   }

//           // if (currentZoom <= 13) {
//           //   if (alarmMarker.current.length > 0) {
//           //     removeAlarmsMarker()
//           //   }
//           // }
//         });
//         //s const navControlContainer = mapContainer.current.querySelector('.mapboxgl-ctrl-bottom-right');
//         // if (navControlContainer) {

//         //   if (state.right) {
//         //     navControlContainer.classList.add('nav-control-custom');
//         //   } else {
//         //     navControlContainer.classList.remove('nav-control-custom');
//         //   }
//         // }
//       } else {
//         // if (zoom >= 8) {
//         //   addCityMarkers(map.current.getCenter());
//         // } else {
//         //   removeCityMarkers();
//         //   if (!map.current?._markers) {
//         //     // showRegionMarkers();
//         //   }
//         // }

//         if (zoom > 11) {
//           cities?.forEach(item => {
//             removeThreeDLayer(item.lng, item.lat);
//           })
//         }
//         // addWmsLayer()
//         //   addWmsSitesLayer()
//         // Check if the WMS layer needs to be removed
//         // if (zoom) {
//         //   addWmsLayer()
//         //   addWmsSitesLayer()
//         // } else {
//         //   removeWmsLayer();
//         //   removeWmsSitesLayer();
//         // }
//         // If the map already exists, update its center and zoom
//         // map.current.setCenter([lng, lat]);
//         // map.current.setZoom(zoom);

//       }
//     }
//   }, [isLoading, open, state.right]);

//   // useEffect(() => {
//   //   if (!isLoading && map.current) {
//   //     // Initialize map and controls
//   //     if (!map.current.getContainer().querySelector('.mapboxgl-ctrl-zoom-in')) {
//   //       map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
//   //     }

//   //     // Simulate click after a short delay
//   //     setTimeout(() => {
//   //       const zoomInButton = map.current.getContainer().querySelector('.mapboxgl-ctrl-zoom-in');
//   //       if (zoomInButton) {
//   //         zoomInButton.click();
//   //          // Simulate a click on the zoom in button
//   //       }
//   //     }, 500); // Adjust delay as needed
//   //   }
//   // }, [isLoading]);

//   const createRegionMarkerElement = (cityName, number) => {
//     const markerElement = document.createElement('div');
//     markerElement.className = number > 0 ? 'region-marker' : ''; //region-marker-green

//     // Add circular cutout for the image
//     const imageCutout = document.createElement('div');
//     imageCutout.className = number > 0 ? 'image-cutout' : '';
//     markerElement.appendChild(imageCutout);

//     // Add pin tip
//     const pinTip = document.createElement('div');
//     pinTip.className = 'pin-tip';
//     markerElement.appendChild(pinTip);

//     // Add city name
//     // const cityNameElement = document.createElement('div');
//     // cityNameElement.className = 'city-name';
//     // cityNameElement.textContent = cityName;
//     // markerElement.appendChild(cityNameElement);

//     // Add number in the center
//     if (number > 0) {
//       const numberElement = document.createElement('div');
//       numberElement.className = number > 0 ? 'number' : '';
//       numberElement.textContent = number;
//       markerElement.appendChild(numberElement);
//     }

//     return markerElement;
//   };

//   const createCityMarkerElement = (iconUrl, cityName, number) => {
//     const markerElement = document.createElement('div');
//     markerElement.className = 'custom-marker';

//     // Add number on top
//     const numberElement = document.createElement('div');
//     numberElement.className = number > 0 ? 'number' : '';
//     numberElement.textContent = number;
//     markerElement.appendChild(numberElement);

//     // Add plus sign on top of the number
//     // const plusSign = document.createElement('div');
//     // plusSign.className = number > 0 ? 'plus-sign' : '';
//     // plusSign.textContent = '+';
//     // numberElement.appendChild(plusSign);

//     // Add circular cutout for the image
//     const imageCutout = document.createElement('div');
//     imageCutout.className = 'image-cutout';
//     markerElement.appendChild(imageCutout);

//     // Set background image for the cutout
//     const image = document.createElement('img');
//     image.src = iconUrl;
//     imageCutout.appendChild(image);

//     // Add pin tip
//     const pinTip = document.createElement('div');
//     pinTip.className = 'pin-tip';
//     markerElement.appendChild(pinTip);

//     // Add city name
//     const cityNameElement = document.createElement('div');
//     cityNameElement.className = 'city-name';
//     cityNameElement.textContent = cityName;
//     markerElement.appendChild(cityNameElement);

//     return markerElement;
//   };

//   const createCityThreeDMarker = (lng, lat, alarmsCount, name) => {
//     if (!map.current.getLayer(`3d-model-${lng}-${lat}`)) {

//       const modelOrigin = [lng, lat];
//       const modelAltitude = 6000;
//       const modelRotate = [Math.PI / 2, 0, 0];
//       const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);

//       const scaleFactor = 9000; // Adjust this factor to increase the model size
//       const modelTransform = {
//         translateX: modelAsMercatorCoordinate.x,
//         translateY: modelAsMercatorCoordinate.y,
//         translateZ: modelAsMercatorCoordinate.z,
//         rotateX: modelRotate[0],
//         rotateY: modelRotate[1],
//         rotateZ: modelRotate[2],
//         scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * scaleFactor,
//       };
//       const startTime = Date.now();
//       const customLayer = {
//         id: `3d-model-${lng}-${lat}`,
//         type: 'custom',
//         renderingMode: '3d',
//         onAdd: function (map, gl) {
//           this.camera = new THREE.Camera();
//           this.scene = new THREE.Scene();

//           // Add ambient light
//           const ambientLight = new THREE.AmbientLight(0x404040, 1); // soft white light with full intensity
//           this.scene.add(ambientLight);

//           // Add hemisphere light for even lighting
//           const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x444444, 0.6);
//           hemisphereLight.position.set(0, 200, 0);
//           this.scene.add(hemisphereLight);

//           // Add multiple directional lights
//           const directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.8);
//           directionalLight1.position.set(0, -70, 100).normalize();
//           this.scene.add(directionalLight1);

//           const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.8);
//           directionalLight2.position.set(0, 70, 100).normalize();
//           this.scene.add(directionalLight2);

//           const directionalLight3 = new THREE.DirectionalLight(0xffffff, 0.8);
//           directionalLight3.position.set(70, 0, 100).normalize();
//           this.scene.add(directionalLight3);

//           const directionalLight4 = new THREE.DirectionalLight(0xffffff, 0.8);
//           directionalLight4.position.set(-70, 0, 100).normalize();
//           this.scene.add(directionalLight4);

//           const directionalLight5 = new THREE.DirectionalLight(0xffffff, 0.8);
//           directionalLight5.position.set(100, 0, 100).normalize();
//           this.scene.add(directionalLight5);

//           // Add a top light
//           const topLight = new THREE.DirectionalLight(0xffffff, 1);
//           topLight.position.set(0, 200, 100).normalize(); // Positioned directly above the model
//           this.scene.add(topLight);

//           // Add point light
//           const pointLight = new THREE.PointLight(0xffffff, 1, 100);
//           pointLight.position.set(50, 50, 50);
//           this.scene.add(pointLight);

//           // Add spotlight
//           const spotLight = new THREE.SpotLight(0xffffff);
//           spotLight.position.set(15, 40, 35);
//           spotLight.castShadow = true;
//           this.scene.add(spotLight);

//           const loader = new GLTFLoader();
//           loader.load(
//             `/models/${name}.gltf`,
//             (gltf) => {
//               const model = gltf.scene;

//               // Preserve original materials
//               model.traverse((node) => {
//                 if (node.isMesh) {
//                   node.material = node.material.clone(); // Clone the existing material to ensure lighting compatibility
//                   node.material.needsUpdate = true;
//                 }
//               });

//               this.scene.add(model);

//               // Load the font and create the text
//               const fontLoader = new FontLoader();
//               if (alarmsCount > 0) {
//                 fontLoader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', (font) => {
//                   const textGeometry = new TextGeometry(`${alarmsCount}`, {
//                     font: font,
//                     size: 1,
//                     height: 0.2,
//                   });

//                   const textMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
//                   const textMesh = new THREE.Mesh(textGeometry, textMaterial);

//                   // Adjust the position of the text to place it on top of the model
//                   textMesh.position.set(0, 7, 0); // Adjust these values as necessary
//                   this.scene.add(textMesh);
//                 });
//               }
//             },
//             undefined,
//             (error) => {
//               console.error('An error happened', error);
//             }
//           );

//           this.map = map;

//           this.renderer = new THREE.WebGLRenderer({
//             canvas: map.getCanvas(),
//             context: gl,
//             antialias: true,
//           });

//           this.renderer.autoClear = false;

//           // Initialize raycaster and mouse vector
//           this.raycaster = new THREE.Raycaster();
//           this.mouse = new THREE.Vector2();

//           // Add click event listener
//           // map.getCanvas().addEventListener('click', (event) => {
//           //     const rect = map.getCanvas().getBoundingClientRect();
//           //     this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
//           //     this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

//           //     this.raycaster.setFromCamera(this.mouse, this.camera);

//           //     const intersects = this.raycaster.intersectObjects(this.scene.children, true);
//           //    if (intersects.length > 0) {
//           //       const clickedPoint = intersects[0].point;
//           //         const clickedLngLat = map.unproject([event.clientX, event.clientY]);
//           //        map.flyTo({
//           //             center: [clickedLngLat.lng, clickedLngLat.lat],
//           //             zoom: 10,
//           //             essential: true,
//           //             duration: 2000,
//           //         });
//           //       addWmsLayer();
//           //       addWmsSitesLayer()
//           //     }
//           // });
//         },
//         render: function (gl, matrix) {
//           const rotationSpeed = 0.001;
//           const currentTime = Date.now();
//           const rotationY = rotationSpeed * (currentTime - startTime);
//           const tiltX = Math.PI / 6; // Tilt angle on the X-axis (adjust this value)

//           // Recalculate the modelTransform
//           const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);
//           const modelTransform = {
//             translateX: modelAsMercatorCoordinate.x,
//             translateY: modelAsMercatorCoordinate.y,
//             translateZ: modelAsMercatorCoordinate.z,
//             rotateX: modelRotate[0],
//             rotateY: modelRotate[1] + rotationY, // Add the rotationY to the model's rotation
//             rotateZ: modelRotate[2],
//             scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * scaleFactor,
//           };

//           const m = new THREE.Matrix4().fromArray(matrix);
//           const l = new THREE.Matrix4()
//             .makeTranslation(
//               modelTransform.translateX,
//               modelTransform.translateY,
//               modelTransform.translateZ
//             )
//             .scale(
//               new THREE.Vector3(
//                 modelTransform.scale,
//                 -modelTransform.scale,
//                 modelTransform.scale
//               )
//             )
//             .multiply(new THREE.Matrix4().makeRotationX(tiltX))
//             .multiply(new THREE.Matrix4().makeRotationY(rotationY));

//           this.camera.projectionMatrix = m.multiply(l);
//           this.renderer.resetState();
//           this.renderer.render(this.scene, this.camera);
//           this.map.triggerRepaint();
//         },
//       };
//       map.current.addLayer(customLayer);
//     }
//   };

//   const addCityMarkers = (center) => {
//     if (!map.current.getSource('cities')) {
//       removeRegionMarkers(); // Remove region markers
//       cities?.forEach(item => {
//         if (item?.name === Cities.lahore || item?.name === Cities.faisalabad || item?.name === Cities.karachi || item?.name === Cities.islamabad) {
//           //   createCityThreeDMarker(item.lng, item.lat, item?.alarmsCount, item?.name);
//         }
//         else {
//           const cityMarker = new mapboxgl.Marker({
//             element: createCityMarkerElement(getImageUrl(item?.name), item?.name, item?.alarmsCount)
//           })
//             .setLngLat([item.lng, item.lat])
//             .addTo(map.current);
//           cityMarkers.current.push(cityMarker);
//           cityMarker.getElement().addEventListener('click', () => {
//             setLng(item.lng);
//             setLat(item.lat);
//             // setZoom(12);
//             map.current.flyTo({
//               center: [item.lng, item.lat],
//               //   zoom: 12,
//               essential: true,
//               duration: 6000,
//             });
//             addWmsLayer();
//             addWmsSitesLayer()
//           });
//         }
//       });
//     }
//   };
//   const addAlarmMarker = (center) => {
//     if (alarmMarker.current.length > 0) {
//       removeAlarmsMarker()
//     }
//     const alarmMarkers = new mapboxgl.Marker()
//       .setLngLat(center)
//       .addTo(map.current);
//     alarmMarker.current.push(alarmMarkers);
//   };

//   const removeCityMarkers = () => {
//     cityMarkers.current.forEach(marker => marker.remove());
//     cityMarkers.current = [];
//     // showRegionMarkers(); // Show region markers
//   };

//   const addWmsLayer = async () => {
//     try {
//       if (map.current.getSource('linksLayers')) {
//         removeWmsLayer()
//       }
//       if (!map.current.getSource('linksLayers')) {
//         const response = await fetch(
//           'https://geoserver.softoo.co/geoserver/mpbn_geo_data/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mpbn_geo_data%3Ah_mpbn_geo_data&maxFeatures=10000&outputFormat=application%2Fjson'
//         );
//         if (!response.ok) {
//           throw new Error('Failed to fetch data');
//         }

//         const data = await response.json();
//         map.current.addSource('linksLayers', {
//           type: 'geojson',
//           data: data
//         });

//         map.current.addLayer({
//           id: 'linksLayers',
//           type: 'line',
//           source: 'linksLayers',
//           // layout: { 'line-join': 'round', 'line-cap': 'round' },
//           paint: {
//             // 'line-color': ['get', 'color'],
//             'line-color': [
//               'coalesce',
//               ['get', 'color'],
//               '#66773c' 
//             ],
//             'line-opacity': 0.4,
//             'line-width': 3
//           }
//         });
//         map.current.addLayer({
//           type: 'line',
//           source: 'linksLayers',
//           id: 'linksLayers-dashed',
//           paint: {
//             // 'line-color': ['get', 'color'],
//             'line-color': [
//               'coalesce',
//               ['get', 'color'],
//               '#66773c' // default to green if 'color' is null or undefined
//             ],
//             'line-width': 3,
//             'line-dasharray': [0, 4, 3],
//           },
//         });
//         const dashArraySequence = [
//           [0, 4, 3],
//           [0.5, 4, 2.5],
//           [1, 4, 2],
//           [1.5, 4, 1.5],
//           [2, 4, 1],
//           [2.5, 4, 0.5],
//           [3, 4, 0],
//           [0, 0.5, 3, 3.5],
//           [0, 1, 3, 3],
//           [0, 1.5, 3, 2.5],
//           [0, 2, 3, 2],
//           [0, 2.5, 3, 1.5],
//           [0, 3, 3, 1],
//           [0, 3.5, 3, 0.5],
//         ];

//         const animateDashArray = (timestamp) => {
//           const newStep = parseInt((timestamp / 120) % dashArraySequence.length);

//           // if (newStep !== stepRef.current) {
//           map.current.setPaintProperty('linksLayers-dashed', 'line-dasharray', dashArraySequence[stepRef.current]);
//           stepRef.current = newStep;
//           // }

//           requestAnimationFrame(animateDashArray);
//         };

//         animateDashArray(0);

//         // Add event listeners to change cursor style
//         map.current.on('mouseenter', 'linksLayers', () => {
//           map.current.getCanvasContainer().style.cursor = 'pointer';
//         });

//         map.current.on('mouseleave', 'linksLayers', () => {
//           map.current.getCanvasContainer().style.cursor = '';
//         });

//         // Add click event listener for popups
//         // map.current.on('click', 'linksLayers', (e) => {
//         //   const features = map.current.queryRenderedFeatures(e.point, { layers: ['linksLayers'] });

//         //   if (!features?.length) {
//         //     return;
//         //   }
//         // const coordinates = features[0].geometry.coordinates.slice();
//         //   const properties = e?.features[0]?.properties;
//         //   // const lngLat = e?.lngLat;
//         //  const data =  GetToolTipData(properties?.layer_name)
//         //   const popupHtml =
//         //     `<div class="tooltip-container"> 
//         //                   <div class="title_container_tooltip"> 
//         //                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
//         //                       <path d="M17.6647 13.1271L10.6688 0.977643C10.4939 0.679986 10.2444 0.433184 9.94476 0.261698C9.64517 0.0902134 9.30597 0 8.96077 0C8.61557 0 8.27636 0.0902134 7.97677 0.261698C7.67718 0.433184 7.42761 0.679986 7.25279 0.977643L0.256856 13.1271C0.0886461 13.415 0 13.7425 0 14.0759C0 14.4094 0.0886461 14.7368 0.256856 15.0247C0.429438 15.3242 0.678587 15.5723 0.978737 15.7437C1.27889 15.9151 1.61923 16.0035 1.96484 15.9999H15.9567C16.302 16.0032 16.642 15.9146 16.9419 15.7433C17.2417 15.5719 17.4906 15.3239 17.6631 15.0247C17.8315 14.7369 17.9205 14.4096 17.9207 14.0761C17.921 13.7427 17.8326 13.4152 17.6647 13.1271ZM8.32078 6.39999C8.32078 6.23025 8.3882 6.06747 8.50822 5.94745C8.62825 5.82742 8.79103 5.76 8.96077 5.76C9.13051 5.76 9.29329 5.82742 9.41331 5.94745C9.53333 6.06747 9.60076 6.23025 9.60076 6.39999V9.59996C9.60076 9.7697 9.53333 9.93248 9.41331 10.0525C9.29329 10.1725 9.13051 10.24 8.96077 10.24C8.79103 10.24 8.62825 10.1725 8.50822 10.0525C8.3882 9.93248 8.32078 9.7697 8.32078 9.59996V6.39999ZM8.96077 13.4399C8.7709 13.4399 8.5853 13.3836 8.42743 13.2781C8.26956 13.1726 8.14651 13.0227 8.07385 12.8473C8.00119 12.6719 7.98218 12.4789 8.01922 12.2926C8.05627 12.1064 8.1477 11.9354 8.28195 11.8011C8.41621 11.6669 8.58726 11.5754 8.77348 11.5384C8.9597 11.5013 9.15273 11.5204 9.32814 11.593C9.50356 11.6657 9.65349 11.7887 9.75897 11.9466C9.86446 12.1045 9.92076 12.2901 9.92076 12.4799C9.92076 12.7345 9.81962 12.9787 9.63958 13.1587C9.45955 13.3388 9.21537 13.4399 8.96077 13.4399Z" fill="#BD1D23"/>
//         //                     </svg>
//         //                     <div class="title_text_container_tooltip">
//         //                       <h3 class="tooltip-heading">${properties?.layer_name || '--'}</h3>
//         //                       <p class="tooltip-item">Length (km): ${e?.features[0]?._vectorTileFeature?._values?.length || '--'}</p>
//         //                       <p class="popup-item"><strong>Commission Date:</strong> ${properties?.commissioned_at ? new Date(properties.commissioned_at).toLocaleDateString('en-GB') : '--'}</p>
//         //                     </div>               
//         //                   </div>
//         //               </div>`

//         //   if (sitePopupFlag === false) {

//         //     new mapboxgl.Popup({ closeButton: false })
//         //       .setHTML(popupHtml)
//         //       .addTo(map.current);
//         //   }
//         //    // Zoom in on the clicked point
//         // //  map.current.flyTo({
//         // //   // center: coordinates,
//         // //   // zoom: 11 // Adjust zoom level as needed
//         // // });
//         // });

//         map.current.on('click', 'linksLayers', async (e) => {
//           // e.stopPropagation(); // Prevent event propagation to avoid unnecessary map behaviors
//           const features = map.current.queryRenderedFeatures(e.point, {
//             layers: ['linksLayers']
//           });

//           if (!features.length) return;

//           const feature = features[0];
//           const properties = feature.properties;
//           const trunksData = await GetToolTipData(properties?.layer_name)
//           console.log('trunksData', trunksData)
//           const midPoint = feature.geometry.coordinates[Math.floor(feature.geometry.coordinates.length / 2)];

//           const trunksHtml = trunksData?.trunks.map(trunk => {
//             const colorDescription = trunk.color || 'dot'; 

//             return `
//           <p class="popup-item">
//             <strong>${trunk?.link_name}:</strong> 
//             <span style="display: inline-block; width: 10px; height: 10px; background-color: ${trunk?.color || 'green'}; border-radius: 50%; margin-left: 5px;"></span>
//           </p>`;
//           }).join('');

//           const popupHtml = `
//             <div class="tooltip-container" style="height: 200px;overflow-x: hidden;width:213px;margin-top:8% ;padding-top:5px"> 
//               <div style="width: 95%; height: 4px; border-radius:10px; background-color: ${trunksData?.overAllColor || 'green'};"></div>
//               <div class="title_container_tooltip"> 
//                 <button id="popup-close-btn" style="position: absolute; top: 4px; right: 10px; background: none; border: none; font-size: 18px; cursor: pointer;">&times;</button>
//                 <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
//                               <path d="M17.6647 13.1271L10.6688 0.977643C10.4939 0.679986 10.2444 0.433184 9.94476 0.261698C9.64517 0.0902134 9.30597 0 8.96077 0C8.61557 0 8.27636 0.0902134 7.97677 0.261698C7.67718 0.433184 7.42761 0.679986 7.25279 0.977643L0.256856 13.1271C0.0886461 13.415 0 13.7425 0 14.0759C0 14.4094 0.0886461 14.7368 0.256856 15.0247C0.429438 15.3242 0.678587 15.5723 0.978737 15.7437C1.27889 15.9151 1.61923 16.0035 1.96484 15.9999H15.9567C16.302 16.0032 16.642 15.9146 16.9419 15.7433C17.2417 15.5719 17.4906 15.3239 17.6631 15.0247C17.8315 14.7369 17.9205 14.4096 17.9207 14.0761C17.921 13.7427 17.8326 13.4152 17.6647 13.1271ZM8.32078 6.39999C8.32078 6.23025 8.3882 6.06747 8.50822 5.94745C8.62825 5.82742 8.79103 5.76 8.96077 5.76C9.13051 5.76 9.29329 5.82742 9.41331 5.94745C9.53333 6.06747 9.60076 6.23025 9.60076 6.39999V9.59996C9.60076 9.7697 9.53333 9.93248 9.41331 10.0525C9.29329 10.1725 9.13051 10.24 8.96077 10.24C8.79103 10.24 8.62825 10.1725 8.50822 10.0525C8.3882 9.93248 8.32078 9.7697 8.32078 9.59996V6.39999ZM8.96077 13.4399C8.7709 13.4399 8.5853 13.3836 8.42743 13.2781C8.26956 13.1726 8.14651 13.0227 8.07385 12.8473C8.00119 12.6719 7.98218 12.4789 8.01922 12.2926C8.05627 12.1064 8.1477 11.9354 8.28195 11.8011C8.41621 11.6669 8.58726 11.5754 8.77348 11.5384C8.9597 11.5013 9.15273 11.5204 9.32814 11.593C9.50356 11.6657 9.65349 11.7887 9.75897 11.9466C9.86446 12.1045 9.92076 12.2901 9.92076 12.4799C9.92076 12.7345 9.81962 12.9787 9.63958 13.1587C9.45955 13.3388 9.21537 13.4399 8.96077 13.4399Z" fill="#BD1D23"/>
//                            </svg>
//                 <div class="title_text_container_tooltip">
//                   <h3 class="tooltip-heading" style="font-size:12px;">${properties?.layer_name || '--'}</h3>
//                   <p class="popup-item"><strong>Length (km):</strong> ${features[0]?._vectorTileFeature?._values?.length || '--'}</p>
//                   <p class="popup-item"><strong>Trunk Id:</strong> ${trunksData?.trunk_id}</p>
//                   <p class="popup-item"><strong>Total Links:</strong> ${trunksData?.totalLinks}</p>
//                   <p class="popup-item"><strong>Total Broken:</strong> ${trunksData?.totalBroken}</p>

//                   ${trunksHtml} <!-- Insert trunk details here -->
//                    </div>               
//               </div>
//             </div>`;
//           const popup = new mapboxgl.Popup({ closeButton: false })
//             .setLngLat(midPoint)
//             .setHTML(popupHtml)
//             .addTo(map.current);

//           // Add event listener to close the popup when the close button is clicked
//           document
//             .getElementById('popup-close-btn')
//             .addEventListener('click', () => {
//               popup.remove();
//             });
//         });
//       }
//     } catch (error) {
//       console.error('Error adding WFS layer:', error);
//     }
//   };
//   const GetToolTipData = async (layerName) => {
//     const responseData = await apiClient.get(`/mpbn/trunkData/`, {
//       params: { linkName: layerName },
//     });
//     return responseData.data.response
//   }
//   const addWmsSitesLayer = async () => {
//     try {
//       if (!map.current.getSource('sitesLayers')) {
//         const response = await fetch(
//           'https://geoserver.softoo.co/geoserver/mpbn_geo_data/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mpbn_geo_data%3Ah_mpbn_sites_geo_data&maxFeatures=1000&outputFormat=application%2Fjson'
//         );
//         if (!response.ok) {
//           throw new Error('Failed to fetch data');
//         }
//         const data = await response.json();

//         map.current.addSource('sitesLayers', {
//           type: 'geojson',
//           data: data
//         });

//         map.current.addLayer({
//           id: 'sitesLayers',
//           type: 'circle',
//           source: 'sitesLayers',
//           paint: {
//             'circle-radius': 4.8,
//             'circle-color': '#cc00cc',
//             'circle-stroke-color': '#ff4dff',
//             'circle-stroke-width': 3.5,
//             'circle-stroke-opacity': 0.5,
//             // 'circle-opacity': 0.5
//           }
//         });
//         // Add event listeners to change cursor style
//         map.current.on('mouseenter', 'sitesLayers', () => {
//           map.current.getCanvasContainer().style.cursor = 'pointer';
//         });

//         map.current.on('mouseleave', 'sitesLayers', () => {
//           map.current.getCanvasContainer().style.cursor = '';
//         });
//         // Add onclick event listener
//         //   map.current.on('click', 'sitesLayers', (e) => {

//         //     const features = map.current.queryRenderedFeatures(e?.point, { layers: ['sitesLayers'] });

//         //     if (!features?.length) {
//         //       return;
//         //     }

//         //     // const properties = features[0].properties;
//         //     const coordinates = e?.features[0]?.geometry?.coordinates?.slice();
//         //     const properties = e?.features[0]?.properties;
//         //     const popupHtml = `
//         //     <div class="popup-container">
//         //     <p class="popup-item"><strong>Site ID:</strong> ${properties?.['site id'] || '--'}</p>
//         //     <p class="popup-item"><strong>Region:</strong> ${properties?.region || '--'}</p>
//         //     <p class="popup-item"><strong>City:</strong> ${properties?.city || '--'}</p>
//         //     </div>
//         // `;
//         //     {/* <h3 class="popup-heading">${properties?.bp || '--'}</h3>

//         //         <p class="popup-item"><strong>Cutover Date:</strong> ${properties?.['cutover da'] || '--'}</p>
//         //         <p class="popup-item"><strong>Fiber POP:</strong> ${properties?.['fiber pop'] || '--'}</p>

//         //         <p class="popup-item"><strong>Ring Name:</strong> ${properties?.['ring name'] || '--'}</p>
//         //         <p class="popup-item"><strong>Ring Unique:</strong> ${properties?.['ring uniqu'] || '--'}</p>

//         //         <p class="popup-item"><strong>Status:</strong> ${properties?.status || '--'}</p> */}
//         //     setSitePopupFlag(true);

//         //     new mapboxgl.Popup()
//         //       .setLngLat(coordinates)
//         //       .setHTML(popupHtml)
//         //       .addTo(map.current)
//         //       .on('close', () => {
//         //         setSitePopupFlag(false);
//         //       });

//         //   });
//         //   map.current.flyTo({
//         //     // center: coordinates,
//         //     zoom: 14 // Adjust zoom level as needed
//         //   });
//         map.current.on('click', 'sitesLayers', (e) => {
//           const features = map.current.queryRenderedFeatures(e?.point, { layers: ['sitesLayers'] });

//           if (!features?.length) {
//             return;
//           }

//           const coordinates = features[0].geometry.coordinates.slice();
//           const properties = features[0].properties;

//           const popupHtml = `
//           <div class="popup-container">
//             <p class="popup-item"><strong>Site ID:</strong> ${properties?.['site_id'] || '--'}</p>
//              <p class="popup-item"><strong>City:</strong> ${properties?.copy || '--'}</p>
//           </div>
//         `;

//           setSitePopupFlag(true);

//           new mapboxgl.Popup()
//             .setLngLat(coordinates)
//             .setHTML(popupHtml)
//             .addTo(map.current)
//             .on('close', () => {
//               setSitePopupFlag(false);
//             });

//           // Zoom in on the clicked point
//           map.current.flyTo({
//             center: coordinates,
//             zoom: 14 // Adjust zoom level as needed
//           });
//         });


//       }
//     } catch (error) {
//       console.error('Error adding WFS layer:', error);
//     }
//   };

//   const removeWmsLayer = () => {
//     if (map.current.getSource('linksLayers')) {
//       map.current.removeLayer('linksLayers');
//       map.current.removeSource('linksLayers');
//     }
//     if (map.current.getLayer('linksLayers-dashed')) {
//       map.current.removeLayer('linksLayers-dashed');
//       map.current.removeSource('linksLayers');
//     }

//   };

//   const removeWmsSitesLayer = () => {
//     if (map.current.getSource('sitesLayers')) {
//       //   map.current.removeLayer('sitesLayers');
//       //   map.current.removeSource('sitesLayers');
//     }
//   };
//   const removeThreeDLayer = (lng, lat) => {
//     if (map.current.getLayer(`3d-model-${lng}-${lat}`)) {
//       map.current.removeLayer(`3d-model-${lng}-${lat}`);
//     }
//   };

//   const removeRegionMarkers = () => {
//     regionMarkers.current.forEach(marker => marker.remove());
//     regionMarkers.current = [];
//   };
//   const removeAlarmsMarker = () => {
//     alarmMarker.current.forEach(marker => marker.remove());
//     alarmMarker.current = [];
//   };

//   //   const showRegionMarkers = () => {
//   //     // Clear existing region markers
//   //     regionMarkers.current.forEach(marker => marker.remove());
//   //     regionMarkers.current = [];

//   //     // Loop through regions and add markers
//   //     regions.forEach(region => {
//   //       const alarmsCount = data.filter(alarm => alarm.region === region.regionName).length;

//   //       const regionMarker = new mapboxgl.Marker({
//   //         element: createRegionMarkerElement('N1', alarmsCount)
//   //       })
//   //         .setLngLat(region.center)
//   //         .addTo(map.current);

//   //       regionMarkers.current.push(regionMarker);

//   //       regionMarker.getElement().addEventListener('click', () => {
//   //         setLng(region.center[0]);
//   //         setLat(region.center[1]);

//   //         map.current.flyTo({
//   //           center: region.center,
//   //           zoom: 8,
//   //           essential: true,
//   //           duration: 6000,
//   //         });

//   //         addCityMarkers(region.center);
//   //       });
//   //     });
//   //   };

//   const handleChange = (value) => {
//     setAlramType(value);
//   };
//   function formatDuration(value) {
//     const hours = Math.floor(value / 3600); // Calculate hours
//     const minutes = Math.floor((value % 3600) / 60); // Calculate remaining minutes
//     return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
//   }
//   const handleMouseEnter = () => {
//     setPlayerHover(true);
//   };

//   const handleMouseLeave = () => {
//     setPlayerHover(false);
//   };
// const PlayVideo = (value) => {
//     if (value < 0) return;

//     setPosition(value);
//     const hours = Math.floor(value / 3600);
//     const minutes = Math.floor((value % 3600) / 60);
//     const hh = String(hours).padStart(2, '0');
//     const mm = String(minutes).padStart(2, '0');

//     const isCurrentDate = selectedDate.isSame(dayjs(), 'day');
//     const now = dayjs();
//     const twentyFourHoursAgoTime = isCurrentDate 
//       ? now.subtract(24, 'hour').add(value, 'seconds') 
//       : now.add(value, 'seconds');

//     setVideoPlayTime(`${twentyFourHoursAgoTime.format('YYYY-MM-DD')} ${twentyFourHoursAgoTime.format('HH:mm:ss')}`);
//   };

//   const PlayPause = () => {
//     if (paused) {
//       // Resume playing
//       setPaused(false);
//       const interval = setInterval(() => {
//         setPosition(prevPosition => {
//           const newPosition = prevPosition + 900;
//           if (newPosition >= duration) {
//             clearInterval(interval);
//             setPaused(true);
//             return duration;
//           }
//           PlayVideo(newPosition);
//           return newPosition;
//         });
//       }, 5000);
//       setIntervalID(interval);
//     } else {
//       // Pause playing
//       clearInterval(intervalID);
//       setPaused(true);
//     }
//   };
//   useEffect(() => {
//     const isCurrentDate = selectedDate.isSame(dayjs(), 'day');
//     if (isCurrentDate) {
//       const now = dayjs();
//       const twentyFourHoursAgo = now.subtract(24, 'hour');
//       setVideoPlayTime(`${twentyFourHoursAgo.format('YYYY-MM-DD')} ${twentyFourHoursAgo.format('HH:mm:ss')}`);
//     } else {
//       setVideoPlayTime(`${selectedDate.format('YYYY-MM-DD')} 00:00:00`);
//     }
//     setPosition(0);
//   }, [selectedDate]);




//   const renderHeader = useMemo(() => (
//     <div style={{ position: 'absolute', zIndex: 1, marginLeft: "32px", marginRight: "32px", marginTop: "20px", width: '-webkit-fill-available' }}>
//       <Box sx={{
//         display: 'flex',
//         justifyContent: 'space-between',
//         width: '100%',
//         height: '44px',
//       }}>
//         <div className="flex">
//           <a id="switch_on" className={`toggleStyle ${alramType === 'live' ? 'selected' : 'not_selected'}`} onClick={() => handleChange('live')}>
//             Live Alarms
//           </a>
//           <a id="switch_off" className={`toggleStyle ${alramType === 'history' ? 'selected' : 'not_selected'}`} onClick={() => handleChange('history')}>
//             History Alarms
//           </a>
//         </div>
//         <div style={{
//           display: 'flex',
//           alignItems: 'center',
//           gap: '12px',
//         }}>
//           <div style={{ background: "#F8E8E9" }}>
//             {
//               alramType === 'history' && (
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <DatePicker
//                     // label="Date Range"
//                     name="birthDate"
//                     value={selectedDate}
//                     data-testid='mpbn-map-component-2'
//                     onChange={handleDateChange}
//                     sx={{ height: 44, '& .MuiInputBase-root': { height: 44 } }}
//                     renderInput={(params) => (
//                       <TextField
//                         {...params}
//                         fullWidth
//                         size="small"
//                       />
//                     )}

//                   />
//                 </LocalizationProvider>
//               )
//             }
//           </div>
//           <div className="flex-table">
//             <a className={state.right ? "table-icons" : "table-icon"} onClick={toggleDrawer('right', true)}>
//               <Tooltip title="Filter">
//                 <TuneIcon />
//               </Tooltip>
//             </a>
//           </div>
//           <div className="flex-table">
//             <a className={viewType === "graph" ? "table-icons" : "table-icon-single"} onClick={() => setViewType('graph')}>
//               <Tooltip title="Map">
//                 <MapIcon />
//               </Tooltip>
//             </a>
//             <a className={viewType === "table" ? "table-icons" : "table-icon-single"} onClick={() => setViewType('table')}>
//               <Tooltip title="Table">
//                 <TableChartIcon />
//               </Tooltip>
//             </a>
//           </div>
//         </div>
//       </Box>
//     </div>
//   ), [alramType, handleChange, setViewType]);

//   const renderFooter = useMemo(() => (
//     <div style={{ position: 'absolute', marginLeft: '32px', marginBottom: '20px', zIndex: 1, width: '-webkit-fill-available', bottom: 0, left: 0 }}>
//       {alramType === 'history' && (
//         <Widget onMouseEnter={() => setPlayerHover(true)} onMouseLeave={() => setPlayerHover(false)} sx={{ display: 'flex', alignItems: 'center', gap: '5px', width: playerHover ? 343 : 100 }}>
//           {!playerHover ? (
//             <>
//               <IconButton aria-label={paused ? 'play' : 'pause'} onClick={() => PlayPause(paused)} sx={{ padding: '4px', border: '2px solid #FFF', mr: 2 }}>
//                 {paused ? (
//                   <PlayArrowRounded sx={{ fontSize: '1.5rem', color: '#BD1D23' }} htmlColor={mainIconColor} />
//                 ) : (
//                   <PauseRounded sx={{ fontSize: '1.5rem', color: '#FDCA1C' }} htmlColor={mainIconColor} />
//                 )}
//               </IconButton>
//               <Box sx={{ width: 8, height: 8, backgroundColor: '#BD1D23', borderRadius: '8px' }} />
//               <TinyText>{formatDuration(position)}</TinyText>
//             </>
//           ) : (
//             <Box sx={{ width: '100%' }}>
//               <Slider
//                 aria-label="time-indicator"
//                 size="small"
//                 value={position}
//                 min={0}
//                 step={900}
//                 max={duration}
//                 onChange={(_, value) => PlayVideo(value)}
//                 sx={{ color: '#BD1D23', height: 6.5, '& .MuiSlider-thumb': { display: 'none' }, '& .MuiSlider-rail': { opacity: 0.28 } }}
//               />
//               <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: -2 }}>
//                 <TinyText>{formatDuration(position)}</TinyText>
//                 <TinyText>-{formatDuration(duration - position)}</TinyText>
//               </Box>
//               <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: -1 }}>
//                 <IconButton aria-label="previous song" onClick={() => PlayVideo(position - 900)}>
//                   <FastRewindRounded fontSize="large" htmlColor={mainIconColor} sx={{ fontSize: '1.5rem', color: '#FFFFFF' }} />
//                 </IconButton>
//                 <IconButton aria-label={paused ? 'play' : 'pause'} onClick={() => PlayPause(paused)} sx={{ padding: '4px', border: '2px solid #FFF' }}>
//                   {paused ? (
//                     <PlayArrowRounded sx={{ fontSize: '1.5rem', color: '#BD1D23' }} htmlColor={mainIconColor} />
//                   ) : (
//                     <PauseRounded sx={{ fontSize: '1.5rem', color: '#FDCA1C' }} htmlColor={mainIconColor} />
//                   )}
//                 </IconButton>
//                 <IconButton aria-label="next song" onClick={() => PlayVideo(position + 900)}>
//                   <FastForwardRounded fontSize="large" htmlColor={mainIconColor} sx={{ fontSize: '1.5rem', color: '#FFFFFF' }} />
//                 </IconButton>
//               </Box>
//             </Box>
//           )}
//         </Widget>
//       )}
//     </div>
//   ), [alramType, playerHover, paused, position, mainIconColor, duration, PlayPause, PlayVideo, formatDuration]);

//   return (
//     <div style={{ position: 'relative' }} >
//       {isLoading ? (
//         // Render loading state
//         <Box >
//           <Skeleton variant="rectangular" sx={{ height: 'calc(100vh - 72px)' }} />
//         </Box>
//       ) : (
//         // Render map and header
//         <>
//           {renderHeader}
//           <div ref={mapContainer} style={{ height: 'calc(100vh - 72px)' }}>
//             <div className="mapboxgl-ctrl-logo" style={{ display: 'none' }}></div>
//           </div>
//           {renderFooter}
//         </>
//       )}
//     </div>
//   );
// }


























import React, { useRef, useEffect, useState, useMemo } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import northPolygonData from './Geojsons/northPolygonData.geojson';
import centralAPolygonData from './Geojsons/centralAPolygonData.geojson';
import centralBPolygonData from './Geojsons/centralBPolygonData.geojson';
import southPolygonData from './Geojsons/southPolygonData.geojson';
import test_cities from './Geojsons/test_cities.json';
import all_cities from './Geojsons/city_data.json';
import { getImageUrl } from '../GetImage/GetImage';
import { styled } from '@mui/material/styles';
import { Box, Skeleton, Typography, TextField } from '@mui/material';
import Slider from '@mui/material/Slider';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import FastForwardRounded from '@mui/icons-material/FastForwardRounded';
import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
import IconButton from '@mui/material/IconButton';
import TableChartIcon from '@mui/icons-material/TableChart';
import MapIcon from '@mui/icons-material/Map';
import pakistanPolygonData from './Geojsons/pakistanPolygonData.geojson';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import axios from 'axios';
import FilterListIcon from '@mui/icons-material/FilterList';
import TuneIcon from '@mui/icons-material/Tune';
import Tooltip from '@mui/material/Tooltip';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { Cities } from '../../enums/cities.enum';
import { findCityByAlias } from '../../common/functions/find_City_By_Alias.function';
import { findRegionByAlias } from '../../common/functions/find_Region_By_Alias.function';
import './mapStyles.css'
import apiClient from '../../common/functions/api_axios_instance.function';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';
import { RefreshButton } from '../../common/components/refresh_button.component';
import { GeoServerLinks } from '../../enums/geo_server_links.enum';
import { logout } from '../../common/functions/log_out.function';
// Extend GLTFLoader class
class ExtendedGLTFLoader extends GLTFLoader {
  constructor(manager) {
    super(manager);
    this.crossOrigin = 'anonymous'; // Set crossOrigin
  }
}

mapboxgl.accessToken = 'pk.eyJ1IjoidXNtYW4tZ2hhbmkiLCJhIjoiY2xoaGNqODllMGFkZTNtcHIxMXlqcGUwdCJ9.430W3OsMNhVeWxBNktOutA';

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  // opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
  color: '#FFF'
});

const Widget = styled('div')(({ theme }) => ({
  padding: 10,
  borderRadius: 16,

  maxWidth: '100%',
  // margin: 'auto',
  position: 'relative',
  zIndex: 1,
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : '#192126',
  // backdropFilter: 'blur(40px)',
}));


export default function MapMpbn({ setViewType, data, videoPlayTime, regionData, handleReset, loading, markerRef, selectedAlarm, link, site, viewType, city, region, ring, setVideoPlayTime, alramType, setAlramType, open, toggleDrawer, applyFilters, state }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const today = dayjs();
  const [shouldZoomToMarker, setShouldZoomToMarker] = useState(false);
  const stepRef = useRef(0);
  const [lng, setLng] = useState(69.3451);
  const [lat, setLat] = useState(30.3753);
  const [zoom, setZoom] = useState(5);
  const [sitePopupFlag, setSitePopupFlag] = useState(false);

  const [linkPopupFlag, setLinkPopupFlag] = useState(false);
  const cityMarkers = useRef([]);
  const regionMarkers = useRef([]);
  const alarmMarker = useRef([]);
  const navigate = useNavigate()
  const duration = 86400; // seconds
  const [position, setPosition] = useState(0);
  const [paused, setPaused] = useState(true);
  const [intervalID, setIntervalID] = useState();
  const [playerHover, setPlayerHover] = useState(false);
  const mainIconColor = '#000';
  const [isLoading, setIsLoading] = useState(true);

  const [regions, setRegions] = useState(
    [
      { id: 'north-polygon', regionName: 'North', alarmsCount: 0, color: '#35527e', center: [72.94996378341335, 34.974994348955335] },
      { id: 'centralA-polygon', regionName: 'Center A', alarmsCount: 0, color: '#893e2a', center: [73.40944846601698, 30.590024194213726] },
      { id: 'centralB-polygon', regionName: 'Center B', alarmsCount: 0, color: '#66773c', center: [71.16216343821162, 30.486783539931153] },
      { id: 'south-polygon', regionName: 'South', alarmsCount: 0, color: '#906b23', center: [66.5, 27.486783539931153] },
      { id: 'pakistan', regionName: 'Pakistan', alarmsCount: 0, data: pakistanPolygonData, color: '#66773c', center: [72.94996378341335, 34.974994348955335] },
    ]
  )
  const [cities, setCities] = useState(test_cities);

  const defaultDate = dayjs();
  const [selectedDate, setSelectedDate] = useState(defaultDate);


  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  useEffect(() => {
    const isCurrentDate = selectedDate.isSame(dayjs(), 'day');
    if (isCurrentDate) {
      const now = dayjs();
      const twentyFourHoursAgo = now.subtract(24, 'hour');
      setVideoPlayTime(`${twentyFourHoursAgo.format('YYYY-MM-DD')} ${twentyFourHoursAgo.format('HH:mm:ss')}`);
    } else {
      setVideoPlayTime(`${selectedDate.format('YYYY-MM-DD')} 00:00:00`);
    }
    setPosition(0);
  }, [selectedDate]);

  useEffect(() => {
    if (data) {
      setIsLoading(true);

      const updatedDataArr = regions.map(region => {
        const alarmsCount = data?.filter(alarm => alarm.region === region.regionName).length;
        return { ...region, alarmsCount };
      });
      setRegions(updatedDataArr);

      const updatedCitiesDataArr = cities.map(city => {
        const alarmsCount = data?.filter(alarm => alarm?.city?.toUpperCase() === city?.name?.toUpperCase()).length;
        return { ...city, alarmsCount };
      });
      setCities(updatedCitiesDataArr);
      if (map.current) {
        if (zoom < 8) {
          showRegionMarkers();
        }

      }

      setIsLoading(false);
    }
  }, [data, map.current, zoom]);

  useEffect(() => {
    if (selectedAlarm?.lat && selectedAlarm?.long && selectedAlarm?.xmin && selectedAlarm?.ymin && selectedAlarm?.xmax && selectedAlarm?.ymax) {
      setLng(selectedAlarm?.lat);
      setLat(selectedAlarm?.long);
      if (map.current) {

        addAlarmMarker([selectedAlarm?.lat, selectedAlarm?.long])
        map.current.flyTo({
          center: [selectedAlarm?.lat, selectedAlarm?.long],
          zoom: 5,
          essential: true,
          duration: 6000,
        });
        map.current.fitBounds([
          [selectedAlarm?.xmin - 0.0003, selectedAlarm?.ymin - 0.0003],
          [selectedAlarm?.xmax + 0.0003, selectedAlarm?.ymax + 0.0003]
        ]);
      }

    }
  }, [selectedAlarm])

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response, foundObj, lng, lat, zoomTo;
        const beforeUnderscoreLink = link.split('_')[0];
        if (ring || link || site) {
          response = await apiClient.get(`/mpbn/ringCenter`, {
            params: { city, linkName: link, site: site },
          });

          if (response?.data?.[0]) {
            foundObj = response.data[0];
            lng = foundObj?.[0];
            lat = foundObj?.[1];
            zoomTo = 7; // Higher zoom level for site

            // Ensure zoom level is appropriate
            zoomTo = Math.min(Math.max(zoomTo, 7), 9); // Adjust as needed

            setLng(lng);
            setLat(lat);
            { console.log('MarkerRefMpbn', markerRef) }
            // Remove the previous marker if it exists
            if (markerRef.current) {
              markerRef.current.remove();
            }

            // Add a new marker to the map
            markerRef.current = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map.current);

            // Use easeTo for faster zoom
            map.current.flyTo({
              center: [lng, lat],
              zoom: zoomTo,
              duration: 2000, // Adjust duration for smoother transition
            });

          } else {
            foundObj = findCityByAlias(all_cities, city);
            lng = foundObj?.lng;
            lat = foundObj?.lat;
            zoomTo = 7; // Default zoom level for cities
          }
        } else if (city) {
          foundObj = findCityByAlias(all_cities, city);
          lng = foundObj?.lng;
          lat = foundObj?.lat;
          zoomTo = 7; // Default zoom level for cities
        } else if (region) {
          foundObj = findRegionByAlias(regions, region);
          lng = foundObj?.center[0];
          lat = foundObj?.center[1];
          zoomTo = 7; // Default zoom level for regions
        } else {
          lng = 69.3451;
          lat = 30.3753;
          zoomTo = 5; // Default zoom level for the whole map
        }

        if (lng !== undefined && lat !== undefined) {
          setLng(lng);
          setLat(lat);

          // Use easeTo for faster zoom
          map.current.flyTo({
            center: [lng, lat],
            zoom: zoomTo,
            duration: 2000, // Adjust duration for smoother transition
          });

        } else {
          console.error('No data found');
        }
      } catch (error) {
        console.error('Error fetching data:', error);

        const fallbackCity = findCityByAlias(all_cities, city);
        if (fallbackCity) {
          const lng = fallbackCity?.lng;
          const lat = fallbackCity?.lat;
          const zoomTo = 7; // Default zoom level for fallback

          setLng(lng);
          setLat(lat);

          // Use easeTo for faster zoom
          map.current.flyTo({
            center: [lng, lat],
            zoom: zoomTo,
            duration: 2000, // Adjust duration for smoother transition
          });
        }
      }
    };
    if (map.current) {
      fetchData();
    }
  }, [city, site, link, map, all_cities]);

  useEffect(() => {
    if (isLoading === false) {
      if (!map.current) {
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/standard',//'mapbox://styles/mapbox/streets-v12?optimize=true',
          center: [lng, lat],
          zoom: zoom,
          attributionControl: false,
          pitch: 0
        });
        map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
        map.current.on('load', () => {
          regions.forEach(region => {
            map.current.addSource(region.id, {
              type: 'geojson',
              data: region.data
            });
            map.current.addLayer({
              id: region.id,
              // type: 'fill',
              type: 'line',
              source: region.id,
              paint: {
                'line-color': region.color,
                'line-width': 3,
                'line-opacity': 0.4,
              },
            });
            map.current.addLayer({
              type: 'line',
              source: region.id,
              id: `${region.id}-dashed`,
              paint: {
                'line-color': region.color,
                'line-width': 3,
                'line-dasharray': [0, 4, 3],
              },
            });
            // const dashArraySequence = [
            //   [0, 4, 3],
            //   [0.5, 4, 2.5],
            //   [1, 4, 2],
            //   [1.5, 4, 1.5],
            //   [2, 4, 1],
            //   [2.5, 4, 0.5],
            //   [3, 4, 0],
            //   [0, 0.5, 3, 3.5],
            //   [0, 1, 3, 3],
            //   [0, 1.5, 3, 2.5],
            //   [0, 2, 3, 2],
            //   [0, 2.5, 3, 1.5],
            //   [0, 3, 3, 1],
            //   [0, 3.5, 3, 0.5],
            // ];

            // const animateDashArray = (timestamp) => {
            //   const newStep = parseInt((timestamp / 120) % dashArraySequence.length);

            //   // if (newStep !== stepRef.current) {
            //   map.current.setPaintProperty(`${region.id}-dashed`, 'line-dasharray', dashArraySequence[stepRef.current]);
            //   stepRef.current = newStep;
            //   // }

            //   requestAnimationFrame(animateDashArray);
            // };

            // animateDashArray(0);

            if (!map.current?._markers) {
              showRegionMarkers();
            }


            // const regionMarker = new mapboxgl.Marker({
            //   element: createRegionMarkerElement('N1', region?.alarmsCount)
            // })
            //   .setLngLat(region.center)
            //   .addTo(map.current);
            // regionMarkers.current.push(regionMarker);

            // regionMarker.getElement().addEventListener('click', () => {
            //   setLng(region.center[0]);
            //   setLat(region.center[1]);
            //   // setZoom(8);

            //   map.current.flyTo({
            //     center: region.center,
            //     zoom: 8,
            //     essential: true,
            //     duration: 6000,
            //   });
            //   addCityMarkers(region.center);
            // });
          });
        });
        addWmsLayer()
        addWmsSitesLayer()
        map.current.on('moveend', () => {
          setLng(map.current.getCenter().lng.toFixed(4));
          setLat(map.current.getCenter().lat.toFixed(4));
          setZoom(map.current.getZoom().toFixed(2));

          const currentZoom = map.current.getZoom();
          if (currentZoom >= 7 && currentZoom <= 9.5) {
            addCityMarkers(map.current.getCenter());
          } else {
            removeCityMarkers();
            if (!map.current?._markers) {
              showRegionMarkers();
            }
          }
          if (currentZoom > 9) {
            cities?.forEach(item => {
              removeThreeDLayer(item.lng, item.lat);
            })
          }
          // Check if the WMS layer needs to be removed
          if (currentZoom >= 12) {
            // addWmsLayer()
            // addWmsSitesLayer()
          } else {
            // removeWmsLayer();
            // removeWmsSitesLayer();
          }

          if (currentZoom <= 13) {
            if (alarmMarker.current.length > 0) {
              removeAlarmsMarker()
            }
          }
        });
        //s const navControlContainer = mapContainer.current.querySelector('.mapboxgl-ctrl-bottom-right');
        // if (navControlContainer) {

        //   if (state.right) {
        //     navControlContainer.classList.add('nav-control-custom');
        //   } else {
        //     navControlContainer.classList.remove('nav-control-custom');
        //   }
        // }
      } else {
        if (zoom >= 8) {
          addCityMarkers(map.current.getCenter());
        } else {
          removeCityMarkers();
          if (!map.current?._markers) {
            showRegionMarkers();
          }
        }

        if (zoom > 11) {
          cities?.forEach(item => {
            removeThreeDLayer(item.lng, item.lat);
          })
        }
        // addWmsLayer()
        // addWmsSitesLayer()
        // Check if the WMS layer needs to be removed
        // if (zoom >= 12) {
        //   addWmsLayer()
        //   addWmsSitesLayer()
        // } else {
        //   removeWmsLayer();
        //   removeWmsSitesLayer();
        // }
        // If the map already exists, update its center and zoom
        map.current.setCenter([lng, lat]);
        map.current.setZoom(zoom);

      }
    }
  }, [isLoading]);

  const createRegionMarkerElement = (cityName, number) => {
    const markerElement = document.createElement('div');
    markerElement.className = number > 0 ? 'region-marker' : ''; //region-marker-green

    // Add circular cutout for the image
    const imageCutout = document.createElement('div');
    imageCutout.className = number > 0 ? 'image-cutout' : '';
    markerElement.appendChild(imageCutout);

    // Add pin tip
    const pinTip = document.createElement('div');
    pinTip.className = 'pin-tip';
    markerElement.appendChild(pinTip);

    // Add city name
    // const cityNameElement = document.createElement('div');
    // cityNameElement.className = 'city-name';
    // cityNameElement.textContent = cityName;
    // markerElement.appendChild(cityNameElement);

    // Add number in the center
    if (number > 0) {
      const numberElement = document.createElement('div');
      numberElement.className = number > 0 ? 'number' : '';
      numberElement.textContent = number;
      markerElement.appendChild(numberElement);
    }

    return markerElement;
  };

  const createCityMarkerElement = (iconUrl, cityName, number) => {
    const markerElement = document.createElement('div');
    markerElement.className = 'custom-marker';

    // Add number on top
    const numberElement = document.createElement('div');
    numberElement.className = number > 0 ? 'number' : '';
    numberElement.textContent = number;
    markerElement.appendChild(numberElement);

    // Add plus sign on top of the number
    // const plusSign = document.createElement('div');
    // plusSign.className = number > 0 ? 'plus-sign' : '';
    // plusSign.textContent = '+';
    // numberElement.appendChild(plusSign);

    // Add circular cutout for the image
    const imageCutout = document.createElement('div');
    imageCutout.className = 'image-cutout';
    markerElement.appendChild(imageCutout);

    // Set background image for the cutout
    const image = document.createElement('img');
    image.src = iconUrl;
    imageCutout.appendChild(image);

    // Add pin tip
    const pinTip = document.createElement('div');
    pinTip.className = 'pin-tip';
    markerElement.appendChild(pinTip);

    // Add city name
    const cityNameElement = document.createElement('div');
    cityNameElement.className = 'city-name';
    cityNameElement.textContent = cityName;
    markerElement.appendChild(cityNameElement);

    return markerElement;
  };
  const createCityThreeDMarker = (lng, lat, alarmsCount, name) => {
    if (!map.current.getLayer(`3d-model-${lng}-${lat}`)) {

      const modelOrigin = [lng, lat];
      const modelAltitude = 6000;
      const modelRotate = [Math.PI / 2, 0, 0];
      const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);

      const scaleFactor = 7000; // Adjust this factor to increase the model size
      const modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * scaleFactor,
      };
      const startTime = Date.now();
      const customLayer = {
        id: `3d-model-${lng}-${lat}`,
        type: 'custom',
        renderingMode: '3d',
        onAdd: function (map, gl) {
          this.camera = new THREE.Camera();
          this.scene = new THREE.Scene();

          // Add ambient light
          const ambientLight = new THREE.AmbientLight(0x404040, 1); // soft white light with full intensity
          this.scene.add(ambientLight);

          // Add hemisphere light for even lighting
          const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x444444, 0.6);
          hemisphereLight.position.set(0, 200, 0);
          this.scene.add(hemisphereLight);

          // Add multiple directional lights
          const directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.8);
          directionalLight1.position.set(0, -70, 100).normalize();
          this.scene.add(directionalLight1);

          const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.8);
          directionalLight2.position.set(0, 70, 100).normalize();
          this.scene.add(directionalLight2);

          const directionalLight3 = new THREE.DirectionalLight(0xffffff, 0.8);
          directionalLight3.position.set(70, 0, 100).normalize();
          this.scene.add(directionalLight3);

          const directionalLight4 = new THREE.DirectionalLight(0xffffff, 0.8);
          directionalLight4.position.set(-70, 0, 100).normalize();
          this.scene.add(directionalLight4);

          const directionalLight5 = new THREE.DirectionalLight(0xffffff, 0.8);
          directionalLight5.position.set(100, 0, 100).normalize();
          this.scene.add(directionalLight5);

          // Add a top light
          const topLight = new THREE.DirectionalLight(0xffffff, 1);
          topLight.position.set(0, 200, 100).normalize(); // Positioned directly above the model
          this.scene.add(topLight);

          // Add point light
          const pointLight = new THREE.PointLight(0xffffff, 1, 100);
          pointLight.position.set(50, 50, 50);
          this.scene.add(pointLight);

          // Add spotlight
          const spotLight = new THREE.SpotLight(0xffffff);
          spotLight.position.set(15, 40, 35);
          spotLight.castShadow = true;
          this.scene.add(spotLight);

          const loader = new GLTFLoader();
          loader.load(
            `/models/${name}.gltf`,
            (gltf) => {
              const model = gltf.scene;

              // Preserve original materials
              model.traverse((node) => {
                if (node.isMesh) {
                  node.material = node.material.clone(); // Clone the existing material to ensure lighting compatibility
                  node.material.needsUpdate = true;
                }
              });

              this.scene.add(model);

              // Load the font and create the text
              const fontLoader = new FontLoader();
              if (alarmsCount > 0) {
                // fontLoader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', (font) => {
                //   const textGeometry = new TextGeometry(`${alarmsCount}`, {
                //     font: font,
                //     size: 1,
                //     height: 0.2,
                //   });

                //   const textMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
                //   const textMesh = new THREE.Mesh(textGeometry, textMaterial);

                //   // Adjust the position of the text to place it on top of the model
                //   textMesh.position.set(0, 7, 0); // Adjust these values as necessary
                //   this.scene.add(textMesh);
                // });
              }
            },
            undefined,
            (error) => {
              console.error('An error happened', error);
            }
          );

          this.map = map;

          this.renderer = new THREE.WebGLRenderer({
            canvas: map.getCanvas(),
            context: gl,
            antialias: true,
          });

          this.renderer.autoClear = false;

          // Initialize raycaster and mouse vector
          this.raycaster = new THREE.Raycaster();
          this.mouse = new THREE.Vector2();

          // Add click event listener
          map.getCanvas().addEventListener('click', (event) => {
            const rect = map.getCanvas().getBoundingClientRect();
            this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

            this.raycaster.setFromCamera(this.mouse, this.camera);

            const intersects = this.raycaster.intersectObjects(this.scene.children, true);
            if (intersects.length > 0) {
              const clickedPoint = intersects[0].point;
              const clickedLngLat = map.unproject([event.clientX, event.clientY]);
              map.flyTo({
                center: [clickedLngLat.lng, clickedLngLat.lat],
                zoom: 10,
                essential: true,
                duration: 2000,
              });
              // addWmsLayer();
              // addWmsSitesLayer()
            }
          });
        },
        render: function (gl, matrix) {
          const rotationSpeed = 0.001;
          const currentTime = Date.now();
          const rotationY = rotationSpeed * (currentTime - startTime);
          const tiltX = Math.PI / 6; // Tilt angle on the X-axis (adjust this value)

          // Recalculate the modelTransform
          const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);
          const modelTransform = {
            translateX: modelAsMercatorCoordinate.x,
            translateY: modelAsMercatorCoordinate.y,
            translateZ: modelAsMercatorCoordinate.z,
            rotateX: modelRotate[0],
            rotateY: modelRotate[1] + rotationY, // Add the rotationY to the model's rotation
            rotateZ: modelRotate[2],
            scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * scaleFactor,
          };

          const m = new THREE.Matrix4().fromArray(matrix);
          const l = new THREE.Matrix4()
            .makeTranslation(
              modelTransform.translateX,
              modelTransform.translateY,
              modelTransform.translateZ
            )
            .scale(
              new THREE.Vector3(
                modelTransform.scale,
                -modelTransform.scale,
                modelTransform.scale
              )
            )
            .multiply(new THREE.Matrix4().makeRotationX(tiltX))
            .multiply(new THREE.Matrix4().makeRotationY(rotationY));

          this.camera.projectionMatrix = m.multiply(l);
          this.renderer.resetState();
          this.renderer.render(this.scene, this.camera);
          this.map.triggerRepaint();
        },
      };
      map.current.addLayer(customLayer);
    }
  };

  const addCityMarkers = (center) => {
    if (!map.current.getSource('cities')) {
      removeRegionMarkers(); // Remove region markers
      cities?.forEach(item => {
        if (item?.name === Cities.lahore || item?.name === Cities.faisalabad || item?.name === Cities.karachi || item?.name === Cities.islamabad) {
          createCityThreeDMarker(item.lng, item.lat, item?.alarmsCount, item?.name);
        }
        else {
          const cityMarker = new mapboxgl.Marker({
            element: createCityMarkerElement(getImageUrl(item?.name), item?.name, item?.alarmsCount)
          })
            .setLngLat([item.lng, item.lat])
            .addTo(map.current);
          cityMarkers.current.push(cityMarker);
          cityMarker.getElement().addEventListener('click', () => {
            setLng(item.lng);
            setLat(item.lat);
            // setZoom(12);
            map.current.flyTo({
              center: [item.lng, item.lat],
              zoom: 12,
              essential: true,
              duration: 6000,
            });
            // addWmsLayer();
            // addWmsSitesLayer()
          });
        }
      });
    }
  };
  const addAlarmMarker = (center) => {
    if (alarmMarker.current.length > 0) {
      removeAlarmsMarker()
    }
    const alarmMarkers = new mapboxgl.Marker()
      .setLngLat(center)
      .addTo(map.current);
    alarmMarker.current.push(alarmMarkers);
  };

  const removeCityMarkers = () => {
    cityMarkers.current.forEach(marker => marker.remove());
    cityMarkers.current = [];
    // showRegionMarkers(); // Show region markers
  };
  // const addWmsLayer = async () => {
  //   try {
  //     if (map.current.getSource('linksLayers')) {
  //       removeWmsLayer()
  //     }
  //     if (!map.current.getSource('linksLayers')) {
  //       const response = await fetch(GeoServerLinks.mpbnLinkLayer);
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch data');
  //       }

  //       const data = await response.json();
  //       map.current.addSource('linksLayers', {
  //         type: 'geojson',
  //         data: data
  //       });

  //       map.current.addLayer({
  //         id: 'linksLayers',
  //         type: 'line',
  //         source: 'linksLayers',
  //         // layout: { 'line-join': 'round', 'line-cap': 'round' },
  //         paint: {
  //           // 'line-color': ['get', 'color'],
  //           'line-color': [
  //             'coalesce',
  //             ['get', 'color'],
  //             '#66773c'
  //           ],
  //           'line-opacity': 0.4,
  //           'line-width': 3
  //         }
  //       });
  //       map.current.addLayer({
  //         type: 'line',
  //         source: 'linksLayers',
  //         id: 'linksLayers-dashed',
  //         paint: {
  //           // 'line-color': ['get', 'color'],
  //           'line-color': [
  //             'coalesce',
  //             ['get', 'color'],
  //             '#66773c' // default to green if 'color' is null or undefined
  //           ],
  //           'line-width': 3,
  //           'line-dasharray': [0, 4, 3],
  //         },
  //       });
  //       const dashArraySequence = [
  //         [0, 4, 3],
  //         [0.5, 4, 2.5],
  //         [1, 4, 2],
  //         [1.5, 4, 1.5],
  //         [2, 4, 1],
  //         [2.5, 4, 0.5],
  //         [3, 4, 0],
  //         [0, 0.5, 3, 3.5],
  //         [0, 1, 3, 3],
  //         [0, 1.5, 3, 2.5],
  //         [0, 2, 3, 2],
  //         [0, 2.5, 3, 1.5],
  //         [0, 3, 3, 1],
  //         [0, 3.5, 3, 0.5],
  //       ];

  //       const animateDashArray = (timestamp) => {
  //         const newStep = parseInt((timestamp / 120) % dashArraySequence.length);

  //         // if (newStep !== stepRef.current) {
  //         map.current.setPaintProperty('linksLayers-dashed', 'line-dasharray', dashArraySequence[stepRef.current]);
  //         stepRef.current = newStep;
  //         // }

  //         requestAnimationFrame(animateDashArray);
  //       };

  //       animateDashArray(0);

  //       // Add event listeners to change cursor style
  //       map.current.on('mouseenter', 'linksLayers', () => {
  //         map.current.getCanvasContainer().style.cursor = 'pointer';
  //       });

  //       map.current.on('mouseleave', 'linksLayers', () => {
  //         map.current.getCanvasContainer().style.cursor = '';
  //       });
  //       map.current.on('click', 'linksLayers', async (e) => {
  //         // e.stopPropagation(); // Prevent event propagation to avoid unnecessary map behaviors
  //         const features = map.current.queryRenderedFeatures(e.point, {
  //           layers: ['linksLayers']
  //         });

  //         if (!features.length) return;

  //         const feature = features[0];
  //         const properties = feature?.properties;
  //         const trunksData = await GetToolTipData(properties?.layer_name)
  //         console.log('trunksData', trunksData)
  //         const midPoint = feature.geometry.coordinates[Math.floor(feature.geometry.coordinates.length / 2)];
  //         {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
  //                             <path d="M17.6647 13.1271L10.6688 0.977643C10.4939 0.679986 10.2444 0.433184 9.94476 0.261698C9.64517 0.0902134 9.30597 0 8.96077 0C8.61557 0 8.27636 0.0902134 7.97677 0.261698C7.67718 0.433184 7.42761 0.679986 7.25279 0.977643L0.256856 13.1271C0.0886461 13.415 0 13.7425 0 14.0759C0 14.4094 0.0886461 14.7368 0.256856 15.0247C0.429438 15.3242 0.678587 15.5723 0.978737 15.7437C1.27889 15.9151 1.61923 16.0035 1.96484 15.9999H15.9567C16.302 16.0032 16.642 15.9146 16.9419 15.7433C17.2417 15.5719 17.4906 15.3239 17.6631 15.0247C17.8315 14.7369 17.9205 14.4096 17.9207 14.0761C17.921 13.7427 17.8326 13.4152 17.6647 13.1271ZM8.32078 6.39999C8.32078 6.23025 8.3882 6.06747 8.50822 5.94745C8.62825 5.82742 8.79103 5.76 8.96077 5.76C9.13051 5.76 9.29329 5.82742 9.41331 5.94745C9.53333 6.06747 9.60076 6.23025 9.60076 6.39999V9.59996C9.60076 9.7697 9.53333 9.93248 9.41331 10.0525C9.29329 10.1725 9.13051 10.24 8.96077 10.24C8.79103 10.24 8.62825 10.1725 8.50822 10.0525C8.3882 9.93248 8.32078 9.7697 8.32078 9.59996V6.39999ZM8.96077 13.4399C8.7709 13.4399 8.5853 13.3836 8.42743 13.2781C8.26956 13.1726 8.14651 13.0227 8.07385 12.8473C8.00119 12.6719 7.98218 12.4789 8.01922 12.2926C8.05627 12.1064 8.1477 11.9354 8.28195 11.8011C8.41621 11.6669 8.58726 11.5754 8.77348 11.5384C8.9597 11.5013 9.15273 11.5204 9.32814 11.593C9.50356 11.6657 9.65349 11.7887 9.75897 11.9466C9.86446 12.1045 9.92076 12.2901 9.92076 12.4799C9.92076 12.7345 9.81962 12.9787 9.63958 13.1587C9.45955 13.3388 9.21537 13.4399 8.96077 13.4399Z" fill="#BD1D23"/>
  //                          </svg> */}
  //         const trunksHtml = trunksData?.trunks.map(trunk => {
  //           const colorDescription = trunk.color || 'dot';

  //           return `<p class="popup-item" style="display: flex; align-items: center; margin-bottom: 5px;">
  //     <strong style="flex-shrink: 0;">${trunk?.link_name}:</strong> 
  //     <span style="display: inline-block; width: 10px; height: 10px; background-color: ${trunk?.color || 'green'}; border-radius: 50%; margin-left: 10px;"></span>
  //   </p>`;
  //         }).join('');

  //         const popupHtml = `
  //           <div class="tooltip-container" style="height: 200px;overflow-x: hidden;width:213px;margin-top:8% ;padding-top:5px"> 
  //             <div style="width: 95%; height: 4px; border-radius:10px; background-color: ${trunksData?.overAllColor || 'green'};"></div>
  //             <div class="title_container_tooltip"> 
  //               <button id="popup-close-btn" style="position: absolute; top: 4px; right: 10px; background: none; border: none; font-size: 18px; cursor: pointer;">&times;</button>

  //               <div class="title_text_container_tooltip">
  //                 <h3 class="tooltip-heading" style="font-size:12px;">${properties?.layer_name || '--'}</h3>
  //                 <p class="popup-item"><strong>Length (km):</strong> ${features[0]?._vectorTileFeature?._values?.length || '--'}</p>
  //                 <p class="popup-item"><strong>Trunk Id:</strong> ${trunksData?.trunk_id}</p>
  //                 <p class="popup-item"><strong>Total Links:</strong> ${trunksData?.totalLinks}</p>
  //                 <p class="popup-item"><strong>Total Broken:</strong> ${trunksData?.totalBroken}</p>

  //                 ${trunksHtml} <!-- Insert trunk details here -->
  //                  </div>               
  //             </div>
  //           </div>`;
  //         const popup = new mapboxgl.Popup({ closeButton: false })
  //           .setLngLat(midPoint)
  //           .setHTML(popupHtml)
  //           .addTo(map.current);

  //         // Add event listener to close the popup when the close button is clicked
  //         document
  //           .getElementById('popup-close-btn')
  //           .addEventListener('click', () => {
  //             popup.remove();
  //           });
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Error adding WFS layer:', error);
  //   }
  // };







  ////////////////////  Timer ////////////////////////

  let currentPopup = null;
  // const addWmsLayer = async () => {
  //   try {
  //     if (map.current.getSource('linksLayers')) {
  //       removeWmsLayer();
  //     }
  //     if (!map.current.getSource('linksLayers')) {
  //       const response = await fetch(GeoServerLinks.mpbnLinkLayer);
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch data');
  //       }

  //       const data = await response.json();
  //       map.current.addSource('linksLayers', {
  //         type: 'geojson',
  //         data: data
  //       });

  //       map.current.addLayer({
  //         id: 'linksLayers',
  //         type: 'line',
  //         source: 'linksLayers',
  //         paint: {
  //           'line-color': ['coalesce', ['get', 'color'], '#66773c'],
  //           'line-opacity': 0.4,
  //           'line-width': 3
  //         }
  //       });

  //       map.current.addLayer({
  //         type: 'line',
  //         source: 'linksLayers',
  //         id: 'linksLayers-dashed',
  //         paint: {
  //           'line-color': ['coalesce', ['get', 'color'], '#66773c'],
  //           'line-width': 3,
  //           'line-dasharray': [0, 4, 3],
  //         },
  //       });

  //       // Store the reference to the current popup
  //       let currentPopup = null;

  //       // Timer for popup display
  //       let popupTimer = null;
  //       const popupDisplayDelay = 1000; // 1 second

  //       const dashArraySequence = [
  //         [0, 4, 3],
  //         [0.5, 4, 2.5],
  //         [1, 4, 2],
  //         [1.5, 4, 1.5],
  //         [2, 4, 1],
  //         [2.5, 4, 0.5],
  //         [3, 4, 0],
  //         [0, 0.5, 3, 3.5],
  //         [0, 1, 3, 3],
  //         [0, 1.5, 3, 2.5],
  //         [0, 2, 3, 2],
  //         [0, 2.5, 3, 1.5],
  //         [0, 3, 3, 1],
  //         [0, 3.5, 3, 0.5],
  //       ];

  //       const animateDashArray = (timestamp) => {
  //         const newStep = parseInt((timestamp / 120) % dashArraySequence.length);
  //         map.current.setPaintProperty('linksLayers-dashed', 'line-dasharray', dashArraySequence[newStep]);
  //         requestAnimationFrame(animateDashArray);
  //       };

  //       animateDashArray(0);

  //       map.current.on('mouseenter', 'linksLayers', async (e) => {
  //         map.current.getCanvasContainer().style.cursor = 'pointer';

  //         // Clear the previous popup timer if it exists
  //         if (popupTimer) {
  //           clearTimeout(popupTimer);
  //         }

  //         // Set up timer to show popup after 1 second
  //         popupTimer = setTimeout(async () => {
  //           // Remove the previous popup if it exists
  //           if (currentPopup) {
  //             currentPopup.remove();
  //           }

  //           const features = map.current.queryRenderedFeatures(e.point, {
  //             layers: ['linksLayers']
  //           });

  //           if (!features.length) return;

  //           const feature = features[0];
  //           const properties = feature?.properties;
  //           const trunksData = await GetToolTipData(properties?.layer_name); // Adjust this based on your actual function
  //           const midPoint = feature.geometry.coordinates[Math.floor(feature.geometry.coordinates.length / 2)];

  //           const trunksHtml = trunksData?.trunks.map(trunk => {
  //             return `<p class="popup-item" style="display: flex; align-items: center; margin-bottom: 5px;">
  //               <strong style="flex-shrink: 0;">${trunk?.link_name}:</strong> 
  //               <span style="display: inline-block; width: 10px; height: 10px; background-color: ${trunk?.color || 'green'}; border-radius: 50%; margin-left: 10px;"></span>
  //             </p>`;
  //           }).join('');

  //           const popupHtml = `
  //             <div class="tooltip-container" style="height: 200px;overflow-x: hidden;width:213px;margin-top:8% ;padding-top:5px"> 
  //               <div style="width: 95%; height: 4px; border-radius:10px; background-color: ${trunksData?.overAllColor || 'green'};"></div>
  //               <div class="title_container_tooltip"> 
  //                 <button id="popup-close-btn" style="position: absolute; top: 4px; right: 10px; background: none; border: none; font-size: 18px; cursor: pointer;">&times;</button>
  //                 <div class="title_text_container_tooltip">
  //                   <h3 class="tooltip-heading" style="font-size:12px;">${properties?.layer_name || '--'}</h3>
  //                   <p class="popup-item"><strong>Length (km):</strong> ${features[0]?._vectorTileFeature?._values?.length || '--'}</p>
  //                   <p class="popup-item"><strong>Trunk Id:</strong> ${trunksData?.trunk_id}</p>
  //                   <p class="popup-item"><strong>Total Links:</strong> ${trunksData?.totalLinks}</p>
  //                   <p class="popup-item"><strong>Total Broken:</strong> ${trunksData?.totalBroken}</p>
  //                   ${trunksHtml}
  //                 </div>               
  //               </div>
  //             </div>`;

  //           currentPopup = new mapboxgl.Popup({ closeButton: false })
  //             .setLngLat(midPoint)
  //             .setHTML(popupHtml)
  //             .addTo(map.current);

  //           // Add event listener to close the popup when the close button is clicked
  //           document.getElementById('popup-close-btn')
  //             .addEventListener('click', () => {
  //               if (currentPopup) {
  //                 currentPopup.remove();
  //                 currentPopup = null; // Ensure popup reference is cleared
  //               }
  //             });
  //         }, popupDisplayDelay);
  //       });

  //       map.current.on('mouseleave', 'linksLayers', () => {
  //         map.current.getCanvasContainer().style.cursor = '';

  //         // Clear the popup timer when mouse leaves
  //         if (popupTimer) {
  //           clearTimeout(popupTimer);
  //         }
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Error adding WFS layer:', error);
  //   }
  // };


  const addWmsLayer = async () => {
    try {
      if (map.current.getSource('linksLayers')) {
        removeWmsLayer();
      }
      if (!map.current.getSource('linksLayers')) {
        const response = await fetch(GeoServerLinks.mpbnLinkLayer);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        map.current.addSource('linksLayers', {
          type: 'geojson',
          data: data
        });

        map.current.addLayer({
          id: 'linksLayers',
          type: 'line',
          source: 'linksLayers',
          paint: {
            'line-color': ['coalesce', ['get', 'color'], '#66773c'],
            'line-opacity': 0.4,
            'line-width': 3
          }
        });

        map.current.addLayer({
          type: 'line',
          source: 'linksLayers',
          id: 'linksLayers-dashed',
          paint: {
            'line-color': ['coalesce', ['get', 'color'], '#66773c'],
            'line-width': 3,
            'line-dasharray': [0, 4, 3],
          },
        });

        let currentPopup = null;
        let popupTimer = null;
        const popupDisplayDelay = 1000; // 1 second

        const dashArraySequence = [
          [0, 4, 3],
          [0.5, 4, 2.5],
          [1, 4, 2],
          [1.5, 4, 1.5],
          [2, 4, 1],
          [2.5, 4, 0.5],
          [3, 4, 0],
          [0, 0.5, 3, 3.5],
          [0, 1, 3, 3],
          [0, 1.5, 3, 2.5],
          [0, 2, 3, 2],
          [0, 2.5, 3, 1.5],
          [0, 3, 3, 1],
          [0, 3.5, 3, 0.5],
        ];

        const animateDashArray = (timestamp) => {
          const newStep = parseInt((timestamp / 120) % dashArraySequence.length);
          map.current.setPaintProperty('linksLayers-dashed', 'line-dasharray', dashArraySequence[newStep]);
          requestAnimationFrame(animateDashArray);
        };

        animateDashArray(0);

        // Helper function to remove existing popups
        const removeCurrentPopup = () => {
          if (currentPopup) {
            currentPopup.remove();
            currentPopup = null;
          }
        };

        map.current.on('mouseenter', 'linksLayers', async (e) => {
          map.current.getCanvasContainer().style.cursor = 'pointer';

          if (popupTimer) {
            clearTimeout(popupTimer);
          }

          popupTimer = setTimeout(async () => {
            removeCurrentPopup();

            const features = map.current.queryRenderedFeatures(e.point, {
              layers: ['linksLayers']
            });

            if (!features.length) return;

            const feature = features[0];
            const properties = feature?.properties;
            const trunksData = await GetToolTipData(properties?.layer_name); // Adjust this based on your actual function
            const midPoint = feature.geometry.coordinates[Math.floor(feature.geometry.coordinates.length / 2)];

            const trunksHtml = trunksData?.trunks.map(trunk => {
              return `<p class="popup-item" style="display: flex; align-items: center; margin-bottom: 5px;">
                <strong style="flex-shrink: 0;">${trunk?.link_name}:</strong> 
                <span style="display: inline-block; width: 10px; height: 10px; background-color: ${trunk?.color || 'green'}; border-radius: 50%; margin-left: 10px;"></span>
              </p>`;
            }).join('');

            const popupHtml = `
              <div class="tooltip-container" style="height: 200px;overflow-x: hidden;width:213px;margin-top:8% ;padding-top:5px"> 
                <div style="width: 95%; height: 4px; border-radius:10px; background-color: ${trunksData?.overAllColor || 'green'};"></div>
                <div class="title_container_tooltip"> 
                  <button id="popup-close-btn" style="position: absolute; top: 4px; right: 10px; background: none; border: none; font-size: 18px; cursor: pointer;">&times;</button>
                  <div class="title_text_container_tooltip">
                    <h3 class="tooltip-heading" style="font-size:12px;">${properties?.layer_name || '--'}</h3>
                    <p class="popup-item"><strong>Length (km):</strong> ${features[0]?._vectorTileFeature?._values?.length || '--'}</p>
                    <p class="popup-item"><strong>Trunk Id:</strong> ${trunksData?.trunk_id}</p>
                    <p class="popup-item"><strong>Total Links:</strong> ${trunksData?.totalLinks}</p>
                    <p class="popup-item"><strong>Total Broken:</strong> ${trunksData?.totalBroken}</p>
                    ${trunksHtml}
                  </div>               
                </div>
              </div>`;

            setLinkPopupFlag(true);

            currentPopup = new mapboxgl.Popup({ closeButton: false })
              .setLngLat(midPoint)
              .setHTML(popupHtml)
              .addTo(map.current)
              .on('close', () => {
                setLinkPopupFlag(false);
              });;

            // Ensure close button event listener is attached
            const closeButton = document.getElementById('popup-close-btn');
            if (closeButton) {
              closeButton.addEventListener('click', () => {
                removeCurrentPopup();
              });

            }
            currentPopup._id = `popup-${Date.now()}`;
          }, popupDisplayDelay);
        });

        map.current.on('mouseleave', 'linksLayers', () => {
          map.current.getCanvasContainer().style.cursor = '';

          if (popupTimer) {
            clearTimeout(popupTimer);
          }
        });
        const closeButton = document.getElementById('popup-close-btn');
        if (closeButton) {
          closeButton.addEventListener('click', () => {
            removeCurrentPopup();
          });

        }
      }
    } catch (error) {
      console.error('Error adding WFS layer:', error);
    }
  };








  const GetToolTipData = async (layerName) => {
    try {
      const responseData = await apiClient.get(`/mpbn/trunkData/`, {
        params: { linkName: layerName },
      });
      return responseData.data.response
    }
    catch (error) {
      if(error.response.status === 401) {logout()}
    }
  }
  const addWmsSitesLayer = async () => {
    try {
      if (!map.current.getSource('sitesLayers')) {
        const response = await fetch(GeoServerLinks.mpbnSiteLayer);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();

        map.current.addSource('sitesLayers', {
          type: 'geojson',
          data: data
        });

        map.current.addLayer({
          id: 'sitesLayers',
          type: 'circle',
          source: 'sitesLayers',
          paint: {
            'circle-radius': 4.8,
            'circle-color': '#02367b',
            'circle-stroke-color': '#02367b',
            'circle-stroke-width': 3.5,
            'circle-stroke-opacity': 0.5,
            // 'circle-opacity': 0.5
          }
        });
        // Add event listeners to change cursor style
        map.current.on('mouseenter', 'sitesLayers', () => {
          map.current.getCanvasContainer().style.cursor = 'pointer';
        });

        map.current.on('mouseleave', 'sitesLayers', () => {
          map.current.getCanvasContainer().style.cursor = '';
        });
        // Add onclick event listener
        //   map.current.on('click', 'sitesLayers', (e) => {

        //     const features = map.current.queryRenderedFeatures(e?.point, { layers: ['sitesLayers'] });

        //     if (!features?.length) {
        //       return;
        //     }

        //     // const properties = features[0].properties;
        //     const coordinates = e?.features[0]?.geometry?.coordinates?.slice();
        //     const properties = e?.features[0]?.properties;
        //     const popupHtml = `
        //     <div class="popup-container">
        //     <p class="popup-item"><strong>Site ID:</strong> ${properties?.['site id'] || '--'}</p>
        //     <p class="popup-item"><strong>Region:</strong> ${properties?.region || '--'}</p>
        //     <p class="popup-item"><strong>City:</strong> ${properties?.city || '--'}</p>
        //     </div>
        // `;
        //     {/* <h3 class="popup-heading">${properties?.bp || '--'}</h3>

        //         <p class="popup-item"><strong>Cutover Date:</strong> ${properties?.['cutover da'] || '--'}</p>
        //         <p class="popup-item"><strong>Fiber POP:</strong> ${properties?.['fiber pop'] || '--'}</p>

        //         <p class="popup-item"><strong>Ring Name:</strong> ${properties?.['ring name'] || '--'}</p>
        //         <p class="popup-item"><strong>Ring Unique:</strong> ${properties?.['ring uniqu'] || '--'}</p>

        //         <p class="popup-item"><strong>Status:</strong> ${properties?.status || '--'}</p> */}
        //     setSitePopupFlag(true);

        //     new mapboxgl.Popup()
        //       .setLngLat(coordinates)
        //       .setHTML(popupHtml)
        //       .addTo(map.current)
        //       .on('close', () => {
        //         setSitePopupFlag(false);
        //       });

        //   });
        //   map.current.flyTo({
        //     // center: coordinates,
        //     zoom: 14 // Adjust zoom level as needed
        //   });
        map.current.on('click', 'sitesLayers', (e) => {
          const features = map.current.queryRenderedFeatures(e?.point, { layers: ['sitesLayers'] });

          if (!features?.length) {
            return;
          }

          const coordinates = features[0]?.geometry?.coordinates.slice();
          const properties = features[0]?.properties;
          const popupHtml = `
          <div class="popup-container">
            <p class="popup-item"><strong>Site ID:</strong> ${properties?.['site_id'] || '--'}</p>
             <p class="popup-item"><strong>City:</strong> ${properties?.site_name || '--'}</p>
          </div>
        `;

          setSitePopupFlag(true);

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(popupHtml)
            .addTo(map.current)
            .on('close', () => {
              setSitePopupFlag(false);
            });

          // Zoom in on the clicked point
          // map.current.flyTo({
          //   // center: coordinates,
          //   // zoom: 14 // Adjust zoom level as needed
          // });
        });


      }
    } catch (error) {
      console.error('Error adding WFS layer:', error);
    }
  };

  const removeWmsLayer = () => {
    if (map.current.getSource('linksLayers')) {
      map.current.removeLayer('linksLayers');
      map.current.removeSource('linksLayers');
    }
    if (map.current.getLayer('linksLayers-dashed')) {
      map.current.removeLayer('linksLayers-dashed');
      map.current.removeSource('linksLayers');
    }

  };

  const removeWmsSitesLayer = () => {
    if (map.current.getSource('sitesLayers')) {
      //   map.current.removeLayer('sitesLayers');
      //   map.current.removeSource('sitesLayers');
    }
  };
  const removeThreeDLayer = (lng, lat) => {
    if (map.current.getLayer(`3d-model-${lng}-${lat}`)) {
      map.current.removeLayer(`3d-model-${lng}-${lat}`);
    }
  };

  const removeRegionMarkers = () => {
    regionMarkers.current.forEach(marker => marker.remove());
    regionMarkers.current = [];
  };
  const removeAlarmsMarker = () => {
    alarmMarker.current.forEach(marker => marker.remove());
    alarmMarker.current = [];
  };

  const showRegionMarkers = () => {
    // Clear existing region markers
    regionMarkers.current.forEach(marker => marker.remove());
    regionMarkers.current = [];

    // Loop through regions and add markers
    regions.forEach(region => {
      const alarmsCount = data?.filter(alarm => alarm.region === region.regionName).length;
      console.log('alarmsCOunt===', alarmsCount)
      console.log('alarmsCOunt=== data', data)

      const regionMarker = new mapboxgl.Marker({
        element: createRegionMarkerElement('N1', alarmsCount)
      })
        .setLngLat(region.center)
        .addTo(map.current);

      regionMarkers.current.push(regionMarker);

      regionMarker.getElement().addEventListener('click', () => {
        setLng(region.center[0]);
        setLat(region.center[1]);

        map.current.flyTo({
          center: region.center,
          zoom: 8,
          essential: true,
          duration: 6000,
        });

        addCityMarkers(region.center);
      });
    });
  };

  const handleChange = (value) => {
    setAlramType(value);
    handleReset();
    handleRefresh()
  };
  function formatDuration(value) {
    const hours = Math.floor(value / 3600); // Calculate hours
    const minutes = Math.floor((value % 3600) / 60); // Calculate remaining minutes
    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
  }
  const handleMouseEnter = () => {
    setPlayerHover(true);
  };

  const handleMouseLeave = () => {
    setPlayerHover(false);
  };

  const PlayVideo = (value) => {
    if (value < 0) return;

    setPosition(value);
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const hh = String(hours).padStart(2, '0');
    const mm = String(minutes).padStart(2, '0');

    const isCurrentDate = selectedDate.isSame(dayjs(), 'day');
    const now = dayjs();
    const twentyFourHoursAgoTime = isCurrentDate
      ? selectedDate.subtract(24, 'hour').add(value, 'seconds')
      : selectedDate.add(value, 'seconds');

    setVideoPlayTime(`${twentyFourHoursAgoTime.format('YYYY-MM-DD')} ${twentyFourHoursAgoTime.format('HH:mm:ss')}`);
  };

  const PlayPause = () => {
    if (paused) {
      // Resume playing
      setPaused(false);
      const interval = setInterval(() => {
        setPosition(prevPosition => {
          const newPosition = prevPosition + 900;
          if (newPosition >= duration) {
            clearInterval(interval);
            setPaused(true);
            return duration;
          }
          PlayVideo(newPosition);
          return newPosition;
        });
      }, 5000);
      setIntervalID(interval);
    } else {
      // Pause playing
      clearInterval(intervalID);
      setPaused(true);
    }
  };
  const handleRefresh = () => {
    if (region || city || ring || link || site) {
      handleReset()
    }
    map.current.flyTo({
      center: [69.3451, 30.3753],
      zoom: 5,
      bearing: 0, // Reset the map's heading to 0 (default)
    });
    // if (window.location.pathname === '/fibers') {
    //   // If you're already on the '/fibers' route, force a reload
    //   window.location.reload();
    // } else {
    //   // Otherwise, navigate to the '/fibers' route
    //   navigate('/fibers');
    // }
  };
  const renderHeader = useMemo(() => (
    <div style={{ position: 'absolute', zIndex: 1, marginLeft: "32px", marginRight: "32px", marginTop: "20px", width: '-webkit-fill-available' }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '44px',
      }}>
        <div className="flex">
          <a id="switch_on" className={`toggleStyle ${alramType === 'live' ? 'selected' : 'not_selected'}`} onClick={() => handleChange('live')}>
            Live Alarms
          </a>
          <a id="switch_off" className={`toggleStyle ${alramType === 'history' ? 'selected' : 'not_selected'}`} onClick={() => handleChange('history')}>
            History Alarms
          </a>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
        }}>
          <div style={{ background: "#F8E8E9" }}>
            {
              alramType === 'history' && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // label="Date Range"
                    name="birthDate"
                    value={selectedDate}
                    data-testid='map-component-1'
                    onChange={handleDateChange}
                    sx={{ height: 44, '& .MuiInputBase-root': { height: 44 } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                      />
                    )}
                    maxDate={today}
                  />
                </LocalizationProvider>
              )
            }
          </div>

          <div className="flex-table">
            <a className={state.right ? "table-icons" : "table-icon"} onClick={toggleDrawer('right', true)}>
              <Tooltip title="Filter">
                <TuneIcon />
              </Tooltip>
            </a>
          </div>
          <div className="flex-table">
            <a className={viewType === "graph" ? "table-icons" : "table-icon-single"} onClick={() => setViewType('graph')}>
              <Tooltip title="Map">
                <MapIcon />
              </Tooltip>
            </a>
            <a className={viewType === "table" ? "table-icons" : "table-icon-single"} onClick={() => setViewType('table')}>
              <Tooltip title="Table">
                <TableChartIcon />
              </Tooltip>
            </a>
          </div>
        </div>
      </Box>
    </div>
  ), [alramType, handleChange, setViewType]);

  // const renderFooter = useMemo(() => (
  //  <> 
  //  <div style={{ position: 'absolute', marginLeft: '32px', marginBottom: '20px', zIndex: 1, width: '-webkit-fill-available', bottom: 0, left: 0 }}>
  //     {alramType === 'history' && (
  //       <Widget onMouseEnter={() => setPlayerHover(true)} onMouseLeave={() => setPlayerHover(false)} sx={{ display: 'flex', alignItems: 'center', gap: '5px', width: playerHover ? 343 : 100 }}>
  //         {!playerHover ? (
  //           <>
  //             <IconButton aria-label={paused ? 'play' : 'pause'} onClick={() => PlayPause(paused)} sx={{ padding: '4px', border: '2px solid #FFF', mr: 2 }}>
  //               {paused ? (
  //                 <PlayArrowRounded sx={{ fontSize: '1.5rem', color: '#BD1D23' }} htmlColor={mainIconColor} />
  //               ) : (
  //                 <PauseRounded sx={{ fontSize: '1.5rem', color: '#FDCA1C' }} htmlColor={mainIconColor} />
  //               )}
  //             </IconButton>
  //             <Box sx={{ width: 8, height: 8, backgroundColor: '#BD1D23', borderRadius: '8px' }} />
  //             <TinyText>{formatDuration(position)}</TinyText>
  //           </>
  //         ) : (
  //           <Box sx={{ width: '100%' }}>
  //             <Slider
  //               aria-label="time-indicator"
  //               size="small"
  //               value={position}
  //               min={0}
  //               step={900}
  //               max={duration}
  //               onChange={(_, value) => PlayVideo(value)}
  //               sx={{ color: '#BD1D23', height: 6.5, '& .MuiSlider-thumb': { display: 'none' }, '& .MuiSlider-rail': { opacity: 0.28 } }}
  //             />
  //             <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: -2 }}>
  //               <TinyText>{formatDuration(position)}</TinyText>
  //               <TinyText>-{formatDuration(duration - position)}</TinyText>
  //             </Box>
  //             <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: -1 }}>
  //               <IconButton aria-label="previous song" onClick={() => PlayVideo(position - 900)}>
  //                 <FastRewindRounded fontSize="large" htmlColor={mainIconColor} sx={{ fontSize: '1.5rem', color: '#FFFFFF' }} />
  //               </IconButton>
  //               <IconButton aria-label={paused ? 'play' : 'pause'} onClick={() => PlayPause(paused)} sx={{ padding: '4px', border: '2px solid #FFF' }}>
  //                 {paused ? (
  //                   <PlayArrowRounded sx={{ fontSize: '1.5rem', color: '#BD1D23' }} htmlColor={mainIconColor} />
  //                 ) : (
  //                   <PauseRounded sx={{ fontSize: '1.5rem', color: '#FDCA1C' }} htmlColor={mainIconColor} />
  //                 )}
  //               </IconButton>
  //               <IconButton aria-label="next song" onClick={() => PlayVideo(position + 900)}>
  //                 <FastForwardRounded fontSize="large" htmlColor={mainIconColor} sx={{ fontSize: '1.5rem', color: '#FFFFFF' }} />
  //                 {/* <span style={{color:'white', fontSize:'8px'}}>{videoPlayTime}</span> */}
  //               </IconButton>

  //             </Box>
  //           </Box>
  //         )}
  //       </Widget>
  //     )}
  //   </div>
  //   <div>
  //       <a onClick={handleRefresh}>
  //         <Tooltip title="Refresh">
  //           <RefreshIcon color='#BD1D23' />
  //         </Tooltip>
  //       </a>
  //     </div>
  //   </>
  // ), [alramType, playerHover, paused, position, mainIconColor, duration, PlayPause, PlayVideo, formatDuration]);
  const renderFooter = useMemo(() => (
    <>
      <div style={{ position: 'absolute', marginLeft: '32px', marginBottom: '20px', zIndex: 1, width: '-webkit-fill-available', bottom: 0, left: 0 }}>
        {alramType === 'history' && (
          <Widget onMouseEnter={() => setPlayerHover(true)} onMouseLeave={() => setPlayerHover(false)} sx={{ display: 'flex', alignItems: 'center', gap: '5px', width: playerHover ? 343 : 100 }}>
            {!playerHover ? (
              <>
                <IconButton aria-label={paused ? 'play' : 'pause'} onClick={() => PlayPause(paused)} sx={{ padding: '4px', border: '2px solid #FFF', mr: 2 }}>
                  {paused ? (
                    <PlayArrowRounded sx={{ fontSize: '1.5rem', color: '#BD1D23' }} htmlColor={mainIconColor} />
                  ) : (
                    <PauseRounded sx={{ fontSize: '1.5rem', color: '#FDCA1C' }} htmlColor={mainIconColor} />
                  )}
                </IconButton>
                <Box sx={{ width: 8, height: 8, backgroundColor: '#BD1D23', borderRadius: '8px' }} />
                <TinyText>{formatDuration(position)}</TinyText>
              </>
            ) : (
              <Box sx={{ width: '100%' }}>
                <Slider
                  aria-label="time-indicator"
                  size="small"
                  value={position}
                  min={0}
                  step={900}
                  max={duration}
                  onChange={(_, value) => PlayVideo(value)}
                  sx={{ color: '#BD1D23', height: 6.5, '& .MuiSlider-thumb': { display: 'none' }, '& .MuiSlider-rail': { opacity: 0.28 } }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: -2 }}>
                  <TinyText>{formatDuration(position)}</TinyText>
                  <TinyText>-{formatDuration(duration - position)}</TinyText>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: -1 }}>
                  <IconButton aria-label="previous song" onClick={() => PlayVideo(position - 900)}>
                    <FastRewindRounded fontSize="large" htmlColor={mainIconColor} sx={{ fontSize: '1.5rem', color: '#FFFFFF' }} />
                  </IconButton>
                  <IconButton aria-label={paused ? 'play' : 'pause'} onClick={() => PlayPause(paused)} sx={{ padding: '4px', border: '2px solid #FFF' }}>
                    {paused ? (
                      <PlayArrowRounded sx={{ fontSize: '1.5rem', color: '#BD1D23' }} htmlColor={mainIconColor} />
                    ) : (
                      <PauseRounded sx={{ fontSize: '1.5rem', color: '#FDCA1C' }} htmlColor={mainIconColor} />
                    )}
                  </IconButton>
                  <IconButton aria-label="next song" onClick={() => PlayVideo(position + 900)}>
                    <FastForwardRounded fontSize="large" htmlColor={mainIconColor} sx={{ fontSize: '1.5rem', color: '#FFFFFF' }} />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Widget>
        )}
      </div>
      <RefreshButton handleRefresh={handleRefresh} />
    </>
  ), [alramType, playerHover, paused, position, mainIconColor, duration, PlayPause, PlayVideo, formatDuration]);

  return (
    <div style={{ position: 'relative' }} >
      {isLoading ? (
        // Render loading state
        <Box >
          <Skeleton variant="rectangular" sx={{ height: 'calc(100vh - 72px)' }} />
        </Box>
      ) : (
        // Render map and header
        <>
          {renderHeader}
          <div ref={mapContainer} style={{ height: 'calc(100vh - 72px)' }}>
            <div className="mapboxgl-ctrl-logo" style={{ display: 'none' }}></div>
          </div>
          {renderFooter}

        </>
      )}
    </div>
  );
}