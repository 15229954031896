
import React from 'react';
import { Box, Tooltip, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';



export const RefreshButton = ({ handleRefresh }) => {

    return (
        <>
            <div style={{ position: 'absolute', bottom: '8px', right: '0.7%', zIndex: 1, background: 'white', borderRadius: '50%', padding: '2px' }}>
                <Tooltip title="Refresh">
                    <IconButton
                        aria-label="refresh"
                        onClick={handleRefresh}
                        sx={{
                            color: '#BD1D23',
                            border: '1px solid white',
                            borderRadius: '50%',
                            padding: '2px',
                            backgroundColor: 'white'
                        }}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </>)
};