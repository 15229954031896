import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Box, Button, Typography, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import NewGroupModal from './new_group_modal.component';
import { notify } from '../../common/functions/react-toastify.function';
import { deleteGroup } from './userManagementFunctions/delete_group_function';
export default function GroupDataTable(props) {
    const { groups, fetchGroups } = props;
    const [searchText, setSearchText] = useState('');
    const [openGroupModal, setOpenGroupModal] = useState(false);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);
    };
    const handleDeleteGroup = async (rowData, event) => {
        try {
            await deleteGroup(rowData?.id);
            notify('success', 'Group Deleted Successfully')
            await fetchGroups();
            // handleClose();
        } catch (error) {
            console.error('Error updating permissions:', error);
            notify('error', 'Something went wrong')
            // Handle error appropriately (e.g., show an error message to the user)
        }
    };
    // Map the group data to rows
    const rows = groups?.map((group, index) => ({
        id: group.group_id,
        group_name: group.group_name,
        group_description: group.group_description,
        user: group.user_count, // Add logic if you have user data
        created: '', // Add logic if you have created date
        // status: '', // Add logic if you have status information
    }));

    const filteredRows = rows?.filter((row) =>
        Object.keys(row).some((key) =>
            row[key] !== null && row[key] !== undefined && row[key].toString().toLowerCase().includes(searchText)
        )
    );
    const handleCloseGroupModal = () => setOpenGroupModal(false);
    const columns = [
        { field: 'group_name', headerName: 'Name', width: 269 },
        { field: 'group_description', headerName: 'Description', width: 474 },
        { field: 'user', headerName: 'User', width: 100 }, // Placeholder for user data
        { field: 'created', headerName: 'Created', width: 210 }, // Placeholder for created date
        // { field: 'status', headerName: 'Status', width: 150 }, // Placeholder for status
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <div>
                    <Button
                        variant="outlined"
                        sx={{
                            color: '#BD1D23',
                            borderColor: '#BD1D23',
                            '&:hover': {
                                borderColor: '#BD1D23',
                                backgroundColor: 'rgba(189, 29, 35, 0.08)',
                            },
                        }}
                        style={{ marginRight: 8 }}
                    >
                        <svg onClick={() => handleDeleteGroup(params.row)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M14.12 10.47L12 12.59L9.87 10.47L8.46 11.88L10.59 14L8.47 16.12L9.88 17.53L12 15.41L14.12 17.53L15.53 16.12L13.41 14L15.53 11.88L14.12 10.47ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9Z" fill="black" fill-opacity="0.54" />
                        </svg>
                    </Button>
                </div>
            ),
        }, // Action buttons
    ];
    return (
        <ThemeProvider theme={createTheme()}>
            <Box>
                <NewGroupModal open={openGroupModal} handleClose={handleCloseGroupModal} fetchGroups={fetchGroups} />
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
                    <Typography variant="h6">Groups</Typography>
                    <Box marginRight={2}>
                        <TextField
                            label="Search"
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Search"
                            style={{ marginRight: 8 }}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/* <Button
                            variant="outlined"
                            sx={{
                                marginRight: '8px',
                                backgroundColor: '#FFF',
                                color: '#BD1D23',
                                borderColor: '#BD1D23',
                                '&:hover': {
                                    borderColor: '#BD1D23',
                                    backgroundColor: 'rgba(189, 29, 35, 0.1)',
                                },
                            }}
                            startIcon={<RefreshIcon style={{ color: '#BD1D23' }} />}
                        >
                            Assign Group
                        </Button> */}
                        <Button variant="contained" sx={{
                            '&:hover': {
                                borderColor: '#BD1D23',
                                backgroundColor: 'rgba(189, 29, 35, 0.1)', // Optional: Adds a hover effect with a light background
                            },
                        }} onClick={() => setOpenGroupModal(true)} style={{ backgroundColor: '#BD1D23' }} startIcon={<AddIcon />}>
                            New Group
                        </Button>
                    </Box>
                </Box>
                <div style={{ height: 400, width: '99%' }}>
                    <DataGrid
                        rows={filteredRows}
                        columns={columns}
                        getRowId={(row) => row.id}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                fontSize: '16px',
                                fontWeight: 600,
                                color: '#1D242E',
                                fontFamily: 'Arial, sans-serif',
                            },
                            '& .-MuiDataGrid-columnHeaderTitle': {
                                fontWeight: '600',
                            },
                            '.css-t89xny-MuiDataGrid-columnHeaderTitle': {
                                fontWeight: '600'
                            },
                            '& .MuiDataGrid-checkboxInput.Mui-checked': {
                                color: '#BD1D23',
                            },
                            '& .MuiDataGrid-row.Mui-selected': {
                                backgroundColor: 'inherit', // Remove the background color change
                                '&:hover': {
                                    backgroundColor: 'inherit', // Remove the hover effect
                                },
                            },
                        }}
                    />
                </div>
            </Box>
        </ThemeProvider>
    );
}
