const url = process.env.REACT_APP_PUBLIC_GEO_SERVER_URL

export const GeoServerLinks = {
    fttsLinkLayer: `${url}/geoserver/ftts_geo_data/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ftts_geo_data%3Ah_ftts_geo_data&maxFeatures=5000&outputFormat=application%2Fjson`,
    fttsSiteLayer: `${url}/geoserver/ftts_geo_data/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=ftts_geo_data%3Ah_ftts_site_geo_data&maxFeatures=500000&outputFormat=application%2Fjson`,
    mpbnLinkLayer: `${url}/geoserver/mpbn_geo_data/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mpbn_geo_data%3Ah_mpbn_geo_data&maxFeatures=10000&outputFormat=application%2Fjson`,
    mpbnSiteLayer: `${url}/geoserver/mpbn_geo_data/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mpbn_geo_data%3Ah_mpbn_sites_geo_data&maxFeatures=1000&outputFormat=application%2Fjson`,
    otnLinkLayer: `${url}/geoserver/otn_geo_data/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=otn_geo_data%3Ah_otn_geo_data&maxFeatures=500000&outputFormat=application%2Fjson`,
    otnSiteLayer: `${url}/geoserver/otn_geo_data/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=otn_geo_data%3Ah_otn_site_geo_data&maxFeatures=50000&outputFormat=application%2Fjson`,
    ipranLinkLayer: `${url}/ftts`,
    ipranSiteLayer: `${url}/mpbn`,
}