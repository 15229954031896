import apiClient from '../../../common/functions/api_axios_instance.function';
import { logout } from '../../../common/functions/log_out.function';

const API_URL = process.env.REACT_APP_API_URL;

export const getAllUsers = async () => {
//   const authState = getAuthenticationState();
 try{ 
  const resp = await apiClient.get(`/users/showAllUsers`, {
    // headers: {
    //   authorization: `Bearer ${authState.token}`
    // }
  });
console.log('resp.status',resp.status)
  return (resp.data);

} catch (error) {
  if(error?.response?.status === 401) {logout()}
    console.error('Error fetching users:', error);
  }
}
