import React, { useMemo, useState, useEffect } from 'react';
import { Box, Grid, TextField, Typography, createTheme, Button, InputAdornment, Chip, Checkbox } from '@mui/material';
import {
    MaterialReactTable,
    useMaterialReactTable
} from "material-react-table";
import TableChartIcon from '@mui/icons-material/TableChart';
import MapIcon from '@mui/icons-material/Map';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { deleteGroup } from './userManagementFunctions/delete_group_function';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ThemeProvider } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AssignGroupModal from './assign_group_modal.component';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { notify } from '../../common/functions/react-toastify.function';
import { deleteUser } from './userManagementFunctions/delete_user.function';
import { styled } from '@mui/material/styles';
import { assignAndUnassignedUser } from './userManagementFunctions/assign_and-unassigned_user.function';
import NewUserModal from './new_user_modal.component';
import { convertDateToLocalString } from '../../common/functions/convert_date_to_local_string.function';
import { convertIsoToString } from '../../common/functions/date_to_string.function';
import Swal from 'sweetalert2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FilterListIcon from '@mui/icons-material/FilterList';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import TuneIcon from '@mui/icons-material/Tune';
import { downloadExcelSheetFunction } from '../../common/functions/download_excel_sheet..function';
// import { ExpandableTable } from './expandableTable';
import { borderRadius, boxSizing, color, display, fontSize, fontWeight, lineHeight, padding } from '@mui/system';
import DateRangePickerComponent from '../../common/components/Date_Range_Picker.component';
import AssignBulkGroupModal from './bulk_assign_groups_to_users.component';
import EditPermissionModal from './edit_permission_modal.component';
import NewGroupModal from './new_group_modal.component';
const theme = createTheme({
    typography: {
        // fontFamily: 'Poppins',
    },
});

export default function GroupAndPermissionManagementTable({
    groups,
    setSelectedDate,
    setAlramType,
    fetchGroups
}) {
    const [position, setPosition] = useState(0);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false)
    const [editUser, setEditUser] = useState(null);
    const [openAssignGroupModal, setOpenAssignGroupModal] = useState(false)
    const [openBulkAssignGroupModal, setOpenBulkAssignGroupModal] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false);
    const validData = Array.isArray(groups) ? groups : [];
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [filteredRows, setFilteredRows] = useState();
    const [openEditPermissionModal, setOpenEditPermissionModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [openGroupModal, setOpenGroupModal] = useState(false);
    const [editGroup, setEditGroup] = useState(null);


    useEffect(() => {
        setFilteredRows(groups);
    }, [groups]);


    const handleEditClick = (rowData, event) => {
        setSelectedRowData(rowData);
        setOpenEditPermissionModal(true);
    };

    const handleCloseEditPermissionModal = () => {
        setOpenEditPermissionModal(false);
        setSelectedRowData(null);
    };
    const handleEditGroupClick =(row)=>{
        setSelectedRowData(row)
        setOpenGroupModal(true)
    }
    const handleDeleteGroup = async (rowData, event) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                setSelectedRowData(rowData);
                try {
                    await deleteGroup(rowData?.group_id);
                    notify('success', 'Group Deleted Successfully')
                    await fetchGroups();
                    // handleClose();
                } catch (err) {
                    notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong')
                }
            }
        });
        // try {
        //     await deleteGroup(rowData?.group_id);
        //     notify('success', 'Group Deleted Successfully')
        //     await fetchGroups();
        //     // handleClose();
        // } catch (err) {
        //     notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong')
        // }
    };


    const handleCheckboxChange = (userId) => {
        console.log('iddd', userId)
        setSelectedUserIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(userId)) {
                return prevSelectedIds.filter((id) => id !== userId);
            } else {
                return [...prevSelectedIds, userId];
            }
        });
    };
    const handleChange = (value) => {
        setAlramType(value);
    };
    
    const colorStyles = [
        {
            backgroundColor: '#FFF7E0', // Light yellow
            color: '#D89200', // Dark yellow
            borderColor: '#D89200', // Dark yellow border
        },
        {
            backgroundColor: '#F5F3FF', // Light green
            color: '#7C3AED', // Dark green
            borderColor: '#C4B5FD', // Dark green border
        },
        {
            backgroundColor: '#FEF2F2', // Light blue
            color: '#DC2626', // Dark blue
            borderColor: '#FCA5A5', // Dark blue border
        },
        // Add more styles if needed
    ];
    const handleDeleteUser = async (rowData, event) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                setSelectedRowData(rowData);
                try {
                    await deleteUser(rowData?.user_id);
                    notify('success', 'User Deleted Successfully')
                    await fetchGroups();
                    handleClose();
                } catch (err) {
                    notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong')
                    // Handle error appropriately (e.g., show an error message to the user)
                }
            }
        });
    };
    const handleAssignClick = async (rowData, event) => {
        // event.stopPropagation();
        setSelectedRowData(rowData);
        try {
            const assignUserObj = {
                status: !rowData.status
            }
            await assignAndUnassignedUser(rowData?.user_id, assignUserObj);
            notify('success', 'User Assigned update Successfully')
            await fetchGroups();
            handleClose();
        } catch (err) {
            notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong')
            // Handle error appropriately (e.g., show an error message to the user)
        }
    };
    const handleEditUserClick = (row) => {
        console.log('fullRow', row)
        setSelectedRowData(row)
        setEditUser(true)
    }
    const handleCloseAssignGroupModal = () => {
        setOpenAssignGroupModal(false);
        setSelectedRowData(null);
        setOpenBulkAssignGroupModal(false)
    };
    const handleSelectAll = (event) => {
        if (event.target.checked) {
            // Select all users
            setSelectedUserIds(groups?.map((item) => item.group_id));
        } else {
            // Deselect all users
            setSelectedUserIds([]);
        }
    };
    const handleClose = () => {
        setOpen(false);
        setEditUser(false);
        setEditUser(null);
    };
    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const columns = useMemo(() => [
        // {
        //   header: (
        //     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'auto' }}>
        //     <Checkbox
        //       style={{ padding: 0, width: 20, height: 20 }} // Adjust size as needed
        //       checked={selectedUserIds.length === users.length}
        //       indeterminate={selectedUserIds.length > 0 && selectedUserIds.length < users.length}
        //       onChange={handleSelectAll}
        //     />
        //   </div>
        //   ),
        //   accessorKey: 'user_id',
        //   width:40,
        //   Cell: ({cell}) => {
        //     const userId = cell?.getValue(); 
        //     return (
        //       <div style={{display: 'flex',
        //         alignItems: 'center',
        //         justifyContent: 'center',
        //         width: '40px'}}>
        //       <Checkbox
        //       checked={selectedUserIds.includes(userId)}  // Check if user_id is selected
        //         onChange={() => handleCheckboxChange(userId)} // Pass user_id to handler
        //       />
        //       </div>
        //     );
        //   },
        // },
        {
            accessorKey: 'group_name',
            header: 'Name',
            size: 120,
            Cell: ({ cell }) => (<span style={{ color: '#24A0ED' }}>{cell?.getValue()}</span>),
        },
        {
            accessorKey: 'permissions',
            header: 'Remarks',
            size: 200,
            Cell: ({ cell }) => (
                <span>
                    {/* {Array.isArray(cell.getValue()) && cell.getValue().length > 0
            ? cell.getValue().map(group => group.name).join(', ')
            : ''} */}
                    {Array.isArray(cell.getValue()) && cell.getValue().length > 0
                        ? cell.getValue().map((group, index) => {
                            const styles = colorStyles[index % colorStyles.length];
                            return <span style={{
                                background: styles.backgroundColor,
                                color: styles.color,
                                borderColor: styles.borderColor,
                                borderWidth: 1,
                                border: `1px solid ${styles.borderColor}`,
                                padding: '2px 8px',
                                fontSize: '10.5px',
                                fontWeight: '550',
                                marginRight: '6px',
                                borderRadius: '24px',
                            }}>
                                {group.name}
                            </span>
                        })
                        : null}
                </span>
            ),
        },
        {
            accessorKey: 'group_description',
            header: 'Description',
            size: 150,
        },
        {
            accessorKey: 'created_at',
            header: 'Created Date',
            size: 150,
            Cell: ({ cell }) => dayjs(cell.getValue()).format('YYYY-MM-DD'), // Format the date
        },
        {
            accessorKey: 'actions', // Custom column for action buttons
            header: 'Actions',
            size: 150,
            Cell: ({ row }) => (
                <div>
                    <svg onClick={() => handleEditGroupClick(row.original)} xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer', marginRight: '16px' }} width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM5.92 19H5V18.08L14.06 9.02L14.98 9.94L5.92 19ZM20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3C17.4 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63Z" fill="black" fill-opacity="0.54" />
                    </svg>
                    <svg onClick={() => handleEditClick(row.original)} style={{ cursor: 'pointer', marginRight: '16px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9 15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5 8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96 11.87 14.46 12 15 12Z" fill="black" fill-opacity="0.54" />
                    </svg>
                    <svg onClick={() => handleDeleteGroup(row.original)} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M14.12 10.47L12 12.59L9.87 10.47L8.46 11.88L10.59 14L8.47 16.12L9.88 17.53L12 15.41L14.12 17.53L15.53 16.12L13.41 14L15.53 11.88L14.12 10.47ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9Z" fill="black" fill-opacity="0.54" />
                    </svg>
                </div>
            ),
        },
    ], [groups, selectedUserIds]);

    const table = useMaterialReactTable({
        columns,
        data: validData,
        enableColumnFilterModes: true,
        enableColumnOrdering: true,
        enableGrouping: true,
        enableColumnPinning: true,
        enableFacetedValues: true,
        initialState: {
            showColumnFilters: true,
            showGlobalFilter: true,
            columnPinning: {
                left: ['mrt-row-expand', 'mrt-row-select'],
                right: ['mrt-row-actions'],
            },
        },
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        muiSearchTextFieldProps: {
            size: 'small',
            variant: 'outlined',
        },
        muiPaginationProps: {
            color: 'secondary',
            rowsPerPageOptions: [10, 20, 30],
            shape: 'rounded',
            variant: 'outlined',
            sx: {
                '& .MuiPaginationItem-root.Mui-selected': {
                    backgroundColor: '#BD1D23',
                    color: 'white',
                },
            },
        },
        enableBottomToolbar: true,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enablePagination: true,
        initialState: { density: 'compact' },
        muiTableContainerProps: { sx: { maxHeight: "calc(100vh - 320px)" } },
        muiTableBodyCellProps: {
            sx: {
                color: "#667085",
                backgroundColor: '#fff',
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: '400',
                lineHeight: "125%",
                height: "36px",
            },
        },
        muiTableHeadCellProps: {
            sx: {
                color: "#344054",
                backgroundColor: '#E8E9E9',
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: '600',
                lineHeight: "125%",
            },
        },
        muiTablePaperProps: {
            sx: {
                boxShadow: 'none',
            },
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Tooltip title="Assign Groups">
                {selectedUserIds.length ? <svg onClick={assignBulk} style={{ cursor: 'pointer', marginRight: '16px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9 15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5 8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96 11.87 14.46 12 15 12Z" fill="black" fill-opacity="0.54" />
                </svg> : null}
            </Tooltip>
        ),
        
    });
    const assignBulk = () => {
        setOpenBulkAssignGroupModal(true);
    }
    const handleClearCheck = () => {
        setSelectedUserIds([])
    }
    const handleCloseGroupModal = () => setOpenGroupModal(false);
    console.log('final', selectedUserIds)
    return (
        <div style={{ marginRight: "32px", marginTop: "20px" }}>
            <EditPermissionModal
                open={openEditPermissionModal}
                handleClose={handleCloseEditPermissionModal}
                rowData={selectedRowData} // Pass the selected row data to the modal
                fetchGroups={fetchGroups}
            />
            {/* <NewUserModal open={editUser} row={selectedRowData} handleClose={handleClose} fetchUsers={fetchUsers} /> */}
            <NewGroupModal open={openGroupModal} row={selectedRowData} handleClose={handleCloseGroupModal} fetchGroups={fetchGroups} />
            <div className="box" style={{ marginTop: "20px" }}>
                <MaterialReactTable table={table} />
            </div>
        </div>
    )
}
