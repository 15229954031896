
import React from 'react';
import { Box, Tooltip, GlobalStyles } from '@mui/material';
import { keyframes } from '@emotion/react'; 
import { getSessionStorageData } from '../functions/get_total_alarms_counts_function';
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
export const CustomTooltip = ({ title, children }) => {
  const sessionData = getSessionStorageData(title);
  const tooltipContent = `${title} ${sessionData ? `: ${sessionData}` : ''}`;
return (   
<>
<GlobalStyles styles={{ '.MuiTooltip-tooltip': { zIndex: 9999 } }} />

<Tooltip
    title={tooltipContent}
    arrow
    componentsProps={{
      tooltip: {
        sx: {
          animation: `${fadeIn} 0.3s ease-in-out`,
          bgcolor: '#BD1D23', 
          color: 'white', 
          fontSize: '1em',
          zIndex:9999
        },
      },
      arrow: {
        sx: {
          color: '#BD1D23',
        },
      },
    }}
  >
      {children}
    </Tooltip></>)
};