import * as XLSX from 'xlsx';

export const downloadExcelSheetFunction = (data,name) => {
    // Get all unique keys from the data objects
  const allKeys = Array.from(new Set(data.flatMap(Object.keys)));

  // Prepare the data with all columns
  const fullData = data?.map(item =>
    allKeys.reduce((acc, key) => {
      acc[key] = item[key] || '';
      return acc;
    }, {})
  );

  const worksheet = XLSX.utils.json_to_sheet(fullData);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'alarms');

 name === 'users' ?  XLSX.writeFile(workbook, 'users.xlsx') : XLSX.writeFile(workbook, 'alarms.xlsx');
  
////////**************  download only columns which are in the table ************////////
  // const keysToShow = columns.map(column => column.accessorKey);
  //   const filteredData = data.map(item =>
  //     keysToShow.reduce((acc, key) => {
  //       acc[key] = item[key];
  //       return acc;
  //     }, {})
  //   );

  //   const worksheet = XLSX.utils.json_to_sheet(filteredData);

  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'alarms');

  //   XLSX.writeFile(workbook, 'alarms.xlsx');
  
  // download only those columns which are shown in the table
    // const filteredData = data.map(item =>
    //   columns.reduce((acc, key) => {
    //     acc[key] = item[key];
    //     return acc;
    //   }, {})
    // );

    // const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'alarms');

    // XLSX.writeFile(workbook, 'alarms.xlsx');
  }