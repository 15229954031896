import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, TextField, Typography, InputAdornment, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AssignGroupModal from './assign_group_modal.component';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { notify } from '../../common/functions/react-toastify.function';
import { deleteUser } from './userManagementFunctions/delete_user.function';
import { styled } from '@mui/material/styles';
import { assignAndUnassignedUser } from './userManagementFunctions/assign_and-unassigned_user.function';
import NewUserModal from './new_user_modal.component';
import { convertDateToLocalString } from '../../common/functions/convert_date_to_local_string.function';
import { convertIsoToString } from '../../common/functions/date_to_string.function';
import Swal from 'sweetalert2';
import UserManagementTable from './user_management_table.component';

const StyledChip = styled(Chip)(({ theme }) => ({
    backgroundColor: '#fff4d9', // Light yellow background
    color: '#e7922e', // Dark yellow text color
    border: '1px solid #e7922e', // Dark yellow border
    fontWeight: 'bold',
    borderRadius: '2px',
    '& .MuiChip-deleteIcon': {
        color: '#e7922e', // Dark yellow close icon color
    },
    '&:hover': {
        backgroundColor: '#ffedcc', // Slightly darker yellow on hover
    },
}));

export default function DataTable(props) {
    const { users, fetchUsers } = props
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false)
    const [editUser, setEditUser] = useState(null);
    const [openAssignGroupModal, setOpenAssignGroupModal] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);

    console.log('userDate', users)
    useEffect(() => {
        const initialRows = users?.map((user) => ({
            id: user.user_id,
            name: user.user_name,
            group: user.groups.length > 0 ? user.groups : [],
            department: user.department,
            created: user.created_at ? convertIsoToString(user.created_at) : new Date().toLocaleDateString(),
            useremail: user.user_email,
            phoneNumber: user.phone_number,
            company: user.company,
            status: user.status,
            type: user?.type
        }));
        setRows(initialRows);
        setFilteredRows(initialRows);
    }, [users]);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);

        if (value === '') {
            setFilteredRows(rows);
        } else {
            const filteredData = rows.filter((row) =>
                Object.keys(row).some((key) =>
                    row[key] !== null && row[key] !== undefined && row[key].toString().toLowerCase().includes(value)
                )
            );
            setFilteredRows(filteredData);
        }
    };

    // const handleEditClick = (user) => {
    //     setEditUser(user);
    //     setOpen(true);
    // };
    const handleEditClick = (rowData, event) => {
        // event.stopPropagation(); // Prevents the row selection event
        setSelectedRowData(rowData);
        setOpenAssignGroupModal(true);
    };
    const colorStyles = [
        {
            backgroundColor: '#FFF7E0', // Light yellow
            color: '#D89200', // Dark yellow
            borderColor: '#D89200', // Dark yellow border
        },
        {
            backgroundColor: '#F5F3FF', // Light green
            color: '#7C3AED', // Dark green
            borderColor: '#C4B5FD', // Dark green border
        },
        {
            backgroundColor: '#FEF2F2', // Light blue
            color: '#DC2626', // Dark blue
            borderColor: '#FCA5A5', // Dark blue border
        },
        // Add more styles if needed
    ];
    const handleDeleteUser = async (rowData, event) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                setSelectedRowData(rowData);
                try {
                    await deleteUser(rowData?.id);
                    notify('success', 'User Deleted Successfully')
                    await fetchUsers();
                    handleClose();
                } catch (err) {
                    notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong')
                    // Handle error appropriately (e.g., show an error message to the user)
                }
            }
        });
    };
    const handleAssignClick = async (rowData, event) => {
        // event.stopPropagation();
        setSelectedRowData(rowData);
        try {
            const assignUserObj = {
                status: !rowData.status
            }
            await assignAndUnassignedUser(rowData?.id, assignUserObj);
            notify('success', 'User Assigned update Successfully')
            await fetchUsers();
            handleClose();
        } catch (err) {
            notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong')
            // Handle error appropriately (e.g., show an error message to the user)
        }
    };
    const handleEditUserClick = (row) => {

        setSelectedRowData(row)
        setEditUser(true)
    }
    const handleCloseAssignGroupModal = () => {
        setOpenAssignGroupModal(false);
        setSelectedRowData(null);
    };
    const handleClose = () => {
        setOpen(false);
        setEditUser(false);
        setEditUser(null);
    };
    const columns = [
        { field: 'name', headerName: 'Name', width: 250 },
        {
            field: 'group',
            headerName: 'Group',
            width: 500,
            renderCell: (params) => (
                <div>
                    {/* {params.value?.map((feature) => feature.name).join(', ')} */}
                    {params.value?.map((group, index) => {
                        const styles = colorStyles[index % colorStyles.length];
                        return (<Chip
                            key={index}
                            label={group.name}
                            sx={{
                                marginRight: 1,
                                marginBottom: 1,
                                backgroundColor: styles.backgroundColor,
                                color: styles.color,
                                borderColor: styles.borderColor,
                                borderWidth: 1,
                                marginTop: '1.2%',
                                borderStyle: 'solid',
                                '& .MuiChip-deleteIcon': {
                                    color: styles.color, // Delete icon color
                                },
                                borderRadius: '4px',
                                fontWeight: 'bold',
                            }}
                        />)
                    })}
                </div>
            ),
        },
        { field: 'department', headerName: 'Department', width: 150 },
        { field: 'created', headerName: 'Created', width: 140 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <div style={{ marginTop: '8px' }}>
                    <svg onClick={() => handleEditUserClick(params.row)} xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer', marginRight: '16px' }} width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM5.92 19H5V18.08L14.06 9.02L14.98 9.94L5.92 19ZM20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3C17.4 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63Z" fill="black" fill-opacity="0.54" />
                    </svg>
                    {/* </Button> */}
                    {/* <Button
                        variant="outlined"
                        sx={{
                            color: '#BD1D23',
                            borderColor: '#BD1D23',
                            '&:hover': {
                                borderColor: '#BD1D23',
                                backgroundColor: 'rgba(189, 29, 35, 0.08)',
                            },
                        }}
                        style={{ marginRight: 8 }}
                        onClick={() => handleAssignClick(params.row)}
                    > */}
                    <svg onClick={() => handleEditClick(params.row)} style={{ cursor: 'pointer', marginRight: '16px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9 15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5 8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96 11.87 14.46 12 15 12Z" fill="black" fill-opacity="0.54" />
                    </svg>
                    {/* </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            color: '#BD1D23',
                            borderColor: '#BD1D23',
                            '&:hover': {
                                borderColor: '#BD1D23',
                                backgroundColor: 'rgba(189, 29, 35, 0.08)',
                            },
                        }}
                        style={{ marginRight: 8 }}
                    > */}
                    <svg onClick={() => handleDeleteUser(params.row)} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M14.12 10.47L12 12.59L9.87 10.47L8.46 11.88L10.59 14L8.47 16.12L9.88 17.53L12 15.41L14.12 17.53L15.53 16.12L13.41 14L15.53 11.88L14.12 10.47ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9Z" fill="black" fill-opacity="0.54" />
                    </svg>
                    {/* </Button> */}
                </div>
            ),
        },
    ];

    return (
        <ThemeProvider theme={createTheme()}>
            <Box>
                <AssignGroupModal
                    open={openAssignGroupModal}
                    handleClose={handleCloseAssignGroupModal}
                    rowData={selectedRowData} // Pass the selected row data to the modal
                    fetchUsers={fetchUsers}
                />
                <NewUserModal open={editUser} row={selectedRowData} handleClose={handleClose} fetchUsers={fetchUsers} />
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
                    <Typography variant="h6">User Management</Typography>
                    {/* <Box marginRight={2} width={'384px'}>
                        <TextField
                            fullWidth
                            label="Search"
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Search"
                            style={{ marginRight: 8 }}
                            size="large"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/* <Button variant="contained" color="secondary" style={{ marginRight: '8px', backgroundColor: '#FFF', color: '#000' }} startIcon={<CloudUploadIcon style={{ color: '#BD1D23' }} />}>
                            Bulk Upload
                        </Button>
                        <Button variant="contained" sx={{
                            '&:hover': {
                                borderColor: '#BD1D23',
                                backgroundColor: 'rgba(189, 29, 35, 0.1)', // Optional: Adds a hover effect with a light background
                            },
                        }} onClick={() => setOpen(true)} style={{ backgroundColor: '#BD1D23' }} startIcon={<AddIcon />}>
                            New User
                        </Button> 
                    </Box> */}
                </Box>
                <div style={{ height: 400, width: '99%' }}>
                    {/* <DataGrid
                        rows={filteredRows}
                        columns={columns}
                        getRowId={(row) => row.id}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        disableSelectionOnClick
                        pageSizeOptions={[5, 10]}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                fontSize: '16px',
                                fontWeight: 600,
                                color: '#1D242E',
                                fontFamily: 'Arial, sans-serif',
                            },
                            '& .-MuiDataGrid-columnHeaderTitle': {
                                fontWeight: '600',
                            },
                            '.css-t89xny-MuiDataGrid-columnHeaderTitle': {
                                fontWeight: '600'
                            },
                            '& .MuiDataGrid-checkboxInput.Mui-checked': {
                                color: '#BD1D23',
                            },
                            '& .MuiDataGrid-row.Mui-selected': {
                                backgroundColor: 'inherit', // Remove the background color change
                                '&:hover': {
                                    backgroundColor: 'inherit', // Remove the hover effect
                                },
                            },
                            '& .MuiDataGrid-selectedRowCount': {
                                visibility: 'hidden', // Hide the selected row count
                            },
                        }}
                    /> */}
                <UserManagementTable  users={users} fetchUsers={fetchUsers}/>

                </div>
                {console.log('ussseeerr',users)}
            </Box>
        </ThemeProvider>
    );
}