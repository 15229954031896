import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Box, Typography, TextField, Button, Popper } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import axios from 'axios';
import alert from '../../Assets/AlertIcon/alertIcon.svg'
import Drawer from '@mui/material/Drawer';
import InputBase from '@mui/material/InputBase';
import TopHeader from '../../Components/TopHeader';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { Generals } from '../../enums/general.names.enum';
import alertButton from '../../Assets/AlertIcon/Alert.svg'
import Autocomplete from '@mui/material/Autocomplete';
import MapComponent from '../../Components/Map/Map';
import ActiveAlarmsCard from '../../Components/ActiveAlarmsCard';
import { logout } from '../../common/functions/log_out.function';
import ActiveAlarmsTable from '../../Components/ActiveAlarmsTable';
import io from 'socket.io-client';
import IpranMap from '../../Components/Map/IpranMap'
import OtnMap from '../../Components/Map/OtnMap'
import './styles.css';
import MapMpbn from '../../Components/Map/MpbnMap';
import dayjs from 'dayjs';
import apiClient from '../../common/functions/api_axios_instance.function';
// import wkx from 'wkx';
import { border, borderRadius } from '@mui/system';
import { getAllAlarmsFtts } from './functions/get-all-alarms-ftts.function';
import { MianNavBarLeft } from '../../common/components/main_navBar_left.component';
import { useNavigate } from 'react-router-dom';
// import './tabstyle.css'
// Custom Popper component for dropdown
const CustomPopper = styled(Popper)(({ theme }) => ({
  '.MuiAutocomplete-listbox': {
    backgroundColor: '#fff',
    color: '#585858',
    '& .MuiAutocomplete-option': {
      '&:hover': {
        backgroundColor: '#FBF4F4',
        color: '#F4686E',
        borderRadius: '45px',
      },
    },
  },
}));



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #E8E9E9',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    // width: 'auto',
  },
}));
const useStyles = styled({
  root: {
    color: '#fff',
    '& .MuiAutocomplete-input': {
      color: '#fff',
    },
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
    '& .MuiInputLabel-root': {
      color: '#fff',
    },
  },
  option: {
    '&:hover': {
      backgroundColor: '#F4686E',
      color: '#F4686E',
    },
  },
});
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'flex-end',
//   // padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));


const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '4px',
  textAlign: 'center',
}));
let alarmsAllData = []
export default function FttsLandingPage() {
  const classes = useStyles();
  const apiUrl = process.env.REACT_APP_API_URL;
  let socket;
  // const defaultDate = dayjs().subtract(1, 'day');
  const defaultDate = dayjs();
  const socketRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [viewType, setViewType] = useState(Generals.map);
  const [data, setData] = useState(alarmsAllData ? alarmsAllData : null);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState([defaultDate, defaultDate]);
  const [totalAlarms, setTotalAlarms] = useState(0);
  const [region, setRegion] = useState('');
  const markerRef = useRef(null);
  const [city, setCity] = useState('');
  const [ring, setRing] = useState('');
  const [link, setLink] = useState('');
  const [site, setSite] = useState('');
  const permissionsJSON = localStorage.getItem('permissions');
  let permissions = [];
  try {
    permissions = JSON.parse(permissionsJSON) || [];
  } catch (error) {
    console.error("Failed to parse permissions:", error);
  }
  const permissionNames = Array.isArray(permissions) ? permissions.map(permission => permission.name) : [];
  const [valueTab, setValueTab] = useState(Generals.ftts);
  const [selectedAlarm, setSelectedAlarm] = useState(null);
  const [videoPlayTime, setVideoPlayTime] = useState(`2024-03-22 00:00:00`);
  const [videoPlayTimeEnd, setVideoPlayTimeEnd] = useState(`2024-07-15 00:00:00`);
  const [alramType, setAlramType] = useState(Generals.live);
  // const [menuCollapse, setMenuCollapse] = useState(false)
  const [filtersData, setFiltersData] = useState(null);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [rings, setRings] = useState([]);
  const [sites, setSites] = useState([]);
  const [links, setLinks] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [applyFilters, setApplyFilters] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);
  const navigate = useNavigate()
  const [initialFilters, setInitialFilters] = useState({ region: '', city: '', ring: '', site: '', link: '' });
  const [filtersValue, setFiltersValue] = useState({
    region: '',
    city: '',
    ring: '',
    site: '',
    link: ''
  });
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  useLayoutEffect(() => {
    if (!permissionNames.length) {
      navigate('/rov')
      return
    }
    if (
      !permissionNames.includes('FTTS') &&
      !permissionNames.includes('MPBN') &&
      !permissionNames.includes('OTN') &&
      !permissionNames.includes('IPRAN')
    ) {
      navigate('/rov');
      return;
    }
    const tabCurrent = sessionStorage.getItem('selectedTab')
    if (tabCurrent) {
      setValueTab(tabCurrent)
      return
    }
    if (permissionNames.includes('FTTS')) {
      sessionStorage.setItem('selectedTab', 'ftts');
      setValueTab('ftts')
      return
    }
    if (permissionNames.includes('MPBN')) {
      sessionStorage.setItem('selectedTab', 'mpbn');
      setValueTab('mpbn')
      return
    }
    if (permissionNames.includes('OTN')) {
      sessionStorage.setItem('selectedTab', 'otn');
      navigate('/rov')
      return
    }
    if (permissionNames.includes('IPRAN')) {
      sessionStorage.setItem('selectedTab', 'ipran');
      setValueTab('otn')
      navigate('/rov')
      return
    }
  })
  const fetchMpbnDataFromSocket = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.disconnect(); // Disconnect previous socket instance if it exists
    }
    socketRef.current = io(`${apiUrl}`, {
      rememberUpgrade: true,
      transports: ['websocket'],
      secure: true,
      rejectUnauthorized: false
    });

    socketRef.current.on('connect', () => {

      // Notify the server about the 'alarm' event with queryParams
      socketRef.current.emit('mpbn', {
        region: filtersValue?.region,
        city: filtersValue?.city,
        ring: filtersValue?.ring,
        link: filtersValue?.link,
        site: filtersValue?.site
      });

      socketRef.current.on('initial:alarms', (data) => {
        alarmsAllData = data
        console.log('Received Mpbn alarms data:', data);
        setData(data);
      });

      // Listen for regular alarms updates
      socketRef.current.on('mpbn:response', (data) => {
        if (alramType === Generals.live) {
          setData(data); // Update the state with the new alarms data
          if (data?.length !== sessionStorage.getItem(Generals.totalMpbnAlarms) && data?.length > sessionStorage.getItem(Generals.totalMpbnAlarms)) { sessionStorage.setItem(Generals.totalMpbnAlarms, data?.length) }
        }
      });
    });

    socketRef.current.on('disconnect', () => {
      // setData([])
      console.log('Disconnected from the socket server');
    });
  });
  const fetchOtnDataFromSocket = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.disconnect(); // Disconnect previous socket instance if it exists
    }
    socketRef.current = io(`${apiUrl}`, {
      rememberUpgrade: true,
      transports: ['websocket'],
      secure: true,
      rejectUnauthorized: false
    });

    socketRef.current.on('connect', () => {

      // Notify the server about the 'alarm' event with queryParams
      socketRef.current.emit('otn', {
        region: filtersValue?.region,
        city: filtersValue?.city,
        ring: filtersValue?.ring,
        link: filtersValue?.link,
        site: filtersValue?.site
      });

      socketRef.current.on('initial:alarms', (data) => {
        alarmsAllData = data
        console.log('Received otn alarms data:', data);
        setData(data);
      });

      // Listen for regular alarms updates
      socketRef.current.on('otn:response', (data) => {
        if (alramType === Generals.live) {
          setData(data); // Update the state with the new alarms data
          if (data?.length !== sessionStorage.getItem(Generals.totalOtnAlarms) && data?.length > sessionStorage.getItem(Generals.totalOtnAlarms)) { sessionStorage.setItem(Generals.totalOtnAlarms, data?.length) }
        }
      });
    });

    socketRef.current.on('disconnect', () => {
      // setData([])
      console.log('Disconnected from the socket server');
    });
  });

  const fetchDataFromSocket = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.disconnect(); // Disconnect previous socket instance if it exists
    }
    socketRef.current = io(`${apiUrl}`, {
      rememberUpgrade: true,
      transports: ['websocket'],
      secure: true,
      rejectUnauthorized: false
    });

    socketRef.current.on('connect', () => {

      // Notify the server about the 'alarm' event with queryParams
      socketRef.current.emit('alarm', {
        region: filtersValue?.region,
        city: filtersValue?.city,
        ring: filtersValue?.ring,
        link: filtersValue?.link,
        site: filtersValue?.site
      });

      // const timeout = setTimeout(() => {
      //   console.log('No response within 5 seconds');
      // }, 3000);
      // Listen for the initial alarms data
      socketRef.current.on('initial:alarms', (data) => {
        // clearTimeout(timeout)
        alarmsAllData = data
        setData(data);
      });

      // Listen for regular alarms updates
      socketRef.current.on('alarms:response', (data) => {
        if (alramType === 'live') {
          setData(data); // Update the state with the new alarms data
          // if (data?.length !== sessionStorage.getItem(Generals.totalFttsAlarms) && data?.length > sessionStorage.getItem(Generals.totalFttsAlarms)) { sessionStorage.setItem(Generals.totalFttsAlarms, data?.length) }
          if (data?.length !== Number(sessionStorage.getItem(Generals.totalFttsAlarms))) {
            sessionStorage.setItem(Generals.totalFttsAlarms, data?.length);
          }
        }
      });
    });

    socketRef.current.on('disconnect', () => {
      // setData([])
      console.log('Disconnected from the socket server');
    });
  });
  const fetchData = async () => {
    const tabValue = sessionStorage.getItem(Generals.selectedTab);
    if (alramType === Generals.history) {
      try {
        setLoading(true);
        const response = await apiClient.get(
          valueTab === Generals.ftts
            ? `/ftts/historicAlarms`
            : valueTab === Generals.mpbn
              ? `/mpbn/historicAlarms`
              : valueTab === Generals.otn
                ? `/otn/historicAlarms`
                : valueTab === Generals.ipran
                  ? `/ipran/historicAlarms`
                  : '/ftts/historicAlarms', // default case, assuming 'ftts' as fallback
          {
            params: {
              region: filtersValue?.region,
              city: filtersValue?.city,
              ...(valueTab !== Generals.otn && { ring: filtersValue?.ring }),
              dateTime: videoPlayTime,
              endDateTime: viewType === Generals.map ? videoPlayTime : videoPlayTimeEnd
            }
          }
        );
        setLoading(false);
        if (tabValue === Generals.ftts || tabValue === Generals.mpbn) {
          setData(response.data);
          if (response.data.length) {
            // Optionally handle the response data
          }
        } else {
          setData([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error?.response?.status === 401) { logout() }
        setData([])
        setLoading(false);
      }
    } else {
      setData([]);
    }
  };

  // Main effect to handle WebSocket connection or API data fetching
  useEffect(() => {
    const tabValue = sessionStorage.getItem(Generals.selectedTab);
    if (alramType === Generals.live && tabValue === Generals.ftts) {
      fetchDataFromSocket();
    }
    if ((alramType === Generals.live && tabValue === Generals.mpbn) && !filtersValue?.region) {
      fetchMpbnDataFromSocket();
      setData([])
    }
    if ((alramType === Generals.live && tabValue === Generals.otn)) {
      fetchOtnDataFromSocket();
      setData([])
    }
    else {
      fetchData();
    }

    // Cleanup function to disconnect WebSocket
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null; // Clean up reference
        console.log('Socket disconnected');
      }
    };
  }, [filtersValue, alramType, videoPlayTime, valueTab, videoPlayTimeEnd]);

  // useEffect(() => {
  //   const tabValue = sessionStorage.getItem('selectedTab');
  //   if ((alramType === 'live' && tabValue === 'ftts') && !filtersValue?.region) {
  //     fetchDataFromSocket();
  //   }
  //   if ((alramType === 'live' && tabValue === 'mpbn') && !filtersValue?.region) {
  //     fetchMpbnDataFromSocket();
  //   }
  //   // Cleanup function to disconnect WebSocket
  //   return () => {
  //     if (socketRef.current) {
  //       socketRef.current.disconnect();
  //       socketRef.current = null; // Clean up reference
  //       console.log('Socket disconnected');
  //     }
  //   };
  // }, [filtersValue, alramType]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    })
    handleReset()
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: !state.right });
  };



  useEffect(() => {
    fetchFiltersData();
  }, [state, valueTab]);
  //   const fetchFiltersData = async () => {
  //     console.log('Tab',valueTab)
  //     try {
  //       // const response = await sessionStorage.getItem('selectedTab') === 'ftts' ? axios.get(`${apiUrl}/ftts/sitesInventory`) : axios.get(`${apiUrl}/mpbn/sitesInventory`);
  //       const response = await axios.get(valueTab === Generals.ftts ? `${apiUrl}/ftts/sitesInventory` : `${apiUrl}/mpbn/sitesInventory`);


  //       setFiltersData(response.data);
  //       // setLoading(false);
  //       // Set regions initially
  //       const initialRegions = Object.keys(response.data);
  //       const initialCities = Object.keys(response.data).flatMap(region => Object.keys(response.data[region]));
  //       const initialRings = Object.values(response.data).flatMap(region => Object.values(region).flatMap(city => city.ringNames));
  //       // const initialSites = Object.values(response.data).flatMap(region => Object.values(region).flatMap(city => city));
  //       let initialSites = [];
  // Object.values(response.data).forEach(region => {
  //   Object.values(region).forEach(city => {
  //     initialSites.push(...city.siteCodes);
  //   });
  // });
  // initialSites = [...new Set(initialSites)]; 
  //       const initialLinks =  regions.flatMap(region =>Object.values(response.data[region]).flatMap(city => city.linkNames));
  // setInitialFilters({
  //         region:initialRegions,
  //         city:initialCities,
  //         ring:initialRings,
  //         link:initialLinks,
  //         site:initialSites
  //       })
  //       setRegions(initialRegions);
  //       // Set cities and rings with all values initially
  //       setCities(initialCities);
  //       setRings(initialRings);
  //       setLinks(initialLinks);
  //       setSites(initialSites);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       // setLoading(false);
  //     }
  //   // }; 
  // }

  const fetchFiltersData = async () => {
    try {
      const response = await apiClient.get(
        valueTab === Generals.ftts
          ? `/ftts/sitesInventory`
          : valueTab === Generals.mpbn
            ? `/mpbn/sitesInventory`
            : valueTab === Generals.otn
              ? `/otn/sitesInventory`
              : valueTab === Generals.ipran
                ? `/ipran/sitesInventory`
                : '/ftts/sitesInventory'
      );
      console.log('valueTab', valueTab)
      const responseData = valueTab === Generals.otn ? response.data.response : response.data;
      console.log('tabChange', filtersData)
      setFiltersData(responseData);

      let initialRegions = [];
      let initialCities = [];
      let initialSites = [];
      let initialLinks = [];
      let initialRings = [];


      if (valueTab === Generals.ftts) {
        initialRegions = Object.keys(responseData);
        initialCities = Object.keys(responseData).flatMap(region => Object.keys(responseData[region]));
        initialRings = Object.values(response.data).flatMap(region => Object.values(region).flatMap(city => city.ringNames));
        initialSites = Object.values(responseData).flatMap(region => Object.values(region).flatMap(city => city.siteCodes));
        initialLinks = initialRegions.flatMap(region => Object.values(responseData[region]).flatMap(city => city.linkNames));
      }
      if (valueTab === Generals.mpbn) {
        const cityNamesSet = new Set();
        const siteNamesSet = new Set();
        const linkNames = [];

        Object.keys(responseData).forEach(city => {
          cityNamesSet.add(city);

          Object.keys(responseData[city]).forEach(site => {
            siteNamesSet.add(site);

            const linkNameArray = responseData[city][site]?.linkNames || [];
            linkNames.push(...linkNameArray);
          });
        });

        initialCities = [...cityNamesSet];
        initialSites = [...siteNamesSet];
        initialLinks = linkNames;
      }
      if (valueTab === Generals.otn) {
        initialRegions = Object.keys(responseData);
        initialCities = Object.keys(responseData).flatMap(region => Object.keys(responseData[region]));
        initialSites = initialRegions.flatMap(region =>
          Object.keys(responseData[region]).flatMap(city =>
            Object.keys(responseData[region][city])
          )
        );
        initialLinks = initialRegions.flatMap(region =>
          Object.keys(responseData[region]).flatMap(city =>
            Object.keys(responseData[region][city]).flatMap(site =>
              responseData[region][city][site].linkNames
            )
          )
        );
      }

      setInitialFilters({
        region: initialRegions,
        city: initialCities,
        ring: initialRings, // Set this accordingly if needed
        link: initialLinks,
        site: initialSites
      });

      setRegions(initialRegions);
      setCities(initialCities);
      setRings(initialRings); // Set this accordingly if needed
      setLinks(initialLinks);
      setSites(initialSites);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error?.response?.status === 401) { logout() }
    }
  };


  useEffect(() => {
    if (ring) {
      let selectedCity, selectedRegion;
      for (const region in filtersData) {
        for (const city in filtersData[region]) {
          if (filtersData[region][city].ringNames.includes(ring)) {
            selectedCity = city;
            selectedRegion = region;
            break;
          }
        }
        if (selectedCity) break;
      }

      setRegion(selectedRegion);
      setCity(selectedCity);
      setCities(Object.keys(filtersData[selectedRegion]));

      const selectedRings = filtersData[selectedRegion][selectedCity].ringNames;
      setRings(selectedRings);

      const selectedLinks = filtersData[selectedRegion][selectedCity].linkNames;
      setLinks(selectedLinks);

      const filteredSites = filtersData[selectedRegion][selectedCity].siteCodes.filter(
        site => filtersData[selectedRegion][selectedCity].siteCodes.includes(site)
      );
      setSites(filteredSites);
    }
  }, [ring]);

  const handleRegionChange = (selectedRegion) => {
    if (filtersData && filtersData[selectedRegion]) {
      // Update cities based on the selected region
      const selectedCities = Object.keys(filtersData[selectedRegion]);
      setCities(selectedCities);

      // Update rings and links based on the selected region
      const rings = selectedCities.flatMap(city => filtersData[selectedRegion][city].ringNames);
      const links = selectedCities.flatMap(city => filtersData[selectedRegion][city].linkNames);
      setRings(rings);
      setLinks(links);
    } else {
      // Reset cities, rings, and links to include all values if selectedRegion is not found
      const allRegions = Object.keys(filtersData);
      const allCities = allRegions.flatMap(region => Object.keys(filtersData[region]));
      const allRings = allRegions.flatMap(region =>
        Object.values(filtersData[region]).flatMap(city => city.ringNames)
      );
      const allLinks = allRegions.flatMap(region =>
        Object.values(filtersData[region]).flatMap(city => city.linkNames)
      );

      setCities(allCities);
      setRings(allRings);
      setLinks(allLinks);
    }
  };
  const handleCityChange = (selectedCity) => {
    if (valueTab === Generals.ftts) {
      if (selectedCity) {
        console.log('filtersDataftts', filtersData)

        const selectedRegion = Object.keys(filtersData).find(region => filtersData[region]?.hasOwnProperty(selectedCity));
        setRegion(selectedRegion);

        // Update rings and links based on the selected city
        const rings = filtersData[selectedRegion][selectedCity].ringNames;
        const links = filtersData[selectedRegion][selectedCity].linkNames;
        const sites = filtersData[selectedRegion][selectedCity].siteCodes;
        setRings(rings);
        setLinks(links);
        setSites(sites)
      }
      else {
        // Reset rings and links to include all values if selectedCity is not found
        const rings = Object.values(filtersData[region]).flatMap(city => city.ringNames);
        const links = Object.values(filtersData[region]).flatMap(city => city.linkNames);
        setRings(rings);
        setLinks(links);
      }
    }
    else if (valueTab === Generals.otn) {
      if (selectedCity) {
        // Find the region that contains the selected city
        const selectedRegion = Object.keys(filtersData).find(region =>
          filtersData[region] && filtersData[region][selectedCity]
        );

        if (selectedRegion) {
          setRegion(selectedRegion);

          // Get the sites (like GUJ3302, HSL3510) for the selected city
          const sites = Object.keys(filtersData[selectedRegion][selectedCity]);

          // Get the links for all sites within the selected city
          const links = sites.flatMap(site => filtersData[selectedRegion][selectedCity][site].linkNames);

          setSites(sites);
          setLinks(links);
        }
      } else {
        // Reset if no city is selected
        const allRegions = Object.keys(filtersData);
        const allCities = allRegions.flatMap(region => Object.keys(filtersData[region]));
        const allSites = allCities.flatMap(city =>
          Object.keys(filtersData[Object.keys(filtersData).find(region => filtersData[region].hasOwnProperty(city))][city])
        );
        const allLinks = allCities.flatMap(city =>
          Object.keys(filtersData[Object.keys(filtersData).find(region => filtersData[region].hasOwnProperty(city))][city])
            .flatMap(site => filtersData[Object.keys(filtersData).find(region => filtersData[region].hasOwnProperty(city))][city][site].linkNames)
        );

        setSites(allSites);
        setLinks(allLinks);
      }
    }

    else if (valueTab === Generals.mpbn) {
      if (selectedCity) {
        // Update links and sites based on the selected city
        const sites = Object.keys(filtersData[selectedCity]);
        const links = sites.flatMap(site => filtersData[selectedCity][site].linkNames);
        setSites(sites);
        setLinks(links);
      } else {
        // Reset links and sites to include all values if selectedCity is not found
        const allCities = Object.keys(filtersData);
        const allSites = allCities.flatMap(city => Object.keys(filtersData[city]));
        const allLinks = allCities.flatMap(city => Object.values(filtersData[city]).flatMap(site => site.linkNames));

        setSites(allSites);
        setLinks(allLinks);
      }
    }
  };

  const handleLinkChange = (selectedLink) => {
    if (valueTab === Generals.ftts) {
      if (selectedLink) {
        let selectedCity, selectedRegion, selectedRing;

        //  selectedRing=getRingName(null,selectedLink)
        // Find the city and region for the selected link
        for (const region in filtersData) {
          for (const city in filtersData[region]) {
            if (filtersData[region][city].linkNames.includes(selectedLink)) {
              selectedCity = city;
              selectedRegion = region;
              break;
            }
          }
          if (selectedCity) break; // Exit loop if city is found
        }

        // Update state with selected region and city
        setRegion(selectedRegion);
        setCity(selectedCity);
        setCities(Object.keys(filtersData[selectedRegion]));

        // Update rings and links based on the selected city
        const rings = filtersData[selectedRegion][selectedCity].ringNames;
        const links = filtersData[selectedRegion][selectedCity].linkNames.filter(
          link => link.includes(selectedLink)
        );
        setRings(rings);
        setLinks(links);

        // Filter sites based on selected link
        const filteredSites = filtersData[selectedRegion][selectedCity].siteCodes.filter(
          site => selectedLink.includes(site)
        );

        // Update sites with filtered results
        setSites(filteredSites);
        // setSite('');
      }
    }
    else if (valueTab === Generals.otn) {
      if (selectedLink) {
        let selectedRegion, selectedCity, selectedSite;

        // Find the region, city, and site for the selected link
        for (const region in filtersData) {
          for (const city in filtersData[region]) {
            for (const site in filtersData[region][city]) {
              if (filtersData[region][city][site].linkNames.includes(selectedLink)) {
                selectedRegion = region;
                selectedCity = city;
                selectedSite = site;
                break;
              }
            }
            if (selectedSite) break; // Exit loop if site is found
          }
          if (selectedCity) break; // Exit loop if city is found
        }

        // Update state with selected region, city, and site
        setRegion(selectedRegion);
        setCity(selectedCity);
        setCities(Object.keys(filtersData[selectedRegion]));
        setSites([selectedSite]);

        // Update links based on the selected site
        const links = filtersData[selectedRegion][selectedCity][selectedSite].linkNames;
        setLinks(links);
      } else {
        // Reset to include all values if selectedLink is not found
        const allRegions = Object.keys(filtersData);
        const allCities = allRegions.flatMap(region => Object.keys(filtersData[region]));
        const allSites = allRegions.flatMap(region =>
          Object.values(filtersData[region]).flatMap(city => Object.keys(city))
        );
        const allLinks = allRegions.flatMap(region =>
          Object.values(filtersData[region]).flatMap(city =>
            Object.values(city).flatMap(site => site.linkNames)
          )
        );

        setCities(allCities);
        setSites(allSites);
        setLinks(allLinks);
      }
    }
    else {
      if (selectedLink) {
        let selectedCity, selectedSite;

        // Find the city and site for the selected link
        for (const city in filtersData) {
          for (const site in filtersData[city]) {
            if (filtersData[city][site].linkNames.includes(selectedLink)) {
              selectedCity = city;
              selectedSite = site;
              break;
            }
          }
          if (selectedCity) break; // Exit loop if city is found
        }

        // Update state with selected city and site
        setCity(selectedCity);
        setSites([selectedSite]);

        // Update links based on the selected site
        const links = filtersData[selectedCity][selectedSite].linkNames;
        setLinks(links);
      } else {
        // Reset to include all values if selectedLink is not found
        const allCities = Object.keys(filtersData);
        const allSites = allCities.flatMap(city => Object.keys(filtersData[city]));
        const allLinks = allCities.flatMap(city => Object.values(filtersData[city]).flatMap(site => site.linkNames));

        setCities(allCities);
        setSites(allSites);
        setLinks(allLinks);
      }
    }
  };
  // const getRingName =async(siteName,linkName)=>{
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(`http://localhost:4000/ring-name`, {
  //       params: {
  //         siteName:siteName,
  //         linkName:linkName
  //       }
  //     }) ;
  //     setLoading(false);
  //     setRing(response?.data?.ring_name)
  //   }
  //   catch(error){
  //     console.log(error)
  //   }
  // }

  const handleSiteChange = async (selectedSite) => {
    if (valueTab === Generals.ftts) {
      if (selectedSite) {
        let selectedCity, selectedRegion, selectedRing;

        // selectedRing=getRingName(selectedSite)
        // Find the city and region for the selected site
        for (const region in filtersData) {
          for (const city in filtersData[region]) {
            if (filtersData[region][city].siteCodes.includes(selectedSite)) {
              selectedCity = city;
              selectedRegion = region;
              break;
            }
          }
          if (selectedCity) break; // Exit loop if city is found
        }

        // Update state with selected region and city
        setRegion(selectedRegion);
        setCity(selectedCity);
        // 
        setCities(Object.keys(filtersData[selectedRegion]));

        // Update rings and links based on the selected city
        const rings = filtersData[selectedRegion][selectedCity].ringNames;
        const links = filtersData[selectedRegion][selectedCity].linkNames.filter(
          link => link.includes(selectedSite)
        );
        setRings(rings);
        setLinks(links);

        // Filter sites based on selected site
        const filteredLinks = filtersData[selectedRegion][selectedCity].linkNames.filter(
          link => link.includes(selectedSite)
        );

        // Update links with filtered results
        setLinks(filteredLinks);
        setLink('');
      } else {
        // Reset to include all values if selectedSite is not found
        const allRegions = Object.keys(filtersData);
        const allCities = allRegions.flatMap(region => Object.keys(filtersData[region]));
        const allRings = allRegions.flatMap(region =>
          Object.values(filtersData[region]).flatMap(city => city.ringNames)
        );
        const allLinks = allRegions.flatMap(region =>
          Object.values(filtersData[region]).flatMap(city => city.linkNames)
        );

        setCities(allCities);
        setRings(allRings);
        setLinks(allLinks);
      }
    }
    else if (valueTab === Generals.mpbn) {

      if (selectedSite) {
        let selectedCity;

        // Find the city for the selected site
        for (const city in filtersData) {
          if (filtersData[city]?.hasOwnProperty(selectedSite)) {
            selectedCity = city;
            break;
          }
        }

        // Update state with selected city
        setCity(selectedCity);

        // Update links based on the selected site
        const links = filtersData[selectedCity][selectedSite].linkNames;
        setLinks(links);

        // Update sites to include only the selected site
        setSites([selectedSite]);
      } else {
        // Reset to include all values if selectedSite is not found
        const allCities = Object.keys(filtersData);
        const allSites = allCities.flatMap(city => Object.keys(filtersData[city]));
        const allLinks = allCities.flatMap(city => Object.values(filtersData[city]).flatMap(site => site.linkNames));

        setCities(allCities);
        setSites(allSites);
        setLinks(allLinks);
      }

    }
    else if (valueTab === Generals.otn) {
      if (selectedSite) {
        let selectedCity, selectedRegion;

        // Find the city and region for the selected site
        for (const region in filtersData) {
          for (const city in filtersData[region]) {
            if (filtersData[region][city].hasOwnProperty(selectedSite)) {
              selectedCity = city;
              selectedRegion = region;
              break;
            }
          }
          if (selectedCity) break; // Exit loop if city is found
        }

        // Update state with selected region and city
        setRegion(selectedRegion);
        setCity(selectedCity);

        // Set the cities based on the selected region
        setCities(Object.keys(filtersData[selectedRegion]));

        // Get the links related to the selected site
        const links = filtersData[selectedRegion][selectedCity][selectedSite].linkNames;

        // Update links with filtered results
        setLinks(links);

        // Set sites to include only the selected site
        setSites([selectedSite]);
      } else {
        // Reset to include all values if selectedSite is not found
        const allRegions = Object.keys(filtersData);
        const allCities = allRegions.flatMap(region => Object.keys(filtersData[region]));
        const allSites = allCities.flatMap(city =>
          Object.keys(filtersData[Object.keys(filtersData).find(region => filtersData[region].hasOwnProperty(city))][city])
        );
        const allLinks = allCities.flatMap(city =>
          Object.keys(filtersData[Object.keys(filtersData).find(region => filtersData[region].hasOwnProperty(city))][city])
            .flatMap(site => filtersData[Object.keys(filtersData).find(region => filtersData[region].hasOwnProperty(city))][city][site].linkNames)
        );

        setCities(allCities);
        setSites(allSites);
        setLinks(allLinks);
      }
    }
    else {

    }
  };
  const handleTabSelection = (tabValue) => {
    setValueTab(tabValue);
    sessionStorage.setItem('selectedTab', tabValue);
    // handleDrawerClose();
    navigate('/fibers')
  }



  // const handleLinkChange = (selectedLink) => {
  //   if (selectedLink) {
  //     let selectedCity, selectedRegion;
  //     // Find the city and region for the selected link
  //     for (const region in filtersData) {
  //       for (const city in filtersData[region]) {
  //         if (filtersData[region][city].linkNames.includes(selectedLink)) {
  //           selectedCity = city;
  //           selectedRegion = region;
  //           break;
  //         }
  //       }
  //       if (selectedCity) break; // Exit loop if city is found
  //     }

  //     // Update state with selected region and city
  //     setRegion(selectedRegion);
  //     setCity(selectedCity);
  //     setCities(Object.keys(filtersData[selectedRegion]));

  //     // Update rings and links based on the selected city
  //     const rings = filtersData[selectedRegion][selectedCity].ringNames;
  //     const links = filtersData[selectedRegion][selectedCity].linkNames;
  //     setRings(rings);
  //     setLinks(links);

  //     // Filter sites based on selected link
  //     const filteredSites = filtersData[selectedRegion][selectedCity].siteCodes.filter(
  //       site => filtersData[selectedRegion][selectedCity].linkNames.includes(selectedLink)
  //     );

  //     // Update sites with filtered results
  //     setSites(filteredSites);
  //     setSite('')
  //   }
  // };
  // const handleSiteChange = (selectedSite) => {
  //   if (selectedSite) {
  //     let selectedCity, selectedRegion;
  //     // Find the city and region for the selected site
  //     for (const region in filtersData) {
  //       for (const city in filtersData[region]) {
  //         if (filtersData[region][city].siteCodes.includes(selectedSite)) {
  //           selectedCity = city;
  //           selectedRegion = region;
  //           break;
  //         }
  //       }
  //       if (selectedCity) break; // Exit loop if city is found
  //     }

  //     // Update state with selected region and city
  //     setRegion(selectedRegion);
  //     setCity(selectedCity);
  //     setCities(Object.keys(filtersData[selectedRegion]));

  //     // Update rings and links based on the selected city (if needed)
  //     if (selectedCity) {
  //       const rings = filtersData[selectedRegion][selectedCity].ringNames;
  //       const links = filtersData[selectedRegion][selectedCity].linkNames;
  //       setRings(rings);
  //       setLinks(links);
  //     }
  //     setLink('')
  //   } else {
  //     // Reset to include all values if selectedSite is not found
  //     const allRegions = Object.keys(filtersData);
  //     const allCities = allRegions.flatMap(region => Object.keys(filtersData[region]));
  //     const allRings = allRegions.flatMap(region =>
  //       Object.values(filtersData[region]).flatMap(city => city.ringNames)
  //     );
  //     const allLinks = allRegions.flatMap(region =>
  //       Object.values(filtersData[region]).flatMap(city => city.linkNames)
  //     );

  //     setCities(allCities);
  //     setRings(allRings);
  //     setLinks(allLinks);
  //   }
  // };
  const handleApplyChanges = (regionValue) => {
    if (regionValue) {
      setFiltersValue({ region: regionValue, city, ring, link, site })
      if (regionValue || city || ring || link || site) {
        setApplyFilters(true);
      }
      return
    }
    setFiltersValue({ region, city, ring, link, site })
    if (region || city || ring || link || site) {
      setApplyFilters(true);
    }
  }
  const handleReset = () => {
    { console.log('intial cities', initialFilters.city) }
    { console.log('initialSites', initialFilters.site) }
    { console.log('initialLinks', initialFilters.link) }
    { console.log('intial Region', initialFilters.region) }

    if (region || city || ring || link || site) {
      setFiltersValue({ region: '', city: '', ring: '', link: '', site: '' })
      setApplyFilters(false);
      setRegion('')
      setCity('')
      setRing('')
      setLink('')
      setSite('')
      setRings(initialFilters.ring);
      setCities(initialFilters.city);
      setRegions(initialFilters.region);
      setLinks(initialFilters.link)
      setSites(initialFilters.site);
      if (markerRef.current) {
        markerRef.current.remove();
        markerRef.current = null;
      }
    }
  };
  const list = (anchor) => (
    <Box
      sx={{
        // width: 300, bottom: 0,
        // height: '91vh !important',
        // display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        display: 'flex',
      }}
      role="presentation"
    >
      <Box sx={{
        // p: 2,
        gap: 1, justifyContent: 'left'
      }}>
        {/* <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }} >
          <Typography sx={{
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 600,
          }}
          >
            Filters
          </Typography>
          <CancelIcon sx={{
            cursor: 'pointer'
          }}
            onClick={toggleDrawer('right', false)}
          />
        </Box> */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: 1,
          justifyContent: 'space-between',
          marginLeft: '1%'

        }} >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            // mt: 8
          }}>
            {/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search> */}
            {(sessionStorage.getItem('selectedTab') === 'ftts' || sessionStorage.getItem('selectedTab') === 'otn') &&
              <Autocomplete
                disablePortal
                id="region"
                options={regions}
                classes={classes}
                clearOnEscape={false}
                size="small"
                value={region}
                PopperComponent={CustomPopper}
                sx={{
                  width: 170, // Fixed width for the Autocomplete component
                  color: '#fff',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent', // Initial border color
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent', // Hover border color
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent', // Focused border color
                    },
                  },
                  '.MuiAutocomplete-input': {
                    color: '#fff',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    paddingLeft: '5px', // Reduce padding to use space efficiently
                    paddingRight: '5px',
                    fontSize: '12px', // Adjust font size to fit text within 130px
                  },
                  '.MuiAutocomplete-endAdornment': {
                    display: 'none',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#fff',
                    fontSize: '11px', // Adjust label size to fit within smaller width
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#fff',
                  },
                }}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setRegion(newValue);
                    setCity('');
                    setRing('');
                    handleRegionChange(newValue);
                  }
                }}
                PopoverProps={{
                  sx: { zIndex: (theme) => theme.zIndex.modal + 1 },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Region"
                    sx={{ background: '#3A474F', borderRadius: '5px', width: '100%' }} // Ensures input fills the width
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: '12px' } // Ensure font fits within the fixed width
                    }}
                  />
                )}
              />


            }

            <Autocomplete
              disablePortal
              id="city"
              options={cities}
              size='small'
              value={city}
              clearOnEscape={false}
              PopperComponent={CustomPopper}
              classes={classes}
              onChange={(event, newValue) => { if (newValue !== null) { setCity(newValue); setRing(''); handleCityChange(newValue) } }}
              sx={{
                width: 160, // Fixed width for the Autocomplete component
                color: '#fff',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent', // Initial border color
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent', // Hover border color
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent', // Focused border color
                  },
                },
                '.MuiAutocomplete-input': {
                  color: '#fff',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  paddingLeft: '5px', // Reduce padding to use space efficiently
                  paddingRight: '5px',
                  fontSize: '12px', // Adjust font size to fit text within 130px
                },
                '.MuiAutocomplete-endAdornment': {
                  display: 'none',
                },
                '& .MuiInputLabel-root': {
                  color: '#fff',
                  fontSize: '11px', // Adjust label size to fit within smaller width
                },
                '& .MuiSvgIcon-root': {
                  color: '#fff',
                },
              }}
              PopoverProps={{
                sx: { zIndex: (theme) => theme.zIndex.modal + 1 }
              }}
              renderInput={(params) => <TextField {...params} label="City" sx={{ background: '#3A474F', borderRadius: '5px' }} />}
            />
            {sessionStorage.getItem('selectedTab') === 'ftts' &&
              <Autocomplete
                disablePortal
                id="ring"
                options={rings}
                PopperComponent={CustomPopper}
                clearOnEscape={false}
                size='small'
                value={ring}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setRing(newValue);
                  }
                }}
                sx={{
                  width: 170, // Fixed width for the Autocomplete component
                  color: '#fff',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent', // Initial border color
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent', // Hover border color
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent', // Focused border color
                    },
                  },
                  '.MuiAutocomplete-input': {
                    color: '#fff',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    paddingLeft: '5px', // Reduce padding to use space efficiently
                    paddingRight: '5px',
                    fontSize: '12px', // Adjust font size to fit text within 130px
                  },
                  '.MuiAutocomplete-endAdornment': {
                    display: 'none',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#fff',
                    fontSize: '11px', // Adjust label size to fit within smaller width
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#fff',
                  },
                }}
                PopoverProps={{
                  sx: { zIndex: (theme) => theme.zIndex.modal + 1 }
                }}
                renderInput={(params) => <TextField {...params} label="Ring" sx={{ background: '#3A474F', color: '#fff', borderRadius: '5px' }} />}
              />}
            <Autocomplete
              key={sites?.join(',')}
              disablePortal
              id="site"
              options={sites}
              PopperComponent={CustomPopper}
              clearOnEscape={false}
              size='small'
              value={site}
              onChange={(event, newValue) => { if (newValue !== null) { setSite(newValue); handleSiteChange(newValue) } }}
              sx={{
                width: 130, // Fixed width for the Autocomplete component
                color: '#fff',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent', // Initial border color
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent', // Hover border color
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent', // Focused border color
                  },
                },
                '.MuiAutocomplete-input': {
                  color: '#fff',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  paddingLeft: '5px', // Reduce padding to use space efficiently
                  paddingRight: '5px',
                  fontSize: '12px', // Adjust font size to fit text within 130px
                },
                '.MuiAutocomplete-endAdornment': {
                  display: 'none',
                },
                '& .MuiInputLabel-root': {
                  color: '#fff',
                  fontSize: '11px', // Adjust label size to fit within smaller width
                },
                '& .MuiSvgIcon-root': {
                  color: '#fff',
                },
              }}
              PopoverProps={{
                sx: { zIndex: (theme) => theme.zIndex.modal + 1 }
              }}
              renderInput={(params) => <TextField {...params} label="Site" sx={{ background: '#3A474F', color: '#fff', borderRadius: '5px' }} />}
            />
            <Autocomplete
              disablePortal
              id="link"
              options={links}
              PopperComponent={CustomPopper}
              clearOnEscape={false}
              size='small'
              value={link}
              onChange={(event, newValue) => { if (newValue !== null) { setLink(newValue); handleLinkChange(newValue) } }}
              sx={{
                width: 170, // Fixed width for the Autocomplete component
                color: '#fff',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                  },
                },
                '.MuiAutocomplete-input': {
                  color: '#fff',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  fontSize: '12px',
                },
                '.MuiAutocomplete-endAdornment': {
                  display: 'none',
                },
                '& .MuiInputLabel-root': {
                  color: '#fff',
                  fontSize: '11px',
                },
                '& .MuiSvgIcon-root': {
                  color: '#fff',
                },
              }}
              PopoverProps={{
                sx: { zIndex: (theme) => theme.zIndex.modal + 1 }
              }}
              renderOption={(props, option) => (
                <li {...props} title={option}>
                  <div style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '150px' // Restrict the max width of the dropdown options
                  }}>
                    {option}
                  </div>
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Link" sx={{ background: '#3A474F', color: '#fff', borderRadius: '5px' }} />}
            />

            <Button variant="contained" sx={{
              backgroundColor: '#3A474F',
              fontSize: '11px',
              mr: 1,
              '&:hover': {
                border: '1px solid #bd1d23',
                backgroundColor: '#bd1d23',
                color: 'white',
                boxShadow: 'inset 0 0 10px #bd1d23',
                fontSize: '11px'
              }
            }}
              className='table-icon'
              // onClick={() => {
              //   setFiltersValue({ region: '', city: '', ring: '' })
              //   setApplyFilters(false);
              //   setRegion('')
              //   setCity('')
              //   setRing('')
              // }}
              onClick={handleReset}
            >Reset</Button>
            <Button variant="contained" sx={{
              backgroundColor: '#3A474F',
              fontSize: '11px',
              '&:hover': {
                border: '1px solid #bd1d23',
                fontSize: '11px',
                backgroundColor: '#bd1d23',
                color: 'white',
                boxShadow: 'inset 0 0 10px #bd1d23'
              }
            }}
              className='table-icon'
              onClick={() => handleApplyChanges()}
            >Apply</Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        // justifyContent: 'end',
        flexDirection: 'row',
        gap: '6px',
        // marginRight:'20%'
        // mt: 4,
        // p: 1
      }}>
        {/* <div style={{ backgroundColor: "#3A474F", width: "100%", height: "1px" }}>
        </div> */}
        {/* <div style={{ display: 'flex',  flexDirection: 'row', marginTop: "10px" }}> */}
        {/* <Button variant="contained"  sx={{
            backgroundColor: '#3A474F',
            mr:1,
            '&:hover': {
              border: '1px solid #bd1d23',
              backgroundColor: '#bd1d23',
              color: 'white',
              boxShadow: 'inset 0 0 10px #bd1d23'
            }
          }}
          className='table-icon'
            onClick={() => {
              setFiltersValue({ region: '', city: '', ring: '' })
              setApplyFilters(false);
              setRegion('')
              setCity('')
              setRing('')
            }}
          >Reset</Button>
          <Button variant="contained" sx={{
            backgroundColor: '#3A474F',
            '&:hover': {
              border: '1px solid #bd1d23',
              backgroundColor: '#bd1d23',
              color: 'white',
              boxShadow: 'inset 0 0 10px #bd1d23'
            }
          }}
          className='table-icon'
            onClick={() => {
              setFiltersValue({ region, city, ring })
              if (region || city || ring) {
                setApplyFilters(true);
              }
            }}
          >Apply</Button> */}
        {/* </div> */}
      </Box>
    </Box>
  );
  return (
    <>
      <TopHeader totalAlarms={data} value={valueTab} handleReset={handleReset} setValue={setValueTab} handleDrawerClose={handleDrawerClose} toggleDrawer={toggleDrawer} state={state} applyFilters={applyFilters} list={list} />
      {
        open !== true && (
          <>
            {/* For left Bar */}
            <Grid container>
              <Grid item xs={0.5}> </Grid>
              <Grid item xs={11.5}>
                <Box sx={{
                  position: 'absolute',
                  top: '40%',
                  // left: '78px',
                  //  left: 0,
                  zIndex: 999,
                  cursor: 'pointer'
                }}
                  onClick={handleDrawerOpen}
                >
                  <img alt='' src={alertButton} style={{ width: '43px', height: '152px' }} />
                </Box>
                {/* For left Bar */}

              </Grid>
            </Grid>
          </>
        )
      }
      {/* For left Bar */}

      <Grid container>
        <Grid
          item
          xs={0.5}
          borderRight={'1px solid #ccc'}
          sx={{
            height: 'auto',
            // display: 'flex',
            // flexDirection: 'column',
            alignItems: 'center',
            // justifyContent: 'flex-end', // Align content at the bottom
          }}
        >
          <MianNavBarLeft handleReset={handleReset} handleTabSelection={handleTabSelection} handleDrawerClose={handleDrawerClose} />
        </Grid>
        <Grid item xs={11.5}>
          <Box sx={{ flexGrow: 1, p: 0 }} >
            <Grid container spacing={0}>
              {
                open === true && (
                  <Grid className={`alert_notification_grid ${open ? "open_alert_drawer" : "close_alert_drawer"} `}>
                    <div className='alerts_main'>
                      <div className='alerts_header'>
                        <div className='alerts_title_icon'>
                          <img alt='' src={alert} />
                          <h3> Alert Notification </h3>
                        </div>
                        <button className='crossButton' onClick={handleDrawerClose}>
                          <svg width="10" height="10" style={{ paddingTop: '5px' }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.77778 5.9979L11.6352 2.14181C12.1216 1.6556 12.1216 0.850855 11.6352 0.364652C11.1489 -0.121551 10.3438 -0.121551 9.85745 0.364652L6 4.22075L2.14255 0.364652C1.65618 -0.121551 0.851153 -0.121551 0.36478 0.364652C-0.121593 0.850855 -0.121593 1.6556 0.36478 2.14181L4.22222 5.9979L0.36478 9.854C-0.121593 10.3402 -0.121593 11.145 0.36478 11.6312C0.616353 11.8826 0.93501 12 1.25366 12C1.57232 12 1.89098 11.8826 2.14255 11.6312L6 7.77506L9.85745 11.6312C10.109 11.8826 10.4277 12 10.7463 12C11.065 12 11.3836 11.8826 11.6352 11.6312C12.1216 11.145 12.1216 10.3402 11.6352 9.854L7.77778 5.9979Z" fill="#BD1D23" />
                          </svg>
                        </button>
                      </div>
                      <ActiveAlarmsCard data={data} handleReset={handleReset} handleApplyChanges={handleApplyChanges} value={valueTab} loading={loading} totalAlarms={totalAlarms} setTotalAlarms={setTotalAlarms} setSelectedAlarm={setSelectedAlarm} setFiltersValue={setFiltersValue} filtersValue={filtersValue} setRing={setRing} setCity={setCity} setRegion={setRegion} alarmType={alramType} />
                    </div>
                  </Grid>
                )
              }

              {/* <Grid  item xs={11}> */}
              <div className={`alert_notification_grid ${open ? "open_alert_content" : "close_alert_content"} `}>
                {/* {
              viewType === 'graph' ? sessionStorage.getItem('selectedTab') === 'ftts' ?
                <MapComponent
                  setViewType={setViewType}
                  data={data}
                  markerRef={markerRef}
                  regionData={regionData}
                  viewType={viewType}
                  state={state}
                  loading={loading}
                  selectedAlarm={selectedAlarm}
                  ring={filtersValue?.ring}
                  site={filtersValue?.site}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  region={filtersValue?.region}
                  link={filtersValue?.link}
                  city={filtersValue?.city}
                  setVideoPlayTime={setVideoPlayTime}
                  setVideoPlayTimeEnd={setVideoPlayTimeEnd}
                  alramType={alramType}
                  setAlramType={setAlramType}
                  open={open}
                  toggleDrawer={toggleDrawer}
                  applyFilters={applyFilters}
                /> : 
                <MapMpbn setViewType={setViewType}
                  data={data}
                  regionData={regionData}
                  markerRef={markerRef}
                  viewType={viewType}
                  state={state}
                  loading={loading}
                  selectedAlarm={selectedAlarm}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  ring={filtersValue?.ring}
                  region={filtersValue?.region}
                  city={filtersValue?.city}
                  site={filtersValue?.site}
                  link={filtersValue?.link}
                  setVideoPlayTime={setVideoPlayTime}
                  setVideoPlayTimeEnd={setVideoPlayTimeEnd}
                  alramType={alramType}
                  setAlramType={setAlramType}
                  open={open}
                  toggleDrawer={toggleDrawer}
                  applyFilters={applyFilters} />
                : <ActiveAlarmsTable
                  viewType={viewType}
                  setViewType={setViewType}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  data={data}
                  state={state}
                  loading={loading}
                  selectedAlarm={selectedAlarm}
                  ring={filtersValue?.ring}
                  region={filtersValue?.region}
                  city={filtersValue?.city}
                  setVideoPlayTime={setVideoPlayTime}
                  setVideoPlayTimeEnd={setVideoPlayTimeEnd}
                  alramType={alramType}
                  setAlramType={setAlramType}
                  open={open}
                  toggleDrawer={toggleDrawer}
                  applyFilters={applyFilters}
                />
            } */}
                {
                  viewType === 'graph' ? (
                    sessionStorage.getItem('selectedTab') === 'ftts' ? (
                      <MapComponent
                        setViewType={setViewType}
                        data={data}
                        handleReset={handleReset}
                        videoPlayTime={videoPlayTime}
                        markerRef={markerRef}
                        regionData={regionData}
                        viewType={viewType}
                        state={state}
                        loading={loading}
                        selectedAlarm={selectedAlarm}
                        ring={filtersValue?.ring}
                        site={filtersValue?.site}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        region={filtersValue?.region}
                        link={filtersValue?.link}
                        city={filtersValue?.city}
                        setVideoPlayTime={setVideoPlayTime}
                        setVideoPlayTimeEnd={setVideoPlayTimeEnd}
                        alramType={alramType}
                        setAlramType={setAlramType}
                        open={open}
                        toggleDrawer={toggleDrawer}
                        applyFilters={applyFilters}
                      />
                    ) : sessionStorage.getItem('selectedTab') === 'mpbn' ? (
                      <MapMpbn
                        setViewType={setViewType}
                        data={data}
                        regionData={regionData}
                        handleReset={handleReset}
                        markerRef={markerRef}
                        viewType={viewType}
                        state={state}
                        loading={loading}
                        selectedAlarm={selectedAlarm}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        ring={filtersValue?.ring}
                        region={filtersValue?.region}
                        city={filtersValue?.city}
                        site={filtersValue?.site}
                        link={filtersValue?.link}
                        setVideoPlayTime={setVideoPlayTime}
                        setVideoPlayTimeEnd={setVideoPlayTimeEnd}
                        alramType={alramType}
                        setAlramType={setAlramType}
                        open={open}
                        toggleDrawer={toggleDrawer}
                        applyFilters={applyFilters}
                      />
                    ) : sessionStorage.getItem('selectedTab') === 'otn' ? (
                      <OtnMap
                        handleReset={handleReset}
                        setViewType={setViewType}
                        data={data}
                        regionData={regionData}
                        markerRef={markerRef}
                        viewType={viewType}
                        state={state}
                        loading={loading}
                        selectedAlarm={selectedAlarm}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        ring={filtersValue?.ring}
                        region={filtersValue?.region}
                        city={filtersValue?.city}
                        site={filtersValue?.site}
                        link={filtersValue?.link}
                        setVideoPlayTime={setVideoPlayTime}
                        setVideoPlayTimeEnd={setVideoPlayTimeEnd}
                        alramType={alramType}
                        setAlramType={setAlramType}
                        open={open}
                        toggleDrawer={toggleDrawer}
                        applyFilters={applyFilters}
                      />
                    ) : sessionStorage.getItem('selectedTab') === 'ipran' ? (
                      <IpranMap
                        handleReset={handleReset}
                        setViewType={setViewType}
                        data={data}
                        regionData={regionData}
                        markerRef={markerRef}
                        viewType={viewType}
                        state={state}
                        loading={loading}
                        selectedAlarm={selectedAlarm}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        ring={filtersValue?.ring}
                        region={filtersValue?.region}
                        city={filtersValue?.city}
                        site={filtersValue?.site}
                        link={filtersValue?.link}
                        setVideoPlayTime={setVideoPlayTime}
                        setVideoPlayTimeEnd={setVideoPlayTimeEnd}
                        alramType={alramType}
                        setAlramType={setAlramType}
                        open={open}
                        toggleDrawer={toggleDrawer}
                        applyFilters={applyFilters}
                      />
                    ) : (
                      // Default component if none of the above conditions match
                      <ActiveAlarmsTable
                        viewType={viewType}
                        setViewType={setViewType}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        data={data}
                        state={state}
                        loading={loading}
                        selectedAlarm={selectedAlarm}
                        ring={filtersValue?.ring}
                        region={filtersValue?.region}
                        city={filtersValue?.city}
                        setVideoPlayTime={setVideoPlayTime}
                        setVideoPlayTimeEnd={setVideoPlayTimeEnd}
                        alramType={alramType}
                        setAlramType={setAlramType}
                        open={open}
                        toggleDrawer={toggleDrawer}
                        applyFilters={applyFilters}
                      />
                    )
                  ) : (
                    // Fallback when viewType is not 'graph'
                    <ActiveAlarmsTable
                      viewType={viewType}
                      setViewType={setViewType}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      data={data}
                      state={state}
                      loading={loading}
                      selectedAlarm={selectedAlarm}
                      ring={filtersValue?.ring}
                      region={filtersValue?.region}
                      city={filtersValue?.city}
                      setVideoPlayTime={setVideoPlayTime}
                      setVideoPlayTimeEnd={setVideoPlayTimeEnd}
                      alramType={alramType}
                      setAlramType={setAlramType}
                      open={open}
                      toggleDrawer={toggleDrawer}
                      applyFilters={applyFilters}
                    />
                  )
                }

              </div>
              {/* </Grid>
              </Grid> */}
              {/* <Drawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
            PaperProps={{
              sx: {
                height: 'calc(100% - 66px)',
                top: 66,
              },
            }}
          >
            {list('right')}
          </Drawer> */}
            </Grid>
          </Box>
          {/* For left Bar */}

        </Grid>
      </Grid>
    </>
  );
}