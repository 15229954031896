export const convertIsoToString =(isoDateString)=> {
    const date = new Date(isoDateString);
    
    // Convert the date to a readable string format
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    //   hour: 'numeric',
    //   minute: 'numeric',
    //   second: 'numeric',
    //   timeZoneName: 'short'
    });
  }