
import Peshawar from '../../Assets/MapIcons/Peshawar.jpg'
import Shekhupura from '../../Assets/MapIcons/Shekhupura.jpg'
import Gujrat from '../../Assets/MapIcons/Gujrat.jpg'
import Faisalabad from '../../Assets/MapIcons/Faisalabad.jpg'
import Islamabad from '../../Assets/MapIcons/Islamabad.jpg'
import Karachi from '../../Assets/MapIcons/Karachi.jpg'
import Lahore from '../../Assets/MapIcons/Lahore.jpg'
import Taxila from '../../Assets/MapIcons/Taxila.jpg'
import Abbottabad from '../../Assets/MapIcons/Abbottabad.jpg'
import Shahdara from '../../Assets/MapIcons/Shahdara.jpg'
import Sahiwal from '../../Assets/MapIcons/Sahiwal.jpg'
import JEHLUM from '../../Assets/MapIcons/JEHLUM.jpg'
import Okara from '../../Assets/MapIcons/Okara.jpg'
import Kasur from '../../Assets/MapIcons/Kasur.jpg'
import Hasilpur from '../../Assets/MapIcons/Hasilpur.jpg'
import Rawalpindi from '../../Assets/MapIcons/Rawalpindi.jpg'
import Jhang from '../../Assets/MapIcons/Jhang.jpg'
import Bahawalnagar from '../../Assets/MapIcons/Bahawalnagar.jpg'
import Layyah from '../../Assets/MapIcons/Layyah.jpg'
import Multan from '../../Assets/MapIcons/Multan.jpg'
import Raiwind from '../../Assets/MapIcons/Raiwind.jpg'
import Bahawalpur from '../../Assets/MapIcons/Bahawalpur.jpg'
import DeraGhzaiKhan from '../../Assets/MapIcons/DeraGhazikhan.jpg'
import Sialkot from '../../Assets/MapIcons/Sialkot.jpg'
import Gujranwala from '../../Assets/MapIcons/Gujranwala.jpg'
import Sargodha from '../../Assets/MapIcons/Sargodha.jpg'
import Sukkur from '../../Assets/MapIcons/Sukkur.jpg'
import Hyderabad from '../../Assets/MapIcons/Hyderabad.jpg'
import Mardan from '../../Assets/MapIcons/Mardan.jpg'

export const getImageUrl = (name) => {
    switch (name) {
        case 'Peshawar':
            return Peshawar;
        case 'Sheikhupura':
            return Shekhupura;
        case 'Gujrat':
            return Gujrat;
        case 'Faisalabad':
            return Faisalabad;
        case 'Islamabad':
            return Islamabad;
        case 'Karachi':
            return Karachi;
        case 'Lahore':
            return Lahore;
        case 'Taxila':
            return Taxila;
        case 'Abbottabad':
            return Abbottabad;
        case 'Rawalpindi':
            return Rawalpindi;
        case 'Hasilpur':
            return Hasilpur;
        case 'Kasur':
            return Kasur;
        case 'Okara':
            return Okara;
        case 'JEHLUM':
            return JEHLUM;
        case 'Sahiwal':
            return Sahiwal;
        case 'Shahdara':
            return Shahdara;
        case 'Jhang':
            return Jhang;
        case 'Bahawalnagar':
            return Bahawalnagar;
        case 'Layyah':
            return Layyah;
        case 'Raiwind':
            return Raiwind;
        case 'Multan':
            return Multan;
        case 'Dera Ghazi Khan':
            return DeraGhzaiKhan;
        case 'Bahawalpur':
            return Bahawalpur;
        case 'Sargodha':
            return Sargodha;
        case 'Gujranwala':
            return Gujranwala;
        case 'Sialkot':
            return Sialkot;
        case 'Sukkur':
            return Sukkur;
        case 'Hyderabad':
            return Hyderabad;
        case 'Mardan':
            return Mardan;
        // Add more cases as needed
        default:
            return '';
    }
};