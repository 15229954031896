import React,{useState} from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as FttsIcon } from '../../Assets/AlertIcon/ftts.svg';
import { ReactComponent as Mpbn } from '../../Assets/AlertIcon/MPBN.svg';
import { ReactComponent as Otn } from '../../Assets/AlertIcon/OTN.svg';
import { ReactComponent as IPRAN } from '../../Assets/AlertIcon/IPRAN.svg';
import { useNavigate } from 'react-router-dom';
const QuickLinkButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.7)', // Transparent black background
  color: '#ffb400',
  fontWeight: 'bold',
  textAlign: 'left',
  padding: '16px 20px',
  textTransform: 'none',
  borderRadius: '8px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  gap: '10px', // Space between icon and text
  transition: 'background 0.3s ease', // Smooth transition for hover effect
  '&:hover': {
    background: 'linear-gradient(to right, #bd1d23, #eed51b)', // Gradient on hover
    color: '#202020',
  },
  '&.selected': {
    backgroundColor: '#ffb400',
    color: '#202020',
  },
}));

const QuickLinkTextButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.7)', // Transparent black background
  color: '#ffb400',
  fontWeight: 'bold',
  textAlign: 'left',
  padding: '16px 20px',
  textTransform: 'none',
  borderRadius: '8px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  gap: '10px', // Space between icon and text
  '&:hover': {
    background: 'transparent', // No change on hover
    color: '#ffb400',
  },
}));

const QuickLinks = () => {
  const navigate=useNavigate()

    const [tab,setTab]=useState();
    const handleTabs =(tab)=>{
      setTab(tab)
      if(tab === 'FTTS'){
        sessionStorage.setItem('selectedTab', 'ftts');
        navigate('/fibers');
      }
      if(tab === 'MPBN'){
        sessionStorage.setItem('selectedTab', 'mpbn');
        navigate('/fibers')
      }
    }

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '6px',
          height: '100%',
          background: 'linear-gradient(to bottom, #eed51b, #bd1d23)',
          borderRadius: '8px 0 0 8px', // Match border radius if necessary
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} textAlign={'justify'}>
          <QuickLinkTextButton>
            <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>Quick Links</Typography>
          </QuickLinkTextButton>
        </Grid>
        <Grid item xs={4} sm={4} sx={{ backgroundColor: 'black' }}>
          <QuickLinkButton>
            <FttsIcon style={{ width: '24px', height: '24px' }} />
            <Typography>FTTS</Typography>
          </QuickLinkButton>
        </Grid>
        <Grid item xs={4} sm={4} sx={{ backgroundColor: 'black' }}>
          <QuickLinkButton>
            <Mpbn style={{ width: '24px', height: '24px' }} />
            <Typography>MPBN</Typography>
          </QuickLinkButton>
        </Grid>
        <Grid item xs={4} sm={4} sx={{ backgroundColor: 'black' }}>
          <QuickLinkButton>
            <Otn style={{ width: '24px', height: '24px' }} />
            <Typography>OTN</Typography>
          </QuickLinkButton>
        </Grid>
        <Grid item xs={4} sm={4} sx={{ backgroundColor: 'black' }}>
          <QuickLinkButton>
            <IPRAN style={{ width: '24px', height: '24px' }} />
            <Typography>IPRAN</Typography>
          </QuickLinkButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuickLinks;
