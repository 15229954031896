// import React, { useState, useEffect } from "react";
// import {
//   Dialog,
//   DialogContent,
//   DialogActions,
//   Typography,
//   Chip,
//   Select,
//   MenuItem,
//   TextField,
//   List,
//   ListItem,
//   Checkbox,
//   Button,
//   Box,
//   FormControl,
//   InputLabel,
//   OutlinedInput
// } from "@mui/material";
// import { notify } from "../../common/functions/react-toastify.function";
// import { useBackdrop } from "../../common/components/backDrop_context.component";
// import { assignBulkUserToGroups } from "./userManagementFunctions/bulk_group_assign_to_user.function";
// import MultipleSelect from "../../common/components/multiple_select.component";
// const AssignBulkGroupToUserModal = ({ open, handleClose, users, groups, fetchGroups, fetchUsers }) => {
//   console.log('gggggg', groups)
//   const [selectedGroups, setSelectedGroups] = useState([
//     "Management",
//     "C-Level Leadership",
//   ]);
//   const { showBackdrop, hideBackdrop } = useBackdrop();

//   const [selectedUsers, setSelectedUsers] = useState([]);
//   const [selectedUserIds, setSelectedUserIds] = useState([]);
//   const [selectedGroupIds, setSelectedGroupIds] = useState([]);

//   const handleCheckboxChange = (userId) => {
//     console.log('iddd', userId)

//     setSelectedUserIds((prevSelectedIds) => {
//       if (prevSelectedIds.includes(userId)) {
//         return prevSelectedIds.filter((id) => id !== userId);
//       } else {
//         return [...prevSelectedIds, userId];
//       }
//     });
//   };
//   useEffect(() => {
//     fetchGroups()
//   }, [])

//   const handleUserSelect = (user) => {
//     setSelectedUsers((prevSelected) =>
//       prevSelected.includes(user)
//         ? prevSelected.filter((u) => u !== user)
//         : [...prevSelected, user]
//     );
//   };
//   const handleBulkUpdateAdd = async () => {
//     showBackdrop();
//     const updateBulkObj = {
//       user_ids: selectedUserIds,
//       group_ids: selectedGroupIds,
//     };
//     try {
//       const resp = await assignBulkUserToGroups(updateBulkObj);
//       console.log('updating response', resp)
//       if (resp) notify('success', 'Groups Assigned Successfully');
//       await fetchUsers();
//       handleClearAll();
//     } catch (err) {
//       notify('error', err?.response?.data?.message || err.message || 'Something went wrong');
//       console.error('Error updating permissions:', err);
//     } finally {
//       hideBackdrop();
//     }
//   };
//   const handleClearAll = () => {
//     setSelectedUserIds([])
//     setSelectedGroupIds([])
//     handleClose()
//   }

//   return (
//     <Dialog open={open} onClose={handleClearAll} fullWidth maxWidth="sm">
//       <DialogContent>
//         {/* Header */}
//         <Box display="flex" justifyContent="space-between" alignItems="center">
//           <Typography variant="h6">Assign Group</Typography>
//           <Box display="flex" gap={1}>
//             <MultipleSelect group={groups} setSelectedGroupIds={setSelectedGroupIds} selectedGroupIds={selectedGroupIds} />
//           </Box>
//         </Box>

//         <TextField
//           variant="outlined"
//           fullWidth
//           placeholder="Search Users"
//           margin="normal"
//           InputProps={{
//             startAdornment: <i className="material-icons">search</i>,
//           }}
//         />

//         <List>
//           {users?.map((user) => (
//             <ListItem key={user} onClick={() => handleCheckboxChange(user.user_id)}>
//               <Checkbox color="error" checked={selectedUserIds.includes(user.user_id)} />
//               <Typography>{user.user_name}</Typography>
//             </ListItem>
//           ))}
//         </List>
//       </DialogContent>

//       {/* Actions */}
//       <DialogActions>
//         <Button variant="contained" style={{ backgroundColor: '#BD1D23' }} sx={{
//           backgroundColor: '#BD1D23',
//           '&:hover': {
//             backgroundColor: '#BD1D23', // Ensure the hover color remains the same
//           },
//           '&:disabled': {
//             backgroundColor: '#E57373',
//             color: '#fff',
//           },
//         }} onClick={() => handleBulkUpdateAdd()}
//           disabled={!selectedGroupIds.length || !selectedUserIds.length}
//         >
//           ADD
//         </Button>
//         <Button variant="outlined" onClick={handleClearAll} color="secondary" style={{ marginRight: '8px', backgroundColor: '#FFF', color: '#000' }}>
//           CANCEL
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default AssignBulkGroupToUserModal;




import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Chip,
  Select,
  MenuItem,
  TextField,
  List,
  ListItem,
  Checkbox,
  Button,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { notify } from "../../common/functions/react-toastify.function";
import { useBackdrop } from "../../common/components/backDrop_context.component";
import { assignBulkUserToGroups } from "./userManagementFunctions/bulk_group_assign_to_user.function";
import MultipleSelect from "../../common/components/multiple_select.component";

const AssignBulkGroupToUserModal = ({ open, handleClose, users, groups, fetchGroups, fetchUsers }) => {
  const [selectedGroups, setSelectedGroups] = useState(["Management", "C-Level Leadership"]);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State to store search input

  useEffect(() => {
    fetchGroups();
  }, []);

  const handleCheckboxChange = (userId) => {
    setSelectedUserIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(userId)) {
        return prevSelectedIds.filter((id) => id !== userId);
      } else {
        return [...prevSelectedIds, userId];
      }
    });
  };

  const handleBulkUpdateAdd = async () => {
    showBackdrop();
    const updateBulkObj = {
      user_ids: selectedUserIds,
      group_ids: selectedGroupIds,
    };
    try {
      const resp = await assignBulkUserToGroups(updateBulkObj);
      if (resp) notify("success", "Groups Assigned Successfully");
      await fetchUsers();
      handleClearAll();
    } catch (err) {
      notify("error", err?.response?.data?.message || err.message || "Something went wrong");
    } finally {
      hideBackdrop();
    }
  };

  const handleClearAll = () => {
    setSelectedUserIds([]);
    setSelectedGroupIds([]);
    handleClose();
  };

  const filteredUsers = users?.filter((user) =>
    user.user_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Dialog open={open} onClose={handleClearAll} fullWidth maxWidth="sm">
      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Assign Group</Typography>
          <Box display="flex" gap={1}>
            <MultipleSelect
              group={groups}
              setSelectedGroupIds={setSelectedGroupIds}
              selectedGroupIds={selectedGroupIds}
            />
          </Box>
        </Box>

        <TextField
          variant="outlined"
          fullWidth
          placeholder="Search Users"
          margin="normal"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} // Update search query on change
          // InputProps={{
          //   startAdornment: <i className="material-icons">search</i>,
          // }}
        />

        <List>
          {filteredUsers?.map((user) => (
            <ListItem key={user.user_id} onClick={() => handleCheckboxChange(user.user_id)}>
              <Checkbox color="error" checked={selectedUserIds.includes(user.user_id)} />
              <Typography>{user.user_name}</Typography>
            </ListItem>
          ))}
        </List>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#BD1D23",
            "&:hover": { backgroundColor: "#BD1D23" },
            "&:disabled": { backgroundColor: "#E57373", color: "#fff" },
          }}
          onClick={handleBulkUpdateAdd}
          disabled={!selectedGroupIds.length || !selectedUserIds.length}
        >
          ADD
        </Button>
        <Button variant="outlined" onClick={handleClearAll} color="secondary" style={{ marginRight: "8px", backgroundColor: "#FFF", color: "#000" }}>
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignBulkGroupToUserModal;
