import React, { createContext, useState, useContext } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const BackdropContext = createContext();

export const useBackdrop = () => useContext(BackdropContext);

export const BackdropProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const showBackdrop = () => setOpen(true);
  const hideBackdrop = () => setOpen(false);

  return (
    <BackdropContext.Provider value={{ showBackdrop, hideBackdrop }}>
      {children}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </BackdropContext.Provider>
  );
};
