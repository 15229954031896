import apiClient from '../../../common/functions/api_axios_instance.function';
import { logout } from '../../../common/functions/log_out.function';
export const getOneGroupDetail = async (id) => {
//   const authState = getAuthenticationState();
 try{ 
  if(!id) return; 
  const resp = await apiClient.get(`/users/userAssignmentDetails/${id}`, {
    // headers: {
    //   authorization: `Bearer ${authState.token}`
    // }
    
  });

  return (resp.data);

} catch (error) {
    console.error('Error fetching users:', error);
    if(error.response.status === 401) {logout()}
  }
}