// import logo from './logo.svg';
import './App.scss';
import FttsLandingPage from './Pages/FttsLandingPage';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider, createTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MainLandingPage } from './Pages/LandingMain/mainLandingPage';
import { SideBox } from './Pages/LandingMain/side_box.component';
// import { HashRouter} from 'react-router-dom';
// import { Route, Routes } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MainLoginScreen } from './Pages/logIn/main_Login_Screen.component';
import { LandingPageUserManagement } from './Pages/UserManagement/landing_Page_user_management.component';
import GroupManagement from './Pages/UserManagement/groupManagement';
import ProtectedRoute from './Pages/logIn/protected_Routes_component';
import { BackdropProvider } from './common/components/backDrop_context.component';
const theme = createTheme({
  typography: {
    // fontFamily: 'Poppins',
  },
});
function App() {
  const [videoPlayTime, setVideoPlayTime] = useState(`2024-03-22 00:00:00`);
  // const navigate = useNavigate();
  const [filtersValue, setFiltersValue] = useState({
    region: '',
    city: '',
    ring: '',
  });

  // const navigateToHome = () => {
  //   navigate('/');
  // }
  return (
    //  <Router>
    //     <div className='App'>
    //       <ThemeProvider theme={theme}>
    //        <Routes>
    //           <Route path={'/fibers'} element={<FttsLandingPage />} />
    //           <Route path={'/userManagement'} element={<LandingPageUserManagement />}/>
    //           <Route path={'/rov'} element={<MainLandingPage main={true} />} />
    //           <Route path={'/'} element={<MainLoginScreen />} />
    //         </Routes>
    //       </ThemeProvider>
    //    </div>
    //    <ToastContainer 
    //               position="top-right"
    //               autoClose={3000}
    //               hideProgressBar={false}
    //               newestOnTop={false}
    //               closeOnClick
    //               rtl={false}
    //               pauseOnFocusLoss
    //               draggable
    //               pauseOnHover
    //           />
    //   </Router>
    <BackdropProvider>
    <Router>
      <div className="App">
        <ThemeProvider theme={theme}>
          <Routes>
            <Route
              path="/fibers"
              element={<ProtectedRoute element={FttsLandingPage} />}
            />
            <Route
              path="/userManagement"
              element={
                <ProtectedRoute
                  element={LandingPageUserManagement}
                  requiredPermission="USER_MANAGEMENT"
                />
              }
            />
            <Route
              path="/rov"
              element={<ProtectedRoute element={MainLandingPage} />}
            />
            <Route path="/" element={<MainLoginScreen />} />
          </Routes>
        </ThemeProvider>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
    </BackdropProvider>
  );
}

export default App;
