import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ element: Component, requiredPermission }) => {
    const accessToken = sessionStorage.getItem('logInState');
    const accessToken2 = Cookies.get('AccessToken');

    console.log('Access Token:', accessToken2);
  
    if (!accessToken) {
      return <Navigate to="/" replace />;
    }
    return <Component />;
  };
  

export default ProtectedRoute;
