import apiClient from '../../../common/functions/api_axios_instance.function';
import { logout } from '../../../common/functions/log_out.function';
export const deleteGroup = async (id) => {
  try {
    const response = await apiClient.delete(`/groups/deleteGroup/${id}`);
    
    return response.data;
  } catch (error) {
    if(error.response.status === 401) {logout()}
    console.error('Error assigning feature to permissions:', error);
    // throw error;
  }
};
