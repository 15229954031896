import apiClient from '../../../common/functions/api_axios_instance.function';
import { logout } from '../../../common/functions/log_out.function';
import { notify } from '../../../common/functions/react-toastify.function';
export const assignBulkUserToGroups = async (updatedGroups) => {
  try {
    const response = await apiClient.put(
      `/users/bulkAssignUserGroups`, 
      updatedGroups, 
    );
    
    return response.data;
  } catch (error) {
    notify('error', error.response.data.message)

    if(error.response.status === 401) {logout()}
    // notify('error', error.message)
    console.log('Error assigning feature to permissions:', error);
    // throw error;
  }
};
