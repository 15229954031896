import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import logo from '../../Assets/Logos/logo-desk.svg';
import AdminPopUp from '../../Components/TopHeader/small-right-popUp-admin.component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import newGifT from '../../Assets/ProfileImage/newGifT.gif';
import { SideBox } from './side_box.component';
import { SideBoxBottom } from './side_box_bottom.component';
import QuickLinks from './quickLinks';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from '../../common/functions/log_out.function';
import { notify } from '../../common/functions/react-toastify.function';
import { useNavigate } from 'react-router-dom';
export const MainLandingPage = () => {
  const [anchor, setAnchor] = useState(null);
  const navigate = useNavigate()
  const handleClick = (event) => {
    // setAnchor(anchor ? null : event.currentTarget);
  };
  const permissions = localStorage.getItem('permissions')
  const handleLogout = async () => {
    try {
      const result = await logout();
      console.log(result)
      navigate('/')
      notify('success', result.message)
    } catch (error) {
      notify('error', error.response.data.message)
      navigate('/')
    }
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#192126',
          border: '1px solid #333333',
          color: '#fff',
          height: '70px',
          fontFamily: 'Inter, sans-serif',
        }}
      >
        <AdminPopUp anchor={anchor} setAnchor={setAnchor} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: '12px',
            mx: '16px',
          }}
        >
          <img alt='' src={logo} style={{ height: '50px' }} />
          <Box
            sx={{
              display: 'flex',
              gap: '12px',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              // onClick={handleClick}
            >
              {/* <span>Admin</span>
              <ExpandMoreIcon /> */}
              <LogoutIcon onClick={handleLogout} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2} sx={{ background: 'black', height: 'calc(100vh - 70px)', mt: 0 }}>
        <Grid item xs={12} md={4} lg={4} sx={{ background: 'black' }}>
          <SideBox />
          <SideBoxBottom />
        </Grid>
        <Grid item xs={12} md={8} lg={8} padding={0} sx={{ position: 'relative', background: 'black' }}>
          <img src={newGifT} alt="Example GIF" style={{ width: '100%', height: 'auto' }} />
          {/* <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              padding: '16px',
              boxSizing: 'border-box',
            }}
          >
            <QuickLinks />
          </Box> */}
        </Grid>
      </Grid>
    </>
  );
};
