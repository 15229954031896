import React, { useMemo, useState,useEffect } from 'react';
import { Box, Grid, TextField, Typography, createTheme } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import TableChartIcon from '@mui/icons-material/TableChart';
import MapIcon from '@mui/icons-material/Map';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker,LocalizationProvider } from '@mui/x-date-pickers-pro';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FilterListIcon from '@mui/icons-material/FilterList';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import TuneIcon from '@mui/icons-material/Tune';
import { downloadExcelSheetFunction } from '../../common/functions/download_excel_sheet..function';
import { ExpandableTable } from './expandableTable';
import { borderRadius, boxSizing, color, display, fontSize, fontWeight, lineHeight, padding } from '@mui/system';
import DateRangePickerComponent from '../../common/components/Date_Range_Picker.component';

const theme = createTheme({
  typography: {
    // fontFamily: 'Poppins',
  },
});


export default function ActiveAlarmsTable({ setViewType, data,viewType,selectedDate,setSelectedDate, loading, selectedAlarm, city, region, ring, state,setVideoPlayTime,setVideoPlayTimeEnd, alramType, open, setAlramType, toggleDrawer, applyFilters }) {
  const [position, setPosition] = useState(0);
  const validData = Array.isArray(data) ? data : [];
  
  useEffect(() => {
     if(selectedDate[0] && selectedDate[1] && selectedDate[0] === selectedDate[1]){
    const isCurrentDateStart = selectedDate[0].isSame(dayjs(), 'day');
    const isCurrentDateEnd = selectedDate[1].isSame(dayjs(), 'day');

    if (isCurrentDateStart && isCurrentDateEnd) {
      const now = dayjs();
      const twentyFourHoursAgo = now.subtract(24, 'hour');
      setVideoPlayTime(`${twentyFourHoursAgo.format('YYYY-MM-DD')} ${twentyFourHoursAgo.format('HH:mm:ss')}`);
      setVideoPlayTimeEnd(`${twentyFourHoursAgo.format('YYYY-MM-DD')} ${twentyFourHoursAgo.format('HH:mm:ss')}`);
    }} else {
      setVideoPlayTime(`${selectedDate[0].format('YYYY-MM-DD')} 00:00:00`);
      setVideoPlayTimeEnd(`${selectedDate[1].format('YYYY-MM-DD')} 00:00:00`);
    }
    setPosition(0);
  }, [selectedDate]);

  const handleChange = (value) => {
    setAlramType(value);
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const columns = useMemo(() => {
    const keysToRemove = ['lat', 'long', 'xmin', 'xmax', 'ymin', 'ymax', 'commissioned_at', 'length', 'alarm_source', 'first_occurred', 'last_occurred', 'layer_name'];
  
    // Ensure data is an array
    const validData = Array.isArray(data) ? data : [];
  
    // Get all unique keys from the data objects except the ones to remove
    const keys = validData?.reduce((acc, obj) => {
      Object.keys(obj).forEach(key => {
        if (!keysToRemove.includes(key) && !acc.includes(key)) {
          acc.push(key);
        }
      });
      return acc;
    }, []);
  
    const columnsWithIndex = keys?.map(key => ({
      accessorKey: key,
      header: (key === 'acknowledgement_status' || key === 'clearance_status') ? '' : key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
      size: 120,
      enableSorting: key !== 'clearance_status' && key !== 'acknowledgement_status',
      enableColumnFilter: key !== 'clearance_status' && key !== 'acknowledgement_status',
      enablePinning: key !== 'clearance_status' && key !== 'acknowledgement_status',
      enableColumnActions: key !== 'clearance_status' && key !== 'acknowledgement_status',
      Cell: ({ cell }) => {
        if (key === 'source_lookup_id') {
          return <span style={{ color: '#24A0ED' }}>{cell.getValue()}</span>;
        }
        if (key === 'acknowledgement_status') {
          return (
            <span style={{
              background: cell.getValue() === 'Unacknowledged' ? '#FFD5D7' : '#8be0c6',
              color: cell.getValue() === 'Unacknowledged' ? '#FF6066' : '#00B17B',
              padding: '2px 8px',
              fontSize: '10.5px',
              fontWeight: '550',
              borderRadius: '24px',
            }}>
              {cell.getValue()}
            </span>
          );
        }
        if (key === 'clearance_status') {
          return (
            <span style={{
              background: cell.getValue() === 'Uncleared' ? '#FDE3B1' : '#7FB8FB',
              color: cell.getValue() === 'Uncleared' ? '#F28231' : '#0087B1',
              padding: '2px 8px',
              fontSize: '10.5px',
              fontWeight: '550',
              borderRadius: '24px',
            }}>
              {cell.getValue()}
            </span>
          );
        }
        return cell.getValue();
      }
    }));
  
    return columnsWithIndex;
  }, [data]);
  

//   const columns = useMemo(() => {
//     const keysToRemove = ['lat', 'long', 'xmin', 'xmax', 'ymin', 'ymax', 'commissioned_at', 'length', 'alarm_source', 'first_occurred', 'last_occurred', 'layer_name'];

//     // Get all unique keys from the data objects except the ones to remove
//     const keys = data?.reduce((acc, obj) => {
//         Object.keys(obj).forEach(key => {
//             if (!keysToRemove.includes(key) && !acc.includes(key)) {
//                 acc.push(key);
//             }
//         });

//         return acc;
//     }, []);

//     const columnsWithIndex = [
//         ...keys?.map(key => ({
//             accessorKey: key,
//             header: (key === 'acknowledgement_status' || key === 'clearance_status') ? '' : key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
//             size: 120,
//             enableSorting: key !== 'clearance_status' && key !== 'acknowledgement_status',
//             enableColumnFilter: key !== 'clearance_status' && key !== 'acknowledgement_status',
//             enablePinning: key !== 'clearance_status' && key !== 'acknowledgement_status',
//             enableColumnActions: key !== 'clearance_status' && key !== 'acknowledgement_status',
//             Cell: ({ cell }) => {
//               if(key ==='source_lookup_id'){
//                 return (
//                   <span style={{
//                     color: '#24A0ED',
//                   }}>
//                     {cell.getValue()}
//                   </span>
//                 );
//               }
//                 if (key === 'acknowledgement_status') {
//                   return (
//                       <span style={{
//                           background: cell.getValue()==='Unacknowledged' ? '#FFD5D7' : '#8be0c6',
//                           color: cell.getValue()==='Unacknowledged' ? '#FF6066' : '#00B17B',
//                           padding: '2px 8px 2px 8px',
//                           // padding:'0px 8px',
//                           fontSize: '10.5px',
//                           fontWeight: '550',
//                           borderRadius: '24px',
//                           verticalAlign: 'middle',
//                           boxSizing: 'border-box',
//                       }}>
//                           {cell.getValue()}
//                       </span>
//                   );
//               }
//               if (key === 'clearance_status') {
//                   return (
//                       <span style={{
//                           background: cell.getValue()==='Uncleared' ? '#FDE3B1' : '#7FB8FB',
//                           color: cell.getValue()==='Uncleared' ? '#F28231' : '#0087B1',
//                           padding: '2px 8px 2px 8px',
//                           fontSize: '10.5px',
//                           fontWeight: '550',
//                           borderRadius: '24px',
//                           verticalAlign: 'middle',
//                           boxSizing: 'border-box',
//                       }}>
//                           {cell.getValue()}
//                       </span>
//                   );
//               }
//                 return cell.getValue();
//             }
//         }))
//     ];

//     return columnsWithIndex;
// }, [data]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
      sx: {
        '& .MuiPaginationItem-root.Mui-selected': {
          backgroundColor: '#BD1D23', 
          color: 'white', 
        },
      },
    },
    // enableHiding: false, 
    enableBottomToolbar: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: true,
    initialState: { density: 'compact' },
    muiTableContainerProps: { sx: { maxHeight: "calc(100vh - 320px)" } },
    muiTableBodyCellProps: {
      // sx: (theme) => ({
      //   backgroundColor:
      //     theme.palette.mode === 'dark'
      //       ? theme.palette.grey[900]
      //       : theme.palette.grey[50],
      // }),
      
    },
    muiTableBodyCellProps: {
      sx: {
        color: "#667085",
        backgroundColor: '#fff',
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: '400',
        lineHeight: "125%",
        height: "36px",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: "#344054",
        backgroundColor: '#E8E9E9',
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: '600',
        lineHeight: "125%",
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
      },
    },
    renderDetailPanel: ({ row }) => (
      <>
      <ExpandableTable row={row} />
     </>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Tooltip title="Download">
        <svg style={{ cursor: "pointer" }} onClick={downloadFile} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#0000008a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7 10L12 15L17 10" stroke="#0000008a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 15V3" stroke="#0000008a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </Tooltip>
    ),
  });

  const downloadFile = () => {
    // const visibleColumns = table.getVisibleLeafColumns().map(col => col.id).filter(id => id !== 'mrt-row-expand');
   downloadExcelSheetFunction(validData);
  }

  return (

    <div style={{ marginLeft: "32px", marginRight: "32px", marginTop: "20px" }}>
      <div style={{ width: '-webkit-fill-available' }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: "center",
          width: '100%',
          height: '44px'
        }}>
          <div className="flex">
            <a id="switch_on" className={`toggleStyle ${alramType === 'live' ? 'selected' : 'not_selected'}`} onClick={() => handleChange('live')}>
              Live Alarms
            </a>
            <a id="switch_off" className={`toggleStyle ${alramType === 'history' ? 'selected' : 'not_selected'}`} onClick={() => handleChange('history')}>
              History Alarms
            </a>
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
          }}>
            <div style={{ background: "#F8E8E9" }}>
              {
                alramType === 'history' && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePickerComponent selectedDate={selectedDate} handleDateChange={handleDateChange}/>
                    {/* <DatePicker
                      name="birthDate"
                      value={selectedDate}
                      onChange={handleDateChange}
                      sx={{ height: 44, '& .MuiInputBase-root': { height: 44 } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                        />
                      )}
                    /> */}
                  </LocalizationProvider>
                )
              }
            </div>
            <div className="flex-table">
              <a className={state.right ? "table-icons" :"table-icon" }  onClick={toggleDrawer('right', true)}>
                <Tooltip title="Filter">
                  <TuneIcon />
                </Tooltip>
              </a>
            </div>
            <div className="flex-table">
          <a className={viewType === "graph" ? "table-icons": "table-icon-single"} onClick={() => setViewType('graph')}>
                <Tooltip title="Map">
                  <MapIcon />
                </Tooltip>
              </a>
            <a className={viewType === "table" ? "table-icons": "table-icon-single"} onClick={() => setViewType('table')}>
              <Tooltip title="Table">
                <TableChartIcon />
              </Tooltip>
            </a>
          </div>
          </div>
        </Box>
      </div>
      <div className="box" style={{ marginTop: "20px" }}>
        <MaterialReactTable table={table} />
      </div>
    </div>
  )
}