import React, { useState } from 'react';
import { DateRangePicker,LocalizationProvider } from '@mui/x-date-pickers-pro';
import TextField from '@mui/material/TextField';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import dayjs from 'dayjs';
const DateRangePickerComponent = ({selectedDate,handleDateChange}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const today = dayjs();
  return (
    <DateRangePicker
        value={selectedDate}
        onChange={(newValue) => handleDateChange(newValue)}
        slots={{ field: SingleInputDateRangeField }}
        data-testid='daterange-picker-component-1'
        id='daterange-picker-component-1'
        slotProps={{
          textField: {
            fullWidth: true,
            size: "small",
            label: "Date Range",
            sx: { height: 44, '& .MuiInputBase-root': { height: 44 } },
          }
        }}
        maxDate={today}
      />
  );
};

export default DateRangePickerComponent;
