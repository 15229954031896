// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// toast.configure(); // Initialize React-Toastify

// const notify = (type, message) => {
//     const options = {
//         position: toast.POSITION.TOP_RIGHT,
//         autoClose: 3000, // Auto close after 3 seconds
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//     };

//     switch (type) {
//         case 'success':
//             toast.success(message, options);
//             break;
//         case 'error':
//             toast.error(message, options);
//             break;
//         case 'warning':
//             toast.warning(message, options);
//             break;
//         default:
//             toast.info(message, options);
//             break;
//     }
// };

// export default notify;


import { toast } from 'react-toastify';

export const notify = (type, message) => {
    switch (type) {
        case 'success':
            toast.success(message);
            break;
        case 'error':
            toast.error(message);
            break;
        case 'warning':
            toast.warning(message);
            break;
        default:
            toast.info(message);
            break;
    }
};